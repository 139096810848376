import { DropdownOption } from '../../shared/components/Dropdown'

export const MaterialIconsList = [
  'activity camping',
  'activity celebrate',
  'activity eating 1',
  'activity eating 2',
  'activity exchange',
  'activity exercise',
  'activity handball',
  'activity high five',
  'activity hiking',
  'activity recycling',
  'activity rowing',
  'activity running',
  'activity school',
  'activity skiing',
  'activity talk',
  'activity thumb up',
  'activity yoga',
  'face baby',
  'face man 1',
  'face man 2',
  'face woman 1',
  'face woman 2',
  'group',
  'group with heart 1',
  'group with heart 2',
  'heart',
  'heart broken',
  'heart with hand',
  'heart with plus',
  'heart with tick',
  'home',
  'home with hand',
  'home with person',
  'object bolt',
  'object bomb',
  'object cake',
  'object cog',
  'object compass',
  'object cookie',
  'object flower',
  'object fruit',
  'object globe',
  'object ice cream',
  'object key',
  'object loud speaker',
  'object magnifier',
  'object magnifier with person',
  'object magnifier with star',
  'object magnifier with tick',
  'object pet',
  'object phone',
  'object red cross',
  'object rocket',
  'object rocket launch',
  'object sanitiser',
  'object scissors',
  'object shopping basket',
  'object ticket',
  'object trophy',
  'person',
  'person multiple',
  'person with plus',
  'person with tick',
  'person with wheelchair',
  'sentiment excited',
  'sentiment happy',
  'sentiment laugh',
  'sentiment neutral',
  'sentiment satisfied',
  'star empty',
  'star filled',
  'star half filled',
  'star multiple',
  'tick',
  'tick multiple',
  'time',
  'time day',
  'time fast',
  'time multiple',
  'time night',
  'time with plus',
  'transport airplane',
  'transport bicycle',
  'transport boat',
  'transport bus',
  'transport car',
  'transport train',
  'transport underground',
]

export const MaterialIconOptions: DropdownOption[] = MaterialIconsList.map(
  (e, index) => {
    return { name: e, value: e, label: `${e}.png`, id: `${index}` }
  },
)

export const basicCustomAmount = {
  iconId: MaterialIconOptions[0].value,
  amount: '',
  description: '',
}

export const initialCampaignState = {
  campaignId: '',
  code: '',
  logo: '',
  primaryColor: '#ffffff',
  secondaryColor: '#000000',
  isActive: true,
  expiryDate: '2023-08-15T00:00:00.000Z',
  currency: 'NZD',
  receiptConfig: {
    template: 'donation_default',
    options: ['EMAIL','MAIL','QR_CODE','NO_RECEIPT'],
    emailSubject: '',
    emailText: '',
    emailCc: '',
  },
  emvConfigId: '',
  configs: [
    {
      region: 'default',
      button1Amount: 5,
      button2Amount: 10,
      button3Amount: 20,
    },
  ],
  feeSettings: {
    percentage: 0.0,
    fixedAmount: 50,
    isMandatory: true,
  },
  merchantId: '',
}
