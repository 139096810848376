import type { RootState } from '../../store/store'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TemplatePageProps } from '../../shared/types'
import React, { useState } from 'react'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import {
  deleteInstallation,
  fetchInstallations,
  getActivationCode,
} from './api'
import { useNavigate } from 'react-router-dom'
import Table from '../../shared/components/table/Table'
import Button from '../../shared/components/button/Button'
import { Title } from '../../shared/components/title/Title'
import { SubTitle, Wrapper } from './Kiosk'
import { Installation } from '../Installations/types'
import { TextInput } from '../../shared/components/input/TextInput'
import { Icon } from '../../shared/components/icon/Icon'
import styles from './Kiosk.module.css'
import { Select } from '../../shared/components/Select'
import { Spacer } from '../Merchant/MerchantPage'
import styled from 'styled-components'
import {
  ButtonSize,
  ButtonVariant,
} from '../../shared/components/button/constants'
import { showNotification } from '../../shared/components/Alert'
import Modal from '../../shared/components/modal/Modal'
import { Confirmation } from '../../shared/components/confirmation/Confirmation'
import { setSelectedOrganization } from '../Organization/ducks/organizationSlice'
import { createAlert } from '../../shared/components/alert/ducks/alertSlice'

const KioskList = ({ user }: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const canRead = hasPermission(user, 'kiosk', RolePermission.READ)
  const canCreate = hasPermission(user, 'kiosk', RolePermission.CREATE)
  const canDelete = hasPermission(user, 'kiosk', RolePermission.DELETE)
  const canUpdate = hasPermission(user, 'kiosk', RolePermission.UPDATE)
  const [installations, setInstallations] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [toDelete, setToDelete] = useState(null)
  const [searchText, setSearchText] = useState('')
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const [orgId, setOrgId] = useState('')
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation>(null)

  const filteredInstallations = installations?.filter(
    (value: Installation) =>
      value.deviceType === 'KIOSK' &&
      (value.name.toLowerCase().startsWith(searchText.toLowerCase()) ||
        value.organizationName
          .toLowerCase()
          .startsWith(searchText.toLowerCase())),
  )

  const onEditInstallation = (id: string, orgId: string) => {
    navigate(`/kiosk/${id}`, { state: { orgId } })
  }

  const onRequestDeleteInstallation = (value: Installation) => {
    setToDelete(value)
    setIsModalOpen(true)
  }

  const reloadInstallations = () => {
    setIsLoading(true)
    dispatch(setSelectedOrganization(orgList.find(org => org.orgId === orgId)))
    fetchInstallations(user, orgId).then(x => {
      setIsLoading(false)
      setInstallations(x)
    })
  }

  React.useEffect(() => {
    if (orgList.length) setOrgId(orgList[0].orgId)
    else setOrgId(user.orgId)
  }, [orgList])

  React.useEffect(() => {
    if (!orgId) return
    reloadInstallations()
  }, [orgId])

  const generateCode = () => {
    setIsLoading(true)
    getActivationCode(
      user,
      selectedInstallation.orgId,
      selectedInstallation.installationId,
    ).then(x => {
      selectedInstallation.activationCode = x.activationCode
      selectedInstallation.activationCodeExpiryDate = x.activationCodeExpiryDate
      setSelectedInstallation({ ...selectedInstallation })
      setIsLoading(false)
    })
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(selectedInstallation.activationCode)
      .then(() => {
        dispatch(
          createAlert({ level: 'success', message: 'Copied to clipboard.' }),
        )
        return
      })
      .catch(() => {
        showNotification('danger', 'Failed to copy to clipboard.')
        return
      })
  }

  const onDeleteInstallation = () => {
    setIsModalOpen(false)
    deleteInstallation(user, toDelete.orgId, toDelete.installationId).then(
      () => {
        reloadInstallations()
      },
    )
  }

  return canRead ? (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {isLoading ? null : (
        <Wrapper>
          {isModalOpen && (
            <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
              <Confirmation
                title="Are you sure?"
                message="This action cannot be undone."
                onConfirm={() => {
                  onDeleteInstallation()
                }}
                onCancel={() => setIsModalOpen(false)}
              />
            </Modal>
          )}
          {selectedInstallation ? (
            <>
              <SubTitle>{selectedInstallation.name}</SubTitle>
              <Title>Generate Activation Code</Title>
              <Spacer />
              <TextInput
                label="Organization"
                name="organization"
                placeholder=""
                type="string"
                value={selectedInstallation.organizationName}
                disabled
              />
              <CodeInfo>Code info</CodeInfo>
              {selectedInstallation.activationCode &&
              selectedInstallation.activationCodeExpiryDate * 1000 >
                Date.now() ? (
                <>
                  <Info>
                    <InfoTitle>Activation Code</InfoTitle>
                    <CodeBlock>
                      <Code>
                        {selectedInstallation.activationCode}
                        <span onClick={copyToClipboard}>
                          <Icon name="copy.svg" className={styles.copy} />
                        </span>
                      </Code>
                      <CodeExpiry>
                        {`Expires in ${new Intl.DateTimeFormat('en-NZ', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).format(
                          new Date(
                            selectedInstallation.activationCodeExpiryDate *
                              1000,
                          ),
                        )}`}
                      </CodeExpiry>
                    </CodeBlock>
                  </Info>
                  <div>
                    <Button
                      size={ButtonSize.LARGE}
                      variant={ButtonVariant.GHOST}
                      onClick={generateCode}
                    >
                      Generate new code
                    </Button>
                  </div>
                </>
              ) : (
                <NoCode>
                  <div className={styles.info}>
                    <Icon name="info.svg" />
                    An activation code has not been generated yet.
                  </div>
                  <div>
                    <Button
                      size={ButtonSize.LARGE}
                      variant={ButtonVariant.TONAL}
                      onClick={generateCode}
                    >
                      Generate Code
                    </Button>
                  </div>
                </NoCode>
              )}
              <div className={styles.navigation}>
                <Button
                  size={ButtonSize.LARGE}
                  variant={ButtonVariant.GHOST}
                  onClick={() => setSelectedInstallation(null)}
                >
                  Go to kiosks
                </Button>
              </div>
            </>
          ) : (
            <>
              <Title>Kiosks</Title>
              <div className={styles.search}>
                <TextInput
                  value={searchText}
                  prefix={<Icon name="search.svg" />}
                  onChange={e => setSearchText(e.target.value)}
                />
                <div className={styles.organisation}>
                  <Select
                    isWithFader={false}
                    visibleCount={10}
                    collection={orgList
                      .map(org => ({
                        name: org.name,
                        value: org.orgId,
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))}
                    value={{
                      name: orgList.find(org => org.orgId === orgId)?.name,
                      value: orgId,
                    }}
                    onSelect={org => setOrgId(org)}
                    disabled={orgList.length < 2}
                  />
                </div>
                {canCreate && (
                  <Button
                    onClick={() => navigate('/kiosk/new', { state: { orgId } })}
                  >
                    New Kiosk
                  </Button>
                )}
              </div>
              <div className={styles.filter}>
                Showing {filteredInstallations.length} kiosks (of{' '}
                {installations.length})
              </div>
              <Table
                className={styles.table}
                header={['Id', 'Kiosk', 'Organization', 'Status', 'Actions']}
                data={filteredInstallations.map(
                  (installation: Installation, i: number) => {
                    return [
                      i + 1,
                      installation.name,
                      installation.organizationName,
                      installation.status,
                      <div
                        key={installation.installationId}
                        className={styles.actions}
                      >
                        <div
                          onClick={() => setSelectedInstallation(installation)}
                        >
                          <Icon name="key.svg" />
                        </div>
                        {canUpdate && (
                          <div
                            onClick={() =>
                              onEditInstallation(
                                installation.installationId,
                                installation.orgId,
                              )
                            }
                          >
                            <Icon name="edit.svg" />
                          </div>
                        )}
                        {canDelete && (
                          <div
                            onClick={() =>
                              onRequestDeleteInstallation(installation)
                            }
                          >
                            <Icon name="trash.svg" />
                          </div>
                        )}
                      </div>,
                    ]
                  },
                )}
              />
            </>
          )}
        </Wrapper>
      )}
    </>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(KioskList)

const CodeInfo = styled.div`
  color: var(--content-secondary);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 32px 0 16px;
`

const Info = styled.div`
  max-width: 400px;
  border-radius: 12px;
  padding: 16px;
  margin: 16px 0;
  border: 1px solid var(--content-inverse-secondary);
  background: linear-gradient(0deg, #f4f6f9 0%, #fbfcfe 100%);
  box-shadow: 0 14px 25px -12px rgba(113, 132, 165, 0.23);
`

const InfoTitle = styled.div`
  color: var(--content-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const CodeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Code = styled.div`
  display: flex;
  color: var(--content-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

const CodeExpiry = styled.div`
  color: var(--content-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const NoCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--content-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`
