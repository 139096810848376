import React from 'react'
import type { DateInputProps } from './types'
import { Input } from './Input'
import style from './Input.module.css'
import { Icon } from '../icon/Icon'
import { WarningMessage } from './TextInput'

const iconMap = {
  error: { name: 'error.svg', style: style.error },
  info: { name: 'info.svg', style: style.info },
  success: { name: 'check.svg', style: style.success },
  warning: { name: 'warning.svg', style: style.warning },
}

  export function DateInput(props: DateInputProps): React.ReactNode {
  const {
    onChange,
    onFocus,
    onBlur,
    disabled,
    type,
    tabIndex,
    value,
    className,
    placeholder,
    hasError,
    label,
    tooltip,
    note,
    isRequired,
    infoType,
    info,
    min,
    max 
  } = props

  return (
    <div className={className}>
      <div className={style.header}>
        {label && <span className={style.label}>{label}</span>}
        {tooltip}
        {note && <span className={style.note}> ({note})</span>}
        {isRequired && <span className={style.required}> *</span>}
      </div>
      <Input
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        type={type}
        tabIndex={tabIndex}
        value={value}
        placeholder={placeholder}
        hasError={hasError || infoType === 'error'}
        min={min}
        max={max}
      />
      {infoType && (
        <WarningMessage infoType={infoType} info={info}/>
      )}
    </div>
  )
}