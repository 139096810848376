import { MikaUser, ResponseError } from '../../shared/types'
import {
  baseUrl,
  buildRequestOptions,
  getActiveToken,
  validateActionResponse,
  validateUserToken,
} from '../../shared/utils'
import { showNotification } from '../../shared/components/Alert'
import { FormState } from '../../shared/reducers/formReducer'

export const fetchInstallations = async (
  user: MikaUser,
  org: string,
): Promise<any> => {
  const token = getActiveToken(user)
  if (!token) return null

  try {
    const url = `${baseUrl}/orgs/${org}/installations`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to fetch installations list.')

      return
    }

    return await response.json()
  } catch (error) {
    console.warn(error)
  }
}

export const saveInstallation = async (
  user: MikaUser,
  form: FormState,
  id: string,
): Promise<ResponseError | any> => {
  const url = `${baseUrl}/orgs/${form.orgId ?? user.orgId}/installations${id === 'new' ? '' : `/${id}`}`

  const token = getActiveToken(user)

  validateUserToken(token)

  form.minimumAmount = Number(form.minimumAmount || 0)
  form.maximumAmount = Number(form.maximumAmount || 0)
  form.feeSettings.fixedAmount = Number(form.feeSettings.fixedAmount || 0)
  form.feeSettings.percentage = Number(form.feeSettings.percentage || 0)

  delete form.organizationLogo
  delete form.cardAcceptorId
  delete form.campaignId
  delete form.orgId
  delete form.installationId
  const requestOptions = buildRequestOptions(
    token,
    id === 'new' ? 'POST' : 'PATCH',
    form,
  )

  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'create installation',
  )

  if (error) return error
  return requestOptions.method === 'PATCH' ? '' :  await response.json()
}

export const getInstallation = async (
  user: MikaUser,
  orgId: string,
  installationId: string,
): Promise<ResponseError | any> => {
  const url = `${baseUrl}/orgs/${orgId}/installations/${installationId}`
  const token = getActiveToken(user)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')

  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'get installation',
  )

  if (error) return error
  return await response.json()
}

export const getActivationCode = async (
  user: MikaUser,
  orgId: string,
  installationId: string,
): Promise<ResponseError | any> => {
  let url = `${baseUrl}/orgs/${orgId}/installations/${installationId}/code`
  const token = getActiveToken(user)

  validateUserToken(token)

  let requestOptions = buildRequestOptions(token, 'POST')

  let response = await fetch(url, requestOptions)
  let error = await validateActionResponse(
    response,
    requestOptions,
    'generate activation code',
  )

  if (error) return error
  url = `${baseUrl}/orgs/${orgId}/installations/${installationId}`
  requestOptions = buildRequestOptions(token, 'GET')
  response = await fetch(url, requestOptions)
  error = await validateActionResponse(
    response,
    requestOptions,
    'generate activation code',
  )
  if (error) return error

  return await response.json()
}

export const deleteInstallation = async (
  user: MikaUser,
  orgId: string,
  installationId: string,
): Promise<ResponseError | void> => {
  const url = `${baseUrl}/orgs/${orgId}/installations/${installationId}`
  const token = getActiveToken(user)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'DELETE')

  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'delete installation',
  )

  if (error) return error
}
