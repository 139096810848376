import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { css, styled } from 'styled-components'

export type ScrollFaderProps = {
  children: ReactNode
  faderSize?: string
  className?: string
}
export default function ScrollFader({
  children,
  faderSize = '32px',
  className,
  ...rest
}: ScrollFaderProps): ReactNode {
  const bottomAnchor = useRef<HTMLDivElement>(null)
  const topAnchor = useRef<HTMLDivElement>(null)
  const scrolledRef = useRef<HTMLDivElement>()
  const [showTopFader, setShowTopFader] = useState(false)
  const [showBottomFader, setShowBottomFader] = useState(false)

  useEffect(() => {
    if (scrolledRef.current)
      scrolledRef.current.style.setProperty('--fader-height', faderSize)
  }, [faderSize])

  useEffect(() => {
    const options = { root: scrolledRef.current }
    const topObserver = new IntersectionObserver(
      (e: IntersectionObserverEntry[]) => {
        setShowTopFader(!e[0].intersectionRatio)
      },
      options,
    )
    const bottomObserver = new IntersectionObserver(
      (e: IntersectionObserverEntry[]) => {
        setShowBottomFader(!e[0].intersectionRatio)
      },
      options,
    )

    topObserver.observe(topAnchor.current)
    bottomObserver.observe(bottomAnchor.current)

    return () => {
      topObserver.disconnect()
      bottomObserver.disconnect()
    }
  }, [scrolledRef])

  return (
    <Fader
      className={className}
      {...rest}
      $top={showTopFader}
      $bottom={showBottomFader}
      ref={scrolledRef}
    >
      <TopAnchor ref={topAnchor} />
      <div>{children}</div>
      <BottomAnchor ref={bottomAnchor} />
    </Fader>
  )
}

const Fader = styled.div<{ $top: boolean; $bottom: boolean }>`
  --fader-height: 32px;
  width: 100%;
  height: 100%;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent 0,
    black var(--fader-height),
    black calc(100% - var(--fader-height)),
    transparent 100%
  );
  -webkit-mask-size: 100% calc(100% + var(--fader-height) * 2);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 0 calc(var(--fader-height) * -1);
  transition:
    -webkit-mask-position 0.3s ease,
    -webkit-mask-size 0.3s ease;
  overflow-x: hidden;

  ${props =>
    props.$top &&
    css`
      -webkit-mask-position: 0 0;
      -webkit-mask-size: 100% calc(100% + var(--fader-height));
    `}

  ${props =>
    props.$bottom &&
    css`
      -webkit-mask-position: 0 calc(var(--fader-height) * -1);
      -webkit-mask-size: 100% calc(100% + var(--fader-height));
    `}
  
  ${props =>
    props.$top &&
    props.$bottom &&
    css`
      -webkit-mask-position: 0 0;
      -webkit-mask-size: 100% 100%;
    `}
`

const Anchor = styled.div`
  position: relative;
  z-index: 10;
  display: block;
  height: 0;
  padding: 0;
  background: transparent;
  pointer-events: none;
`

const TopAnchor = styled(Anchor)`
  margin: 1px 0 -1px;
`

const BottomAnchor = styled(Anchor)`
  margin: -1px 0 1px;
`
