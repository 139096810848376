export const enum RolePermission {
  CREATE = 'C',
  READ = 'R',
  UPDATE = 'U',
  DELETE = 'D',
}

export const permissions = {
  mikaAdmin: {
    organization: 'CRUD',
    campaign: 'CRUD',
    merchant: 'CRUD',
    user: 'CRUD',
    transactions: '',
    installation: '',
    kiosk: 'CRUD',
    reports: 'CR',
  },
  portfolioAdmin: {
    organization: 'CRUD',
    campaign: '',
    merchant: 'CRUD',
    user: 'CRUD',
    transactions: 'R',
    installation: 'CRUD',
    kiosk: '',
    reports: 'CR',
  },
  servicePortfolioAdmin: {
    organization: 'CRUD',
    campaign: '',
    merchant: 'CRUD',
    user: 'CRUD',
    transactions: 'R',
    installation: 'CRUD',
    kiosk: 'CRUD',
    reports: 'CR',
  },
  orgAdmin: {
    organization: 'RU',
    campaign: 'CRUD',
    merchant: 'CR',
    user: 'CRUD',
    transactions: 'R',
    installation: 'CRUD',
    kiosk: 'CRUD',
    reports: 'CR',
  },
  campaignAdmin: {
    organization: 'CRUD',
    campaign: 'CRUD',
    merchant: '',
    user: 'CRUD',
    transactions: 'R',
    installation: '',
    kiosk: 'CRUD',
    reports: 'CR',
  },
}

export const hiddenFields = {
  mikaAdmin: [],
  portfolioAdmin: ['orgLogo', 'orgSignature', 'orgCharityNumber'],
  servicePortfolioAdmin: [],
  orgAdmin: [],
  campaignAdmin: [],
}

export const restrictions = {
  orgAdmin: {
    custom: 'portfolioId',
    restricted: ['campaign', 'reportsCampaign'],
  },
  portfolioAdmin: {
    custom: '',
    restricted: ['reportsCampaign'],
  },
  servicePortfolioAdmin: {
    custom: '',
    restricted: ['reportsCampaign'],
  },
}

export const required = {
  orgAdmin: {
    installation: ['portfolioId'],
  },
}
