/* eslint-disable @typescript-eslint/no-explicit-any */
export type FormActionType =
  | 'HANDLE_FIELD_UPDATE'
  | 'LOAD_FORM_VALUES'
  | 'HANDLE_MERCHANT_CONFIGURATION'

export interface FormAction {
  type: FormActionType
  field?: string
  objectMap?: string
  payload: any
}

export interface FormState {
  [key: string]: string | any
}

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'HANDLE_FIELD_UPDATE':
      if (action.objectMap && action.field) {
        return {
          ...state,
          [action.objectMap]: {
            ...state[action.objectMap],
            [action.field]: action.payload,
          },
        }
      }
      if (action.field) {
        return {
          ...state,
          [action.field]: action.payload,
        }
      }

      console.error('Field is not defined in form reducer aciton')

      return state
    case 'HANDLE_MERCHANT_CONFIGURATION':
      if (action.objectMap && action.field) {
        if (action.objectMap === 'acquirerInformation.refund') {
          return {
            ...state,
            acquirerInformation: {
              ...state.acquirerInformation,
              refund: {
                ...state.acquirerInformation.refund,
                [action.field]: action.payload,
              },
            },
          }
        }

        return {
          ...state,
          [action.objectMap]: {
            ...state[action.objectMap],
            [action.field]: action.payload,
          },
        }
      }
      if (action.field) {
        return {
          ...state,
          [action.field]: action.payload,
        }
      }

      console.error('Field is not defined in merchant form reducer aciton')

      return state
    case 'LOAD_FORM_VALUES':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default formReducer
