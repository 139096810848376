import React, {
  ReactNode,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import ScrollFader from './ScrollFader'
import { css, styled } from 'styled-components'
import { noop } from 'lodash'
import { useClickOutside } from '../use-click-outside'
import { Icon } from './icon/Icon'

export type CollectionItem<T> = {
  name: string
  value: T
}

export type SelectProps<T> = {
  collection: CollectionItem<T>[]
  isWithFader?: boolean
  value?: CollectionItem<T>
  disabled?: boolean
  visibleCount?: number
  onSelect?: (value: T) => void
}

export function Select<T>({
  collection,
  isWithFader = true,
  value,
  disabled,
  visibleCount = 5,
  onSelect = noop,
}: SelectProps<T>): ReactNode {
  // ToDo: order the collection to have the selected item on top
  const [selected, setSelected] = useState(value)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>()
  const isFaderUsed = collection.length > visibleCount

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.style.setProperty(
        '--visible-count',
        visibleCount.toString(),
      )
    }
  }, [visibleCount, collection.length])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useClickOutside(selectRef, close)

  const onOpen = () => {
    if (disabled) return
    setIsOpen(!isOpen)
  }

  return (
    <Wrapper $disabled={disabled} ref={selectRef}>
      <Selected onClick={onOpen}>
        {selected && <Name>{selected.name}</Name>}
        <Icon name="carret_down.svg" />
      </Selected>
      {isOpen && (
        <List $isFaderUsed={isFaderUsed}>
          <ScrollFader faderSize={isWithFader ? '48px' : '0'}>
            {collection.map(item => (
              <Option
                key={`${item.name}-${item.value}`}
                onClick={() => {
                  setSelected(item)
                  onSelect(item.value)
                  setIsOpen(false)
                }}
              >
                {item.name}
              </Option>
            ))}
          </ScrollFader>
        </List>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $disabled: boolean }>`
  --visible-count: 5;
  position: relative;
  display: flex;
  height: 36px;
  border: 1px solid gray;
  border-radius: 4px;
  background-color: var(--grey-0);
  cursor: pointer;

  ${props =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

const Selected = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
`

const Name = styled.span`
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  font-weight: bold;
`

const List = styled.div<{ $isFaderUsed: boolean }>`
  position: absolute;
  z-index: 10;
  top: -1px;
  left: -1px;
  overflow: hidden;
  width: calc(100% + 2px);
  max-height: calc(var(--visible-count) * 36px);
  border: 1px solid gray;
  border-radius: 4px;
  background-color: var(--grey-0);
  box-shadow: 0 0 0 1px gray;
  margin-bottom: 10px;

  ${props =>
    props.$isFaderUsed &&
    css`
      height: calc(var(--visible-count) * 36px);
    `}
`

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 8px;

  &:hover {
    background-color: gray;
  }
`
