import React from 'react'
import style from './Input.module.css'
import type { InputProps } from './types'

export function Input({
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  prefix = null,
  suffix = null,
  type = 'text',
  className = '',
  tabIndex = -1,
  value = '',
  placeholder = '',
  hasError = false,
  ...rest
}: InputProps): React.ReactNode {
  return (
    <div
      className={`${className} ${disabled ? style.disabled : ''} ${hasError ? style['error-border'] : ''} ${
        style.wrapper
      }`}
    >
      {prefix && <span className={style.prefix}>{prefix}</span>}
      <input
        type={type}
        disabled={disabled}
        className={style.input}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={tabIndex}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
      {suffix && <span className={style.suffix}>{suffix}</span>}
    </div>
  )
}
