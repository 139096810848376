import React from 'react'

import { useDispatch } from 'react-redux'
import { logout } from '../ducks/loginSlice'
import styled from 'styled-components'

export const LogoutButton = (): React.ReactNode => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <StyledAnchor onClick={handleLogout} className="btn btn-light">
      Logout
    </StyledAnchor>
  )
}

const StyledAnchor = styled.a`
  color: var(--bg-primary-inverse) !important;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
`
