import { FormError, ResponseError } from '../../shared/types'

export const formatMerchantResponseError = (
  error: ResponseError,
): FormError => {
  const badInputs = error.message
  const formError = {}

  badInputs.map(fieldError => {
    if (fieldError.constraints && fieldError.property?.length) {
      let keyName = fieldError.property[fieldError.property.length - 1]

      if (
        fieldError.property.length > 1 &&
        fieldError.property[0] === 'cardAcceptorInformation'
      )
        keyName = 'cardAcceptorInformation_' + keyName

      // formError[keyName] = fieldError.constraints

      let error = ``

      Object.keys(fieldError.constraints).map((constraint, index) => {
        const message = fieldError.constraints[constraint]
        const styledMessage =
          message.charAt(0).toUpperCase() + message.substring(1, message.length)
        error = index === 0 ? `${styledMessage}` : `${error}\n${styledMessage}`
      })

      formError[keyName] = error
    }
  })

  return formError
}
