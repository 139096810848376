import React from 'react'
import { styled } from 'styled-components'

interface LoadingOverlayProps {
  isLoading: boolean
  children?: React.ReactNode
}

const LoadingOverlay = ({
  isLoading,
}: LoadingOverlayProps): React.ReactNode => {
  return (
    isLoading && (
      <AbsoluteOverlay>
        <div className="loading-overlay d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </AbsoluteOverlay>
    )
  )
}

export const LoadingLayer = ({
  isLoading, children,
}: LoadingOverlayProps): React.ReactNode => {
  return (
    isLoading ? (
      <DivOverlay>
        <div className="loading-overlay d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </DivOverlay>
    ) : <div>{children}</div>
  )
}

export default LoadingOverlay

const DivOverlay = styled.div`
  position: relative;

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
    color: var(--bg-accent-secondary);
  }
`

const AbsoluteOverlay = styled(DivOverlay)`
  position: absolute;
  width: 100%;
  height: calc(100vh - 140px);
  top: 70px;
  left: 0;
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
  }
`
