import type { User } from './types'

export const emptyUser: User = {
    createdAt: '',
    email: '',
    firstName: '',
    lastName: '',
    group: '',
    portfolioId: '',
    servicePortfolioId: '',
    addressLine1: '',
    addressLine2: '',
    stateProvince: '',
    postcode: '',
    city: '',
    country: '',
    phoneNumber: '',
  }
