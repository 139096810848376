import React from 'react'
import { styled } from 'styled-components'
import PageTemplate from '../../shared/components/Template/PageTemplate'
import { MikaMascot } from '../../shared/components/MikaMascot'

export const NotFoundPage = (): React.ReactNode => {
  return (
    <PageTemplate>
      <NotFoundWrapper>
        <MikaMascot />
        <h2>Error - Page not found!</h2>
      </NotFoundWrapper>
    </PageTemplate>
  )
}

export default NotFoundPage

const NotFoundWrapper = styled.div`
  background-color: var(--grey-0);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;

  > img {
    margin: 0;
  }
`
