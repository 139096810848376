import React, { ReactNode, useEffect, useState } from 'react'
import type { MikaUser, Organization, ResponseInfo } from '../../shared/types'
import { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import { showNotification } from '../../shared/components/Alert'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { useNavigate } from 'react-router-dom'
import Button from '../../shared/components/button/Button'
import { hasPermission } from '../../shared/utils'
import { deleteOrganization, fetchOrganizationList } from './service/api'
import Message from '../../shared/components/message/Message'
import { RolePermission } from '../../shared/constants'
import { Wrapper } from '../Installations/Installations'
import { Title } from '../../shared/components/title/Title'
import styles from '../Installations/Installation.module.css'
import { TextInput } from '../../shared/components/input/TextInput'
import { Icon } from '../../shared/components/icon/Icon'
import Table from '../../shared/components/table/Table'
import Modal from '../../shared/components/modal/Modal'
import { Confirmation } from '../../shared/components/confirmation/Confirmation'

const compareByName = (a: Organization, b: Organization) =>
  a.name.localeCompare(b.name)

const OrganizationList = ({ user }: { user: MikaUser }): ReactNode => {
  const [isLoading, setIsLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const navigate = useNavigate()
  const canUpdate = hasPermission(user, 'organization', RolePermission.UPDATE)
  const canCreate = hasPermission(user, 'organization', RolePermission.CREATE)
  const canDelete = hasPermission(user, 'organization', RolePermission.DELETE)
  const canRead = hasPermission(user, 'organization', RolePermission.READ)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [toDelete, setToDelete] = useState(null)

  const filteredOrgs = orgList
    ?.filter(value =>
      value.name.toLowerCase().startsWith(searchText.toLowerCase()),
    )
    .sort(compareByName)

  const onEditOrganization = (id: string) => {
    navigate(`/organization/${id}`)
  }

  const onDeleteOrganization = () => {
    setIsLoading(true)
    setIsModalOpen(false)
    deleteOrganization(user, toDelete.orgId).then((e: ResponseInfo) => {
      setIsLoading(false)
      showNotification(e.messageLevel, e.message)
    })
    setToDelete(null)
  }

  const onAddOrganization = () => {
    navigate('/organization/new')
  }

  useEffect(() => {
    if (!isLoading || !canRead) return
    fetchOrganizationList(user).then(() => {
      setIsLoading(false)
    })
  }, [isLoading, user, setIsLoading])

  const onRequestDeleteOrganization = (value: Organization) => {
    setToDelete(value)
    setIsModalOpen(true)
  }

  return (
    <Wrapper>
      {!canRead ? (
        <h1>You do not have permission to use this function.</h1>
      ) : (
        <>
          <LoadingOverlay isLoading={isLoading} />
          {isLoading ? null : (
            <>
              {isModalOpen && (
                <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
                  <Confirmation
                    title="Are you sure?"
                    message="This action cannot be undone."
                    onConfirm={() => {
                      onDeleteOrganization()
                    }}
                    onCancel={() => setIsModalOpen(false)}
                  />
                </Modal>
              )}
              <Title>Organizations</Title>
              <div className={styles.search}>
                <TextInput
                  value={searchText}
                  prefix={<Icon name="search.svg" />}
                  onChange={e => setSearchText(e.target.value)}
                />
                {canCreate && (
                  <Button onClick={() => navigate('/organization/new')}>
                    New Organization
                  </Button>
                )}
              </div>
              <div className={styles.filter}>
                Showing {filteredOrgs.length} organizations (of {orgList.length}
                )
              </div>
              <Table
                className={styles.table}
                header={['#', 'Name', 'Created', 'Status', 'Actions']}
                data={filteredOrgs.map((org: Organization, i: number) => {
                  return [
                    i + 1,
                    org.name,
                    org.createdAt,
                    org.status,
                    <div key={org.orgId} className={styles.actions}>
                      {canUpdate && (
                        <div
                          className={styles.action}
                          onClick={() => onEditOrganization(org.orgId)}
                        >
                          <Icon name="edit.svg" />
                        </div>
                      )}
                      {canDelete && (
                        <div
                          className={styles.action}
                          onClick={() => onRequestDeleteOrganization(org)}
                        >
                          <Icon name="trash.svg" />
                        </div>
                      )}
                    </div>,
                  ]
                })}
              />
            </>
          )}
          {!toDelete && !isLoading && orgList.length === 0 && (
            <Message
              title="No organizations found"
              primaryButtonText={canCreate ? 'New Organization' : ''}
              primaryAction={canCreate ? onAddOrganization : null}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(OrganizationList)
