import { Organization } from '../../shared/types'

export const emptyOrganization: Organization = {
  createdAt: '',
  updatedAt: '',
  addressLine1: '',
  addressLine2: '',
  charityCommNumber: '',
  gstNumber: '',
  city: '',
  contact: {
    firstName: '',
    lastName: '',
    title: 'Mr.',
    email: '',
  },
  website: '',
  country: '',
  orgId: '',
  logo: '',
  name: '',
  businessNumber: '',
  email: '',
  stateProvince: '',
  phoneNumber: '',
  signatureFile: '',
  status: '',
  postcode: '',
  hasKiosk: true,
}
