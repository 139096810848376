import React, { useState } from 'react'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { isValidEmail } from '../../shared/utils'
import { styled } from 'styled-components'
import { LoginHeader } from '../Login/LoginHeader'
import { TextInput } from '../../shared/components/input/TextInput'
import style from './PasswordReset.module.css'
import Button from '../../shared/components/button/Button'
import {
  ButtonSize,
  ButtonVariant,
} from '../../shared/components/button/constants'
import { Icon } from '../../shared/components/icon/Icon'
import { createAlert } from '../../shared/components/alert/ducks/alertSlice'
import { useDispatch } from 'react-redux'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { SektorBackground } from '../Login/Styles'
import { isSektorEnv } from '../../App'
import { LoginTitle } from '../Login/components/LoginForm'

const text = [
  {
    title: 'Forgot your password?',
    description:
      'Enter your email so we can send your password reset instructions. Make sure to check your spam folder if you don’t see it.',
    button: 'Send reset instructions',
  },
  {
    title: 'Set your new password',
    description:
      "We have sent a verification code to your email. Make sure to check your spam folder if you don't see it.",
    button: 'Save new password',
  },
]

export default function PasswordReset() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [pw1, setPw1] = useState('')
  const [isPw1Visible, setIsPw1Visible] = useState(false)
  const [pw2, setPw2] = useState('')
  const [isPw2Visible, setIsPw2Visible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [step, setStep] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const validateForm = () => {
    if (code === '' || pw1 === '' || pw1 !== pw2) setIsValid(false)
    else setIsValid(true)
  }

  React.useEffect(() => {
    validateForm()
  }, [code, pw1, pw2, isLoading])

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
    setIsValid(isValidEmail(e.target.value))
  }

  async function handleResetPassword() {
    setIsLoading(true)
    try {
      setErrorMessage('')
      if (step === 0) {
        await resetPassword({ username: email })
        setStep(1)
      } else if (step === 1) {
        await confirmResetPassword({
          username: email,
          confirmationCode: code,
          newPassword: pw1,
        })
        dispatch(
          createAlert({
            level: 'success',
            message: 'You can now log in with your new password',
          }),
        )
        navigate('/')
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function requestNewCode() {
    setIsLoading(true)
    await resetPassword({ username: email })
    dispatch(
      createAlert({
        level: 'success',
        message: 'We sent you a new verification code',
      }),
    )
    setIsLoading(false)
  }

  const BgComponent = isSektorEnv ? SektorBackground : Background

  return (
    <BgComponent>
      <LoadingOverlay isLoading={isLoading} />
      <LoginHeader />
      <Content>
        <Title>
          <LoginTitle/>
        </Title>
        <Box>
          <BoxTitle>{text[step].title}</BoxTitle>
          <BoxDescription>{text[step].description}</BoxDescription>
          <div className={style.input}>
            {step === 0 && (
              <TextInput
                label="Your email"
                name="email"
                placeholder=""
                type="text"
                onChange={onEmailChange}
                value={email}
                hasError={!!errorMessage}
                info={errorMessage}
                infoType={errorMessage ? 'error' : null}
              />
            )}
            {step === 1 && (
              <>
                <TextInput
                  label="Verification code"
                  name="code"
                  placeholder=""
                  type="text"
                  onChange={e => setCode(e.target.value)}
                  value={code}
                  hasError={!!errorMessage}
                  info={errorMessage}
                  infoType={errorMessage ? 'error' : null}
                />
                <TextInput
                  label="Your new password"
                  name="pw1"
                  placeholder=""
                  suffix={
                    <div
                      className={style.icon}
                      onClick={() => setIsPw1Visible(!isPw1Visible)}
                    >
                      <Icon name={isPw1Visible ? 'eye_slash.svg' : 'eye.svg'} />
                    </div>
                  }
                  type={isPw1Visible ? 'text' : 'password'}
                  onChange={e => setPw1(e.target.value)}
                  value={pw1}
                />
                <TextInput
                  label="Confirm your new password"
                  name="pw2"
                  placeholder=""
                  suffix={
                    <div
                      className={style.icon}
                      onClick={() => setIsPw2Visible(!isPw2Visible)}
                    >
                      <Icon name={isPw2Visible ? 'eye_slash.svg' : 'eye.svg'} />
                    </div>
                  }
                  type={isPw2Visible ? 'text' : 'password'}
                  onChange={e => setPw2(e.target.value)}
                  value={pw2}
                />
              </>
            )}
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.FILLED}
              onClick={handleResetPassword}
              disabled={!isValid}
            >
              {text[step].button}
              <Icon name="arrow_right.svg" />
            </Button>
          </div>
        </Box>
        <Footer>
          {step === 0 && (
            <>
              Remember it now?{' '}
              <Link onClick={() => navigate('/')}>Go back to log in</Link>
            </>
          )}
          {step === 1 && (
            <Link onClick={requestNewCode}>I haven't got my code</Link>
          )}
        </Footer>
      </Content>
    </BgComponent>
  )
}

const Background = styled.div`
  background-color: var(--brand-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  > span {
    color: var(--button-label-tonal-default);
  }
  > span.partner-portal {
    font-size: 24px;
  }

  > a {
    align-self: center;
    img {
      height: 48px;
      margin-right: 0px;
    }
  }
`

const Box = styled.div`
  display: flex;
  align-self: center;
  width: 382px;
  padding: 32px;
  margin-top: 32px;
  flex-direction: column;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.05);
`

const BoxTitle = styled.div`
  overflow: hidden;
  color: var(--content-primary);
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
`

const BoxDescription = styled.div`
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 32px;
`

const Footer = styled.span`
  color: var(--content-primary);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
`

const Link = styled.span`
  font-weight: 600;
  cursor: pointer;
`
