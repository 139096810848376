import {
  MikaUser,
  CampaignEntity,
  EmvConfigEntity,
  ResponseError,
} from '../../shared/types'
import {
  buildRequestOptions,
  getAPIUrl,
  getActiveToken,
  validateActionResponse,
  validateUserToken,
  uploadFile,
} from '../../shared/utils'

export const getOrganizationCampaignList = async (
  mikaUser: MikaUser,
  orgId?: string,
): Promise<CampaignEntity[]> | null => {
  const url = `${getAPIUrl()}/orgs/${orgId ?? mikaUser.orgId}/campaigns`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')

  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get campaign list')

  return await response.json()
}

export const getCampaignById = async (
  mikaUser: MikaUser,
  orgId?: string,
  campaignId?: string
): Promise<CampaignEntity> | null => {
  const url = `${getAPIUrl()}/orgs/${orgId ?? mikaUser.orgId}/campaigns/${campaignId}`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')

  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get campaign entity')

  return await response.json()
}

const uploadLogo = async (
  mikaUser: MikaUser,
  url: string,
  logo: string,
): Promise<ResponseError | void> => {
  const logoResponse = await uploadFile(mikaUser, url, logo)
  if (logoResponse) {
    return {
      statusCode: 400,
      error: 'Failed to upload logo',
      message: [
        {
          statusCode: 400,
          expected: 'Valid file',
          received: logoResponse,
          property: ['logo'],
          constraints: { logo: logoResponse },
        },
      ],
    }
  }
}

export const updateCampaign = async (
  mikaUser: MikaUser,
  campaign: CampaignEntity,
  organizationId?: string,
  logo?: string,
): Promise<ResponseError | void> => {
  const orgId = organizationId ?? mikaUser.orgId
  const url = `${getAPIUrl()}/orgs/${orgId ?? campaign.orgId}/campaigns/${campaign.campaignId}/`
  const token = getActiveToken(mikaUser)

  const campaignId = campaign.campaignId

  delete campaign.campaignId
  delete campaign.orgId
  const requestOptions = buildRequestOptions(token, 'PATCH', campaign)
  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'register campaign',
  )

  if (error) return error

  if (logo) {
    return await uploadLogo(
      mikaUser,
      `${getAPIUrl()}/orgs/${orgId}/campaigns/${campaignId}/uploadUrl/logo`,
      logo,
    )
  }
}

export const registerCampaign = async (
  mikaUser: MikaUser,
  campaign: CampaignEntity,
  organizationId?: string,
  logo?: string,
): Promise<ResponseError | void> => {
  if (mikaUser && mikaUser.accessToken) {
    const orgId = organizationId ?? mikaUser.orgId
    const url = `${getAPIUrl()}/orgs/${orgId}/campaigns/`

    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    const requestOptions = buildRequestOptions(token, 'POST', campaign)

    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'register campaign',
    )

    if (error) return error

    const { campaignId } = await response.json()

    if (logo) {
      return await uploadLogo(
        mikaUser,
        `${getAPIUrl()}/orgs/${orgId}/campaigns/${campaignId}/uploadUrl/logo`,
        logo,
      )
    }
  }
}

export const getEmvConfigList = async (
  mikaUser: MikaUser,
): Promise<EmvConfigEntity[]> | null => {
  const url = `${getAPIUrl()}/emv-configs`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')

  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get campaign list')

  return await response.json()
}

export const deleteCampaign = async (
  mikaUser: MikaUser,
  campaign: CampaignEntity,
  orgId: string,
): Promise<any> | null => {
  const url = `${getAPIUrl()}/orgs/${orgId ?? campaign.orgId}/campaigns/${campaign.campaignId}`

  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    const requestOptions = buildRequestOptions(token, 'DELETE')

    const response = await fetch(url, requestOptions)

    validateActionResponse(
      response,
      requestOptions,
      `delete campaign ${campaign.name}`,
    )

    return await response.json()
  }

  return null
}
