import React from 'react'
import { FirstLoginBackground, LoginBody } from './Styles'
import { TemplatePageProps } from '../../shared/types'
import { RootState } from '../../store/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FirstLoginForm from './components/FirstLoginForm'
import { Header } from '../../shared/components/Header'
import { ColoredFooter } from '../../shared/components/LayoutTemplate'

export const FirstLoginPage = ({
  isLoggedIn,
  user,
}: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()

  const currentPath = location.pathname

  React.useEffect(() => {
    if (isLoggedIn && currentPath === '/' && !user.first_login) navigate('/organization')
  }, [isLoggedIn, currentPath, navigate])

  return (
    <FirstLoginBackground>
      <Header />
      <LoginBody>
        <FirstLoginForm />
      </LoginBody>
      <ColoredFooter />
    </FirstLoginBackground>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  isLoggedIn: state.login.isLoggedIn,
})

export default connect(mapStateToProps)(FirstLoginPage)
