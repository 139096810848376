import type { Installation } from './types'

export const defaultInstallation: Installation = {
  name: '',
  type: 'INTEGRATED',
  minimumAmount: 1,
  maximumAmount: 2,
  feeSettings: {
    percentage: 0,
    fixedAmount: 0,
  },
  emvConfigId: '',
  currency: 'NZD',
}
