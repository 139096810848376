import React from 'react'
import type { TextInputProps } from './types'
import { TextInput } from './TextInput'
import styled from 'styled-components'


export function ColorInput(props: TextInputProps): React.ReactNode {
  const {
    value,
    className,
  } = props

  const isValidColor = CSS.supports('color', value.toString())
  const squareColor = isValidColor && `${value}` || '#000'

  return (
    <ColorInputWrapper>
      <div className={className}>
        <TextInput
        {...props}
        type="text"
        maxlength='6'
        placeholder="#000"
        />
      </div>
      <label htmlFor={`colorInput${props.name}`}>
        <ColorSquare $color={squareColor}/>
      </label>
      <input type="color" onChange={props.onChange} value={props.value} className={'colorInput'} id={`colorInput${props.name}`} />
    </ColorInputWrapper>
  )
}

const ColorSquare = styled.div<{$color: string}>`
  background-color: ${({$color}) => $color};
  border: 1px solid grey;
  height: 100%;
  border-radius: 4px;
`

const ColorInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  > div:first-child {
    width: calc(100% - 42px);
  }

  label {
    height: 40px;
    width: 40px;
    align-self: end;
  }

  .colorInput {
    width: 0px;
    height: 0px;
    position: absolute;
  }
`

