import store, { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import {
  EmvConfigEntity,
  FormError,
  TemplatePageProps,
} from '../../shared/types'
import React, { useReducer, useState } from 'react'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import {
  CustomDropdown,
  DropdownOption,
} from '../../shared/components/Dropdown'
import { getEmvConfigList } from '../Campaign/api'
import { showNotification } from '../../shared/components/Alert'
import { Merchant } from '../Merchant/types'
import { getMerchantListByOrgId } from '../Merchant/api'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Title } from '../../shared/components/title/Title'
import formReducer from '../../shared/reducers/formReducer'
import { defaultInstallation } from './constants'
import { TextInput } from '../../shared/components/input/TextInput'
import styles from './Installation.module.css'
import { Action } from '../User/styles'
import Button from '../../shared/components/button/Button'
import { ButtonVariant } from '../../shared/components/button/constants'
import { getInstallation, saveInstallation } from './api'
import { formatMerchantResponseError } from '../Merchant/utils'
import { Installation } from './types'
import { useLocation } from 'react-router-dom'

const Installations = ({ user }: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const canCreate = hasPermission(user, 'installation', RolePermission.CREATE)
  const [emvConfigList, setEmvConfigList] = React.useState<DropdownOption[]>([])
  const [merchantList, setMerchantList] = useState<DropdownOption[]>([])
  const [selectedMerchant, setSelectedMerchant] = useState<any>({})
  const location = useLocation()
  const currentMerchantId = location?.state?.merchantId
  const [form, dispatch] = useReducer(formReducer, {
    ...defaultInstallation,
  })
  const [errors, setErrors] = React.useState<FormError>({})
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const selectedOrganization =
    store.getState().organization.selectedOrganization

  React.useEffect(() => {
    const orgId =
      form.orgId ??
      selectedOrganization?.orgId ??
      orgList[0]?.orgId ??
      user.orgId
    if (orgId !== form.orgId) handleInputChange('orgId', orgId)
    getMerchantListByOrgId(user, orgId)
      .then((options: Merchant[]) => {
        const merchantOptions = []
        if (options && options.length) {
          options.map(option => {
            const merchantOption = {
              name: option.name || option.cardAcceptorInformation.name,
              value: option.merchantId,
            }
            merchantOptions.push(merchantOption)
          })
        }
        setMerchantList(merchantOptions)
        if (merchantOptions.length === 0) {
          showNotification(
            'danger',
            'No merchants found, please create a merchant first.',
          )
          return
        }
        handleInputChange('merchantId', merchantOptions[0].value)
      })
      .catch(() => {
        showNotification('danger', 'Failed to load merchants list.')
      })

    getEmvConfigList(user)
      .then((options: EmvConfigEntity[]) => {
        const emvConfigOptions = []
        if (options && options.length) {
          options.map(option => {
            const emvConfigOption = {
              name: option.name,
              value: option.emvConfigId,
            }
            emvConfigOptions.push(emvConfigOption)
          })
        }
        setEmvConfigList(emvConfigOptions)
        handleInputChange('emvConfigId', emvConfigOptions[0].value)
      })
      .catch(() => {
        showNotification('danger', 'Failed to load emvConfigs list.')
      })
      .finally(() => {
        if (user && id !== 'new') {
          getInstallation(
            user,
            selectedOrganization.orgId,
            id,
          )
            .then((loadedInstallation: Installation) => {
              if (loadedInstallation) {
                const payload: Installation = {
                  ...defaultInstallation,
                  ...loadedInstallation,
                }
                dispatch({ type: 'LOAD_FORM_VALUES', payload: payload })
              } else {
                showNotification('warning', 'Failed to load installation')
              }
            })
            .catch(() => {
              showNotification(
                'danger',
                'Failed to load installation, try again later.',
              )
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      })
  }, [form.orgId])

  React.useEffect(() => {
    if (emvConfigList.length > 0) {
      handleInputChange(
        'emvConfigId',
        emvConfigList.find(emvConfig => emvConfig.value === form.emvConfigId)
          ?.value ?? emvConfigList[0].value,
      )
    }
  }, [emvConfigList])

  React.useEffect(() => {
    if (merchantList.length > 0 && currentMerchantId) {
      const merchant = merchantList.find(
        merchant => merchant.value === currentMerchantId,
      )
      setSelectedMerchant(merchant)
    }
    if (id?.toLocaleLowerCase() === 'new') setIsLoading(false)
  }, [merchantList])

  const handleInputChange = (
    field: string,
    value: string,
    objectMap?: string,
  ) => {
    dispatch({
      type: 'HANDLE_FIELD_UPDATE',
      field,
      payload: value,
      objectMap,
    })
  }

  const onSave = () => {
    saveInstallation(user, form, id).then(response => {
      if (response.error) {
        if (response.message?.length && response.statusCode === 400) {
          const formattedError: FormError =
            formatMerchantResponseError(response)
          setErrors(formattedError)
          showNotification(
            'danger',
            'Failed to save installation, check the field errors.',
          )
        }
      } else {
        showNotification(
          'success',
          `Installation ${form.name} saved successfully.`,
        )
        navigate('/installations')
      }
    })
  }

  return canCreate ? (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <Wrapper>
          <SubTitle>Installations</SubTitle>
          <Title>
            {id === 'new' ? 'New Installation' : 'Edit Installation'}
          </Title>
          <Block>
            <TextInput
              label="Installation Name"
              name="name"
              placeholder=""
              type="text"
              value={form.name}
              onChange={e => handleInputChange('name', e.target.value)}
              hasError={!!errors.name}
              info={errors.name}
              infoType={errors.name ? 'error' : null}
            />
          </Block>
          <Block>
            <TextInput
              label="Organization"
              name="orgId"
              type="text"
              value={selectedOrganization.name}
              disabled
            />
          </Block>
          <Block>
            <CustomDropdown
              label="EMV Config"
              name="emvConfigId"
              options={emvConfigList}
              useOptionName
              onChange={e => handleInputChange('emvConfigId', e.target.value)}
              value={form.emvConfigId}
            />
            {id === 'new' ? (
              <CustomDropdown
                label="Merchant"
                name="merchantId"
                options={merchantList}
                useOptionName
                onChange={e => handleInputChange('merchantId', e.target.value)}
                value={form.merchantId}
              />
            ) : (
              <TextInput
                label="Merchant"
                name="merchantId"
                type="text"
                value={selectedMerchant.name}
                disabled
              />
            )}
          </Block>
          <div className={styles.title}>Installation Settings</div>
          <Block>
            <TextInput
              label="Currency"
              name="name"
              placeholder=""
              type="text"
              value={form.currency}
              onChange={e => handleInputChange('currency', e.target.value)}
              hasError={!!errors.currency}
              info={errors.currency}
              infoType={errors.currency ? 'error' : null}
            />
          </Block>
          <Block>
            <CustomDropdown
              label="Installation Type"
              name="type"
              options={[
                { value: 'INTEGRATED', name: 'Integrated' },
                { value: 'STANDALONE', name: 'Standalone' },
              ]}
              useOptionName
              onChange={e => handleInputChange('type', e.target.value)}
              value={form.type}
            />
          </Block>
          <div className={styles.title}>Fees</div>
          <Block>
            <TextInput
              label="Fee fixed amount"
              name="fixedAmount"
              placeholder=""
              type="number"
              value={form.feeSettings.fixedAmount}
              onChange={e =>
                handleInputChange('fixedAmount', e.target.value, 'feeSettings')
              }
              hasError={!!errors.fixedAmount}
              info={errors.fixedAmount}
              infoType={errors.fixedAmount ? 'error' : null}
            />
            <TextInput
              label="Fee percentage"
              name="percentage"
              placeholder=""
              type="number"
              value={form.feeSettings.percentage}
              onChange={e =>
                handleInputChange('percentage', e.target.value, 'feeSettings')
              }
              hasError={!!errors.percentage}
              info={errors.percentage}
              infoType={errors.percentage ? 'error' : null}
            />
          </Block>
          <div className={styles.title}>Any Amount Range</div>
          <Block>
            <TextInput
              label="Minimum Amount"
              name="minimumAmount"
              placeholder=""
              type="number"
              value={form.minimumAmount}
              onChange={e => handleInputChange('minimumAmount', e.target.value)}
              hasError={!!errors.minimumAmount}
              info={errors.minimumAmount}
              infoType={errors.minimumAmount ? 'error' : null}
            />
            <TextInput
              label="Maximum Amount"
              name="maximumAmount"
              placeholder=""
              type="number"
              value={form.maximumAmount}
              onChange={e => handleInputChange('maximumAmount', e.target.value)}
              hasError={!!errors.maximumAmount}
              info={errors.maximumAmount}
              infoType={errors.maximumAmount ? 'error' : null}
            />
          </Block>
          <Action>
            <Button disabled={merchantList.length === 0} onClick={onSave}>
              Save
            </Button>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={() => {
                navigate('/installations/')
              }}
            >
              Go to all installations
            </Button>
          </Action>
        </Wrapper>
      )}
    </>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(Installations)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 28px;
  max-width: 933px;
  min-width: 616px;
  justify-content: center;
  align-self: center;
`

export const SubTitle = styled.div`
  color: var(--content-subtle);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
`
