import { Merchant } from './types'

const { VITE_MERCHANT_CUSTOMER_ID } = import.meta.env

export const defaultMerchant: Merchant = {
  acquirerId: '',
  customerId: VITE_MERCHANT_CUSTOMER_ID,
  merchantCategoryCode: '',
  cardAcceptorInformation: {
    terminalIdentifier: '12345678',
    identifier: 'mpp_cybs_test',
    name: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: '',
  },
  type: 'softPos',
  acquirerInformation: {
    bankId: '',
    country: '',
    annualCCV: undefined,
    settlementType: '',
    avgTicketSize: undefined,
    delayedSettlement: '',
    refund: {
      dailyLimit: 0,
      isEnabled: false,
    },
    mdr: '',
  },
  name: '',
  portfolioId: '',
  servicePortfolioId: '',
  merchantId: '',
  orgId: '',
  createdAt: '',
  updatedAt: '',
}
