import styled from 'styled-components'
import SektorBg from '../../shared/assets/sektor_background.svg'


export const FirstLoginBackground = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const LoginBackground = styled(FirstLoginBackground)`
  background-color: var(--brand-primary);

  &:after {
    @media (min-height: 800px) {
      content: '';
      border-top-left-radius: 50% 90%;
      border-top-right-radius: 50% 90%;
      z-index: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--grey-0);
      height: 40%;
    }
    @media (max-height: 799px) {
      content: '';
      border-top-left-radius: 50% 90%;
      border-top-right-radius: 50% 90%;
      z-index: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--brand-primary);
      height: 40%;
    }
  }
`

export const SektorBackground = styled(FirstLoginBackground)`
  background-image: url("${SektorBg}");
  background-repeat: no-repeat;
  background-size: 100%;


  &:after {
    @media (min-height: 800px) {
      z-index: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--grey-0);
      height: 40%;
    }
    @media (max-height: 799px) {
      z-index: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--brand-primary);
      height: 40%;
    }
  }
`

export const LoginBody = styled.div`
  font-size: 16px;
  z-index: 1;
  margin: 0 auto auto auto;
  height: calc(100% - 205px);
  display: flex;
  flex-direction: column;

  > div {
    width: calc(280px + 3.5vw);
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    margin: auto;
  }
`

export const FirstLoginBody = styled.div
