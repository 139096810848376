import { useEffect, type MutableRefObject } from 'react'

export function useClickOutside<T extends HTMLElement>(
  ref: MutableRefObject<T>,
  onClickOutside: () => void,
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        onClickOutside()
    }

    const checkEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClickOutside()
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', checkEscKey)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', checkEscKey)
    }
  }, [ref, onClickOutside])
}
