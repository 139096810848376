import React from 'react'
import styled from 'styled-components'

const ActionToggle = ({ value, setValue, label, name }) => {

    const realValue = (typeof value === 'string' && value === 'true') || value === true ? true : false

    return (
      <ToggleWrapper>
        <div>
          <input
                type="checkbox" id={`actionToggle${name}`} value={value} onChange={() => {
                    setValue(!realValue)
                }}
                checked={realValue}
          />
          <label htmlFor={`actionToggle${name}`}></label>
        </div>
        <span>{label}</span>
      </ToggleWrapper>
    )
}

export default ActionToggle

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;

    > div {
        margin-top: 2px;

        label {
            display: inline-block;;
            width: 28px;
            height: 16px;
            background-color: #ccc;
            border-radius: 10px;
            position: relative;
            cursor: pointer;
        }

        label::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            background-color: white;
            border-radius: 50%;
            transition: transform 0.3s;
        }
        
        input {
            display: none;
        }

        input:checked + label {
            background-color: var(--content-primary);
        }

        input:checked + label::before {
            transform: translateX(12px);
        }
    }

    > span {
        margin-top: 2px;
        color: var(--content-primary);
        font-family: var(--font-family);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
    }
    
`
