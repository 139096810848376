import React, { ReactNode, useEffect, useState } from 'react'
import { MikaUser } from '../../shared/types'
import { useNavigate } from 'react-router-dom'
import { Merchant } from './types'
import { showNotification } from '../../shared/components/Alert'
import Button from '../../shared/components/button/Button'
import Modal from '../../shared/components/modal/Modal'
import { Confirmation } from '../../shared/components/confirmation/Confirmation'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { hasPermission } from '../../shared/utils'
import { deleteMerchant, getMerchantListByOrgId } from './api'
import { RootState } from '../../store/store'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Message from '../../shared/components/message/Message'
import { ButtonVariant } from '../../shared/components/button/constants'
import { RolePermission } from '../../shared/constants'

const MerchantTableHeader = (): ReactNode => (
  <thead>
    <tr>
      <th>Id</th>
      <th>Type</th>
      <th>Name</th>
      <th>Actions</th>
    </tr>
  </thead>
)

const MerchantTable = ({
  orgId,
  user,
}: {
  orgId: string
  user: MikaUser
}): ReactNode => {
  const [isLoading, setIsLoading] = useState(true)
  const [merchantList, setMerchantList] = useState<Merchant[]>([])
  const [merchant, setMerchant] = useState<Merchant>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const canCreate = hasPermission(user, 'merchant', RolePermission.CREATE)
  const canRead = hasPermission(user, 'merchant', RolePermission.READ)
  const canUpdate = hasPermission(user, 'merchant', RolePermission.UPDATE)
  const canDelete = hasPermission(user, 'merchant', RolePermission.DELETE)
  const navigate = useNavigate()

  const loadMerchantList = () => {
    return getMerchantListByOrgId(user, orgId)
      .then((options: Merchant[]) => {
        setMerchantList(options)
      })
      .catch(e => {
        showNotification('danger', 'Failed to load merchants list.')
      })
  }

  const onEditMerchant = (merchantId: string) => {
    navigate(`/merchant/${merchantId}/${orgId}`)
  }

  const onAddMerchant = (value: string) => {
    navigate(`/merchant/new/${value}`)
  }

  const onRequestDeleteMerchant = (value: Merchant) => {
    setMerchant(value)
    setIsModalOpen(true)
  }

  const onDeleteMerchant = () => {
    setIsLoading(true)
    setIsModalOpen(false)
    deleteMerchant(user, orgId, merchant.merchantId)
      .then(() => {
        setIsLoading(false)
        showNotification('success', 'Merchant was deleted')
      })
      .catch(() => {
        setIsLoading(false)
        showNotification('success', 'Could not delete Merchant')
      })
  }

  useEffect(() => {
    if (isLoading) {
      loadMerchantList().finally(() => {
        setIsLoading(false)
      })
    }
  }, [orgId, user, isLoading])

  return (
    <Wrapper>
      {!canRead ? (
        <h1>You do not have permission to use this function.</h1>
      ) : (
        <>
          <LoadingOverlay isLoading={isLoading} />
          <h4>Merchant Configurations</h4>

          {(merchantList && merchantList.length && (
            <>
              <MerchantTableWrapper>
                <table className="table">
                  <MerchantTableHeader />
                  <tbody>
                    {!!merchantList &&
                      merchantList.map(merchant => (
                        <tr key={merchant.merchantId}>
                          <td>{merchant.merchantId}</td>
                          <td>{merchant.type}</td>
                          <td>{merchant.name}</td>
                          <td>
                            <div className="actions">
                              {canUpdate && (
                                <i
                                  className="bi bi-pencil-square"
                                  aria-description="Edit"
                                  onClick={() =>
                                    onEditMerchant(merchant.merchantId)
                                  }
                                />
                              )}
                              {canDelete && (
                                <i
                                  className="bi bi-trash"
                                  aria-description="Delete"
                                  onClick={() =>
                                    onRequestDeleteMerchant(merchant)
                                  }
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </MerchantTableWrapper>
              {canCreate && (
                <Button
                  variant={ButtonVariant.TONAL}
                  onClick={() => onAddMerchant(orgId)}
                >
                  + Add new Merchant
                </Button>
              )}
            </>
          )) || (
            <Message
              title="No merchants found"
              primaryAction={() => onAddMerchant(orgId)}
              primaryButtonText={canCreate ? '+ Add new Merchant' : null}
              summary="There are no merchants for this organization yet"
            />
          )}
        </>
      )}
      {isModalOpen && (
        <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
          <Confirmation
            title="Are you sure?"
            message="This action cannot be undone."
            onConfirm={() => {
              onDeleteMerchant()
            }}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(MerchantTable)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--grey-0);
  width: 100%;
  position: relative;
  margin: 32px 0;

  h4 {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
  }
`

export const MerchantTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    > tr > th {
      color: #000;
    }
  }

  tr {
    :first-child {
      padding-left: 32px;
    }
    th {
      border-bottom-width: 0;
    }
  }

  td {
    border-bottom-width: 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;

    i {
      cursor: pointer;
      padding: 0;
    }
  }

  > button {
    margin: 16px 32px;
  }
`
