import React from 'react'
import { TransactionCardProps } from './types'
import styled from 'styled-components'
import { Icon } from '../../shared/components/icon/Icon'
import styles from './TransactionCard.module.css'

export const TransactionCard = (
  props: TransactionCardProps,
): React.ReactNode => {
  return (
    <Wrapper>
      <Icon name={props.icon} className={styles.icon} />
      <Title>{props.title}</Title>
      <Value>{props.value}</Value>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  min-width: 196px;
  height: 100%;
  padding: 24px 20px;
  text-align: left;
  border-radius: 8px;
  border: 1px solid var(--content-inverse-secondary);
`

const Title = styled.div`
  color: var(--content-subtle);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const Value = styled.div`
  color: var(--content-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`
