import React from 'react'
import { styled } from 'styled-components'
import {
  CountryDropdown,
  CustomDropdown,
} from '../../shared/components/Dropdown'
import { LogoComponent } from '../../shared/components/Logos'
import { FormPageContainer, FormPageInfoWrapper, StyledHr } from '../../shared/components/LayoutTemplate'
import { FormLogoDiv } from '../../shared/components/Template/Containers'
import {
  FormError,
  MikaUser,
  Organization,
  TemplatePageProps,
} from '../../shared/types'
import { connect } from 'react-redux'
import { RootState } from '../../store/store'
import {
  createOrganization,
  getOrganizationById,
  updateOrganizationRequest,
  uploadFiles,
} from './service/api'
import formReducer, { FormState } from '../../shared/reducers/formReducer'
import {
  createDebouncedFunction,
  formatResponseError,
  hasPermission,
  isFieldVisible,
} from '../../shared/utils'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { showNotification } from '../../shared/components/Alert'
import Button from '../../shared/components/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { isNil, omitBy } from 'lodash'
import { emptyOrganization } from './constants'
import { TextInput } from '../../shared/components/input/TextInput'
import Upload from '../../shared/components/upload/Upload'
import { Icon } from '../../shared/components/icon/Icon'
import { ButtonVariant } from '../../shared/components/button/constants'
import { RolePermission } from '../../shared/constants'
import MerchantTable from '../Merchant/MerchantTable'
import ChangeImageButton from '../../shared/components/upload/ChangeImage'
import { InputBoolean } from '../../shared/components/InputForm'

type HandleInputChange = (
  field: string,
  value: string,
  objectMap?: string,
) => void

const titles = [
  { name: 'Mr.', value: 'Mr.' },
  { name: 'Mrs.', value: 'Mrs.' },
  { name: 'Ms.', value: 'Ms.' },
  { name: 'Miss', value: 'Miss' },
  { name: 'Other', value: 'Other' },
]

export const OrganizationPage = ({
  user,
}: TemplatePageProps): React.ReactNode => {
  const { id } = useParams<{ id: string }>()
  const [organizationForm, dispatch] = React.useReducer(
    formReducer,
    emptyOrganization,
  )
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasChanges, setHasChanges] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [selectedOrg, setSelectedOrg] = React.useState(emptyOrganization)
  const [errors, setErrors] = React.useState<FormError>({})
  const [logoFileContent, setLogoFileContent] = React.useState<
    string | ArrayBuffer
  >(null)
  const [signatureFileContent, setSignatureFileContent] = React.useState<
    string | ArrayBuffer
  >(null)
  const [errorText, setErrorText] = React.useState<string>('')
  const [signatureErrorText, setSignatureErrorText] = React.useState<string>('')
  const navigate = useNavigate()
  const canUpdate = hasPermission(user, 'organization', RolePermission.UPDATE)
  const canCreate = hasPermission(user, 'organization', RolePermission.CREATE)

  const dropHandler = (content: string | ArrayBuffer) => {
    setErrors({ ...errors, logo: null})
    setLogoFileContent(content)
    setHasChanges(true)
  }

  const signatureDropHandler = (content: string | ArrayBuffer) => {
    setSignatureFileContent(content)
    setHasChanges(true)
  }

  const handleInputChange = (
    field: string,
    value: string | boolean,
    objectMap?: string,
  ) => {
    setHasChanges(true)
    if (objectMap) {
      dispatch({
        type: 'HANDLE_MERCHANT_CONFIGURATION',
        field,
        payload: value,
        objectMap,
      })

      return
    }

    dispatch({ type: 'HANDLE_FIELD_UPDATE', field, payload: value })
  }

  const submitOrganization = (mikaUser: MikaUser) => {
    if (user && hasChanges && !isSaving) {
      setIsSaving(true)
      setErrors({})

      const cleanedOrganization = omitBy(organizationForm, isNil)

      if (id && organizationForm.orgId) {
        updateOrganizationRequest(mikaUser, cleanedOrganization)
          .then(response => {
            if (response && response.error) {
              if (response.message?.length && response.statusCode === 400) {
                const formattedError: FormError = formatResponseError(response)
                setErrors(formattedError)

                showNotification(
                  'danger',
                  'Failed to update organization, check the field errors.',
                )

                return
              }

              showNotification(
                'danger',
                `Failed to update organization, due to the ${response.error}.`,
              )

              return
            }

            showNotification('success', 'Organization was updated!')
            uploadFiles(
              user,
              id,
              logoFileContent as string,
              signatureFileContent as string,
            ).then(x => {
              showNotification('success', 'Organization was updated!')
              if (x[0] || x[1]) {
                showNotification(
                  'danger',
                  `Failed to upload files (${x[0] || x[1]})`,
                )
              }

              setErrorText(x[0])
              setSignatureErrorText(x[1])
            })
          })
          .catch(e => {
            showNotification(
              'danger',
              'Failed to save organization, try again later.',
            )
            console.error({ e })
          })
          .finally(() => {
            setIsSaving(false)
          })
      } else {
        createOrganization(user, cleanedOrganization)
          .then(response => {
            if (typeof response !== 'string' && response && response.error) {
              if (response.message?.length && response.statusCode === 400) {
                const formattedError: FormError = formatResponseError(response)
                setErrors(formattedError)

                showNotification(
                  'danger',
                  'Failed to register organization, check the field errors.',
                )

                return
              }

              showNotification(
                'danger',
                `Failed to register organization, due to the ${response.error}.`,
              )

              return
            }
            showNotification('success', 'Organization registered.')
            uploadFiles(
              user,
              response as string,
              logoFileContent as string,
              signatureFileContent as string,
            ).then(x => {
              setErrorText(x[0])
              setSignatureErrorText(x[1])
              if (x[0] || x[1]) {
                showNotification(
                  'danger',
                  `Failed to upload files (${x[0] || x[1]})`,
                )
              }
              navigate(`/organization/${response}`)
            })
          })
          .finally(() => {
            setIsSaving(false)
          })
      }
    }
  }

  const debouncedHandleInputChange = createDebouncedFunction(
    handleInputChange,
    20,
  )

  React.useEffect(() => {
    if (id && id.toLocaleLowerCase() === 'new') {
      setIsLoading(false)

      return
    }
    if (user && id) {
      getOrganizationById(user, id)
        .then((e: Organization) => {
          if (e) {
            const payload: FormState = { ...emptyOrganization, ...e }
            setSelectedOrg(e)
            dispatch({ type: 'LOAD_FORM_VALUES', payload: payload })
          } else {
            showNotification('warning', 'Failed to load organization')
          }
        })
        .catch(e => {
          showNotification(
            'danger',
            'Failed to load organization, try again later.',
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [id, user])

  return (id === 'new' && canCreate) || (id !== 'new' && canUpdate) ? (
    <FormPageContainer>
      <div>
        <LoadingOverlay isLoading={isLoading || isSaving} />
        {!isLoading && (
          <>
            <h2>{id === 'new' ? 'New organization' : 'Edit organization'}</h2>
            <form>
              <FormPageInfoWrapper>
                <h4 className='group-title'>About</h4>
                <TextInput
                  label="Organization ID:"
                  name="org-id"
                  placeholder=""
                  type="text"
                  onChange={e => handleInputChange('orgId', e.target.value)}
                  value={organizationForm.orgId}
                  hasError={!!errors.orgId}
                  info={errors.orgId}
                  infoType={errors.orgId ? 'error' : null}
                  disabled
                />
                <div className="row-div">
                  <TextInput
                    label="Organization Name:"
                    name="org-name"
                    placeholder="Mika Pay Tech"
                    type="text"
                    onChange={e => handleInputChange('name', e.target.value)}
                    value={organizationForm.name}
                    hasError={!!errors.name}
                    info={errors.name}
                    infoType={errors.name ? 'error' : null}
                    tabIndex={1}
                  />
                  <TextInput
                    label="ABN/NZBN:"
                    name="businessNumber"
                    placeholder=""
                    type="text"
                    onChange={e =>
                      handleInputChange('businessNumber', e.target.value)
                    }
                    value={organizationForm.businessNumber}
                    hasError={!!errors.businessNumber}
                    info={errors.businessNumber}
                    infoType={errors.businessNumber ? 'error' : null}
                    tabIndex={2}
                  />
                </div>
                <div className="row-div">
                  <TextInput
                    label="Address Line 1:"
                    name="org-address"
                    placeholder="Street."
                    type="text"
                    onChange={e =>
                      handleInputChange('addressLine1', e.target.value)
                    }
                    value={organizationForm.addressLine1}
                    hasError={!!errors.addressLine1}
                    info={errors.addressLine1}
                    infoType={errors.addressLine1 ? 'error' : null}
                    tabIndex={3}
                  />
                  <TextInput
                    label="Address Line 2:"
                    name="org-address"
                    placeholder="100, 204"
                    type="text"
                    onChange={e =>
                      handleInputChange('addressLine2', e.target.value)
                    }
                    value={organizationForm.addressLine2}
                    hasError={!!errors.addressLine2}
                    info={errors.addressLine2}
                    infoType={errors.addressLine2 ? 'error' : null}
                    tabIndex={4}
                  />
                </div>
                <div className="row-div">
                  <TextInput
                    label="Suburb/State:"
                    name="org-stateProvince"
                    placeholder="Center"
                    type="text"
                    onChange={e =>
                      handleInputChange('stateProvince', e.target.value)
                    }
                    value={organizationForm.stateProvince}
                    hasError={!!errors.stateProvince}
                    info={errors.stateProvince}
                    infoType={errors.stateProvince ? 'error' : null}
                    tabIndex={5}
                  />
                  <TextInput
                    label="City:"
                    name="org-city"
                    placeholder="New York"
                    type="text"
                    onChange={e => handleInputChange('city', e.target.value)}
                    value={organizationForm.city}
                    hasError={!!errors.city}
                    info={errors.city}
                    infoType={errors.city ? 'error' : null}
                    tabIndex={6}
                  />
                </div>
                <div className="row-div">
                  <CountryDropdown
                    label="Country:"
                    name="org-country"
                    onChange={e => handleInputChange('country', e.target.value)}
                    value={organizationForm.country}
                    tabIndex={7}
                  />
                  <TextInput
                    label="Postal Code:"
                    name="org-zip-code"
                    placeholder="10190080"
                    type="text"
                    onChange={e =>
                      handleInputChange('postcode', e.target.value)
                    }
                    value={organizationForm.postcode}
                    hasError={!!errors.postcode}
                    info={errors.postcode}
                    infoType={errors.postcode ? 'error' : null}
                    tabIndex={8}
                  />
                </div>
                <div className="row-div">
                  {isFieldVisible(user, 'orgCharityNumber') && (
                    <TextInput
                      label="Charity COMM Number:"
                      name="org-comm-number"
                      placeholder="#000"
                      type="text"
                      onChange={e =>
                        handleInputChange('charityCommNumber', e.target.value)
                      }
                      value={organizationForm.charityCommNumber}
                      hasError={!!errors.charityCommNumber}
                      info={errors.charityCommNumber}
                      infoType={errors.charityCommNumber ? 'error' : null}
                      tabIndex={9}
                    />
                  )}
                  <TextInput
                    label="GST Number:"
                    name="org-gst-number"
                    placeholder="#000"
                    type="text"
                    onChange={e =>
                      handleInputChange('gstNumber', e.target.value)
                    }
                    value={organizationForm.gstNumber}
                    hasError={!!errors.gstNumber}
                    info={errors.gstNumber}
                    infoType={errors.gstNumber ? 'error' : null}
                    tabIndex={10}
                  />
                </div>
                <TextInput
                  label="Status:"
                  name="org-status"
                  placeholder=""
                  type="text"
                  onChange={e => handleInputChange('status', e.target.value)}
                  hasError={!!errors.status}
                  info={errors.status}
                  infoType={errors.status ? 'error' : null}
                  value={organizationForm.status}
                  tabIndex={11}
                />
                <TextInput
                  label="Website:"
                  name="org-website"
                  placeholder=""
                  type="text"
                  onChange={e => handleInputChange('website', e.target.value)}
                  hasError={!!errors.website}
                  info={errors.website}
                  infoType={errors.website ? 'error' : null}
                  value={organizationForm.website}
                  tabIndex={12}
                />
                {isFieldVisible(user, 'orgLogo') && (
                  <>
                    <>
                      <label htmlFor="logoAnchor">
                        Organization logo URL:
                      </label>
                    </>
                    <div className={!logoFileContent && !organizationForm.logo ? 'column-div' : 'row-div'}>
                      {(!!logoFileContent || organizationForm.logo) && (
                        <>
                          <LogoComponent
                              src={logoFileContent || organizationForm.logo}
                              size={'big'}
                          />
                          <ChangeImageButton
                            errorText={errors.logo as unknown as string}
                            type={/[/.](jpg|jpeg)$/i}
                            buttonText="Change image"
                            dropCallback={dropHandler}
                          />
                        </>
                      )}
                      {!logoFileContent && !organizationForm.logo && (
                        <Upload
                          errorText={errorText}
                          type={/[/.](jpg|jpeg)$/i}
                          descriptionText="JPG format • 2 MB Max"
                          uploadText="Drop image here"
                          dropCallback={dropHandler}
                        />
                      )}
                    </div>
                  </>
                )}
                {isFieldVisible(user, 'orgSignature') && (
                  <>
                    <TextInput
                      label="Signature File:"
                      name="org-signature-file"
                      placeholder="File URL"
                      type="text"
                      onChange={e =>
                        handleInputChange('signatureFile', e.target.value)
                      }
                      hasError={!!errors.signatureFile}
                      value={organizationForm.signatureFile}
                      infoType={errors.signatureFile ? 'error' : null}
                      info={errors.signatureFile}
                      tabIndex={14}
                    />
                    <div className="column-div">
                      {(!!signatureFileContent ||
                        organizationForm.signatureFile) && (
                        <>
                          <FormLogoDiv>
                            <LogoComponent
                              src={
                                signatureFileContent ||
                                organizationForm.signatureFile
                              }
                              size={'small'}
                            />
                          </FormLogoDiv>
                          {!!signatureFileContent && (
                            <Button
                              variant={ButtonVariant.TONAL}
                              onClick={() => setSignatureFileContent('')}
                            >
                              <Icon name="picture-upload.svg" />
                              Change Signature image
                            </Button>
                          )}
                        </>
                      )}
                      {!signatureFileContent && (
                        <Upload
                          errorText={signatureErrorText}
                          type={/[/.](jpg|jpeg)$/i}
                          descriptionText="JPG format • 2 MB Max"
                          uploadText="Drop image here"
                          dropCallback={signatureDropHandler}
                        />
                      )}
                    </div>
                  </>
                )}
              </FormPageInfoWrapper>
              <OrganizationDetails
                handleInputChange={debouncedHandleInputChange}
                organization={organizationForm}
                errors={errors}
              />
              {id !== 'new' && <MerchantTable orgId={organizationForm.orgId} />}
              <div>
                <Button
                  onClick={e => {
                    submitOrganization(user)
                    e.preventDefault()
                  }}
                >
                  {isSaving ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Saving...</span>
                    </div>
                  ) : (
                    <>{id === 'new' ? 'Submit' : 'Update'}</>
                  )}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </FormPageContainer>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(OrganizationPage)

const OrganizationDetails = ({
  handleInputChange,
  organization,
  errors,
}: {
  handleInputChange: HandleInputChange
  organization: FormState
  errors: FormError
}): React.ReactNode => {
  return (
    <FormPageInfoWrapper>
      <StyledHr />

      <h4 className='group-title'>Contact:</h4>

      <TitleDropdown
        label="Title: "
        options={titles}
        useOptionName
        value={organization?.contact?.title}
        onChange={e => handleInputChange('title', e.target.value, 'contact')}
        tabIndex={13}
      />

      <div className="row-div">
        <TextInput
          label="First Name:"
          name="contact-firstName"
          placeholder="John"
          type="text"
          onChange={e =>
            handleInputChange('firstName', e.target.value, 'contact')
          }
          value={organization?.contact?.firstName}
          hasError={!!errors.firstName}
          info={errors.firstName}
          infoType={errors.firstName ? 'error' : null}
          tabIndex={14}
        />

        <TextInput
          label="Second Name:"
          name="contact-lastName"
          placeholder="Doe"
          type="text"
          onChange={e =>
            handleInputChange('lastName', e.target.value, 'contact')
          }
          value={organization?.contact?.lastName}
          hasError={!!errors.lastName}
          info={errors.lastName}
          infoType={errors.lastName ? 'error' : null}
          tabIndex={15}
        />
      </div>

      <TextInput
        label="Email:"
        name="contact-mail"
        placeholder="johndoe@mika.com"
        type="email"
        onChange={e => handleInputChange('email', e.target.value)}
        value={organization?.email}
        hasError={!!errors.email}
        info={errors.email}
        infoType={errors.email ? 'error' : null}
        tabIndex={16}
      />
      <TextInput
        label="Phone number:"
        name="contact-number"
        placeholder="+2 55 9999-9991"
        type="phone"
        onChange={e => handleInputChange('phoneNumber', e.target.value)}
        value={organization?.phoneNumber}
        hasError={!!errors.phoneNumber}
        info={errors.phoneNumber}
        infoType={errors.phoneNumber ? 'error' : null}
        tabIndex={17}
      />
    </FormPageInfoWrapper>
  )
}

const TitleDropdown = styled(CustomDropdown)`
  width: 25% !important;
`
