import React from 'react'
import { createRoot } from 'react-dom/client'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import Navigation from './Navigation'

import { Provider } from 'react-redux'
import store from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { AppRootContext, WindowContext } from './context'

import { Amplify } from 'aws-amplify'
import { currentConfig } from './services/aws/config'
import { AlertList } from './shared/components/alert/AlertList'
import('./index.scss')

export const isSektorEnv = (import.meta.env.VITE_RETAIL && import.meta.env.VITE_RETAIL.trim() === 'sektor')

  if (isSektorEnv)
    import('./sektor.scss')
  
  if(!isSektorEnv)
    import('./mikapaytech.scss')

Amplify.configure(currentConfig)

const persistor = persistStore(store)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <WindowContext.Provider value={window}>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <AppRootContext.Provider value={document.getElementById('root')!}>
        <PersistGate loading={null} persistor={persistor}>
          <Navigation />
          <AlertList />
        </PersistGate>
      </AppRootContext.Provider>
    </WindowContext.Provider>
  </Provider>,
)
