import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import { FormError, TemplatePageProps } from '../../shared/types'
import React, { useReducer } from 'react'
import { Action } from '../User/styles'
import formReducer from '../../shared/reducers/formReducer'
import {
  createMerchant,
  getAcquirerList,
  getMerchantById,
  updateMerchant,
} from './api'
import { showNotification } from '../../shared/components/Alert'
import Button from '../../shared/components/button/Button'
import styles from './MerchantPage.module.css'
import { defaultMerchant } from './constants'
import { TextInput } from '../../shared/components/input/TextInput'
import { formatMerchantResponseError } from './utils'
import styled from 'styled-components'
import {
  CountryDropdown,
  CustomDropdown,
  CustomMdfDropdown,
  DropdownOption,
} from '../../shared/components/Dropdown'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { Merchant } from './types'
import { Title } from '../../shared/components/title/Title'
import { ButtonVariant } from '../../shared/components/button/constants'

const MerchantPage = ({ user }: TemplatePageProps): React.ReactNode => {
  const { id, orgId } = useParams<{ id: string; orgId: string }>()
  const [merchantForm, dispatch] = useReducer(formReducer, {
    ...defaultMerchant,
  })
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState<FormError>({})
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [acquirerList, setAcquirerList] = React.useState<DropdownOption[]>([])
  const canCreate = hasPermission(user, 'merchant', RolePermission.CREATE)
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const organisation = orgList.find(org => org.orgId === orgId)
  const [selectedTab, setSelectedTab] = React.useState('card')

  const handleInputChange = (
    field: string,
    value: unknown,
    objectMap?: string,
  ) => {
    dispatch({
      type: 'HANDLE_MERCHANT_CONFIGURATION',
      field,
      payload: value,
      objectMap,
    })
  }

  const onSubmitMerchant = () => {
    merchantForm.orgId = orgId
    if (id === 'new') {
      createMerchant(user, merchantForm).then(response => {
        if (response && response.error) {
          if (response.message?.length && response.statusCode === 400) {
            const formattedError: FormError =
              formatMerchantResponseError(response)
            setErrors(formattedError)

            showNotification(
              'danger',
              'Failed to create a merchant, check the field errors.',
            )

            return
          }

          showNotification(
            'danger',
            `Failed to create a merchant, due to the ${response.error}.`,
          )

          return
        }

        showNotification('success', 'Merchant successfully created!')
        navigate('/organization/')
      })
    } else {
      updateMerchant(user, merchantForm).then(response => {
        if (response && response.error) {
          if (response.message?.length && response.statusCode === 400) {
            const formattedError: FormError =
              formatMerchantResponseError(response)
            setErrors(formattedError)

            showNotification(
              'danger',
              'Failed to update a merchant, check the field errors.',
            )

            return
          }

          showNotification(
            'danger',
            `Failed to update a merchant, due to the ${response.error}.`,
          )

          return
        }

        showNotification('success', 'Merchant successfully updated!')
      })
    }
  }

  React.useEffect(() => {
    getAcquirerList(user)
      .then(list => {
        setAcquirerList(
          list.map(acquirer => ({
            name: acquirer.name,
            value: acquirer.acquirerId,
          })),
        )
      })
      .finally(() => {
        if (id?.toLocaleLowerCase() === 'new') setIsLoading(false)
      })

    if (user && id !== 'new') {
      getMerchantById(user, orgId, id)
        .then((loadedMerchant: Merchant) => {
          if (loadedMerchant) {
            const payload: Merchant = {
              ...defaultMerchant,
              ...loadedMerchant,
            }
            dispatch({ type: 'LOAD_FORM_VALUES', payload: payload })
          } else {
            showNotification('warning', 'Failed to load merchant')
          }
        })
        .catch(() => {
          showNotification(
            'danger',
            'Failed to load merchant, try again later.',
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [id, orgId, user])

  React.useEffect(() => {
    if (acquirerList.length > 0) {
      handleInputChange(
        'acquirerId',
        acquirerList.find(
          acquirer => acquirer.value === merchantForm.acquirerId,
        )?.value ?? acquirerList[0].value,
      )
    }
  }, [acquirerList])

  return canCreate ? (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <Wrapper>
          <OrgName>{organisation.name}</OrgName>
          <Title>{id === 'new' ? 'New Merchant' : 'Edit Merchant'}</Title>
          <Spacer />
          <Block>
            <TextInput
              label="Merchant ID:"
              name="merchant-id"
              placeholder=""
              type="text"
              value={merchantForm.merchantId}
              hasError={!!errors.merchantId}
              info={errors.merchantId}
              infoType={errors.merchantId ? 'error' : null}
              disabled
            />
          </Block>
          <Block>
            <CountryDropdown
              label="Country"
              name="country"
              onChange={e =>
                handleInputChange(
                  'country',
                  e.target.value,
                  'acquirerInformation',
                )
              }
              value={merchantForm.acquirerInformation.country}
            />
            <CustomDropdown
              label="Acquirer"
              name="acquirerId"
              onChange={e => handleInputChange('acquirerId', e.target.value)}
              value={merchantForm.acquirerId}
              options={acquirerList}
              useOptionName
            />
          </Block>
          <Block>
            <TextInput
              label="Annual credit card volume"
              name="annualCCV"
              placeholder="1800000"
              type="number"
              value={merchantForm.acquirerInformation.annualCCV}
              onChange={e =>
                handleInputChange(
                  'annualCCV',
                  e.target.value,
                  'acquirerInformation',
                )
              }
              hasError={!!errors.annualCCV}
              info={errors.annualCCV}
              infoType={errors.annualCCV ? 'error' : null}
            />
            <TextInput
              label="Avg ticket size"
              name="avgTicketSize"
              placeholder="25000"
              type="number"
              value={merchantForm.acquirerInformation.avgTicketSize}
              onChange={e =>
                handleInputChange(
                  'avgTicketSize',
                  e.target.value,
                  'acquirerInformation',
                )
              }
              hasError={!!errors.avgTicketSize}
              info={errors.avgTicketSize}
              infoType={errors.avgTicketSize ? 'error' : null}
            />
            <TextInput
              label="Settlement type"
              name="settlementType"
              placeholder="Net"
              type="text"
              value={merchantForm.acquirerInformation.settlementType}
              onChange={e =>
                handleInputChange(
                  'settlementType',
                  e.target.value,
                  'acquirerInformation',
                )
              }
              hasError={!!errors.settlementType}
              info={errors.settlementType}
              infoType={errors.settlementType ? 'error' : null}
            />
            <TextInput
              label="Delayed settlement"
              name="delayedSettlement"
              placeholder="T+1"
              type="text"
              value={merchantForm.acquirerInformation.delayedSettlement}
              onChange={e =>
                handleInputChange(
                  'delayedSettlement',
                  e.target.value,
                  'acquirerInformation',
                )
              }
              hasError={!!errors.delayedSettlement}
              info={errors.delayedSettlement}
              infoType={errors.delayedSettlement ? 'error' : null}
            />
            <CustomMdfDropdown
              label="Merchant Discount Fee excl  GST"
              name="mdr"
              onChange={e => handleInputChange(
                'mdr',
              e.target.value,
              'acquirerInformation',
              )}
              value={merchantForm.acquirerInformation.mdr}
              tabIndex={4}
            />
          </Block>
          <Spacer />
          <Tab>
            <div
              onClick={() => setSelectedTab('card')}
              className={`${styles.title} ${selectedTab === 'card' ? styles.active : null}`}
            >
              Card Acceptor
            </div>
            <div
              onClick={() => setSelectedTab('pos')}
              className={`${styles.title} ${selectedTab === 'pos' ? styles.active : null}`}
            >
              Pos Configuration
            </div>
          </Tab>
          <div className={selectedTab === 'card' ? null : styles.hidden}>
            <Block>
              <TextInput
                label="Name"
                name="cardAcceptorName"
                placeholder="Sports Pathway"
                type="text"
                value={merchantForm.cardAcceptorInformation.name}
                onChange={e =>
                  handleInputChange(
                    'name',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_name}
                info={errors.cardAcceptorInformation_name}
                infoType={errors.cardAcceptorInformation_name ? 'error' : null}
              />
              <TextInput
                label="Merchant Category Code"
                name="merchantCategoryCode"
                placeholder="0000"
                type="text"
                value={merchantForm.merchantCategoryCode}
                onChange={e =>
                  handleInputChange('merchantCategoryCode', e.target.value)
                }
                hasError={!!errors.merchantCategoryCode}
                info={errors.merchantCategoryCode}
                infoType={errors.merchantCategoryCode ? 'error' : null}
              />
            </Block>
            <Block>
              <TextInput
                label="City"
                name="cardAcceptorCity"
                placeholder="Te Puke"
                maxLength={13}
                type="text"
                value={merchantForm.cardAcceptorInformation.city}
                onChange={e =>
                  handleInputChange(
                    'city',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_city}
                info={errors.cardAcceptorInformation_city}
                infoType={errors.cardAcceptorInformation_city ? 'error' : null}
              />
              <TextInput
                label="State Code"
                name="cardAcceptorStateCode"
                placeholder="123"
                maxLength={3}
                type="text"
                value={merchantForm.cardAcceptorInformation.stateCode}
                onChange={e =>
                  handleInputChange(
                    'stateCode',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_stateCode}
                info={errors.cardAcceptorInformation_stateCode}
                infoType={
                  errors.cardAcceptorInformation_stateCode ? 'error' : null
                }
              />
              <TextInput
                label="Postcode/ZIP code"
                name="cardAcceptorPostalCode"
                placeholder="3119"
                type="text"
                value={merchantForm.cardAcceptorInformation.postalCode}
                onChange={e =>
                  handleInputChange(
                    'postalCode',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_postalCode}
                info={errors.cardAcceptorInformation_postalCode}
                infoType={
                  errors.cardAcceptorInformation_postalCode ? 'error' : null
                }
              />
              <TextInput
                label="Country Code"
                name="cardAcceptorCountryCode"
                placeholder="NZ"
                maxLength={2}
                type="text"
                value={merchantForm.cardAcceptorInformation.countryCode}
                onChange={e =>
                  handleInputChange(
                    'countryCode',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_countryCode}
                info={errors.cardAcceptorInformation_countryCode}
                infoType={
                  errors.cardAcceptorInformation_countryCode ? 'error' : null
                }
              />
              <TextInput
                label="Card Acceptor Terminal Identifier"
                name="terminalIdentifier"
                minLength={6}
                onChange={e =>
                  handleInputChange(
                    'terminalIdentifier',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                type="text"
                value={merchantForm.cardAcceptorInformation.terminalIdentifier}
                hasError={!!errors.cardAcceptorInformation_terminalIdentifier}
                info={errors.cardAcceptorInformation_terminalIdentifier}
                infoType={
                  errors.cardAcceptorInformation_terminalIdentifier
                    ? 'error'
                    : null
                }
              />
              <TextInput
                label="Card Acceptor Identifier"
                name="cardAcceptorIdentifier"
                minLength={6}
                placeholder="mpp_cybs_test"
                type="text"
                value={merchantForm.cardAcceptorInformation.identifier}
                onChange={e =>
                  handleInputChange(
                    'identifier',
                    e.target.value,
                    'cardAcceptorInformation',
                  )
                }
                hasError={!!errors.cardAcceptorInformation_identifier}
                info={errors.cardAcceptorInformation_identifier}
                infoType={
                  errors.cardAcceptorInformation_identifier ? 'error' : null
                }
              />
            </Block>
          </div>
          <div className={selectedTab === 'pos' ? null : styles.hidden}>
            <Spacer />
            <SectionTitle>Type</SectionTitle>
            <ul>
              <li>
                <input
                  type="radio"
                  id="softPos"
                  name="selector"
                  defaultChecked
                />
                <label htmlFor="softPos">SoftPOS</label>
                <div className={styles.check}></div>
              </li>
              <li>
                <input type="radio" id="eCommerce" name="selector" disabled />
                <label htmlFor="eCommerce">eCommerce</label>
                <div className={styles.check}></div>
              </li>
            </ul>
            <hr />
            <SectionTitle>Refund</SectionTitle>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                <input
                  type="checkbox"
                  id="checkbox"
                  defaultChecked={
                    merchantForm.acquirerInformation.refund?.isEnabled ?? false
                  }
                  onChange={e =>
                    handleInputChange(
                      'isEnabled',
                      e.target.checked,
                      'acquirerInformation.refund',
                    )
                  }
                />
                <label className={styles.switch} htmlFor="checkbox">
                  <span className={styles.slider}></span>
                </label>
              </div>
              <div>Enable refund</div>
            </div>
            <Block>
              <TextInput
                label="Refund daily limit"
                name="dailyLimit"
                type="number"
                onChange={e =>
                  handleInputChange(
                    'dailyLimit',
                    e.target.value,
                    'acquirerInformation.refund',
                  )
                }
                value={merchantForm.acquirerInformation.refund?.dailyLimit}
                hasError={!!errors.acquirerInformation_refund_dailyLimit}
                info={errors.acquirerInformation_refund_dailyLimit}
                infoType={
                  errors.acquirerInformation_refund_dailyLimit ? 'error' : null
                }
              />
            </Block>
            <hr />
            <Block>
              <CustomDropdown
                label="Supported Cvms"
                name="supportedCvms"
                options={[{ value: 'PIN', name: 'PIN' }]}
                useOptionName
              />
            </Block>
          </div>
          <Action>
            <Button onClick={onSubmitMerchant}>
              {id === 'new' ? 'Save and create offer' : 'Save'}
            </Button>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={() => {
                navigate('/organization/')
              }}
            >
              Cancel
            </Button>
          </Action>
        </Wrapper>
      )}
    </>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(MerchantPage)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 28px;
  max-width: 933px;
  justify-content: center;
  align-self: center;
`

const OrgName = styled.div`
  color: var(--content-subtle);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
`

export const Spacer = styled.div`
  margin-top: 16px;
`

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--content-inverse-secondary);
`

const SectionTitle = styled.div`
  color: var(--content-secondary);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
`
