import React from 'react'
import { IconProps } from './types'
import { ReactSVG } from 'react-svg'

const { VITE_ASSET_PATH } = import.meta.env

export function Icon({ name, className = null }: IconProps): React.ReactNode {
  return (
    <ReactSVG
      className={className}
      src={`${window.location.origin + VITE_ASSET_PATH}${name}`}
    />
  )
}
