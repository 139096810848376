import React, { useContext, useRef } from 'react'
import type { ModalProps } from './types'
import styles from './Modal.module.css'
import { createPortal } from 'react-dom'
import { AppRootContext } from '../../../context'
import { useClickOutside } from '../../use-click-outside'

export default function Modal({
  children,
  hasBackdrop = false,
  onClose = null,
  className = null,
}: ModalProps): React.ReactPortal {
  const portalWrapper = useContext(AppRootContext)
  const contentRef = useRef()

  const onOverlayClick = () => {
    if (!onClose) return
    onClose()
  }

  useClickOutside(contentRef, onOverlayClick)

  // @ts-ignore
  return createPortal(
    <div className={`${styles.wrapper} ${className}`}>
      {hasBackdrop && <div className={styles.backdrop}></div>}
      <div ref={contentRef} className={styles.content}>
        {children}
      </div>
    </div>,
    portalWrapper,
  )
}
