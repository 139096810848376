import React, { ReactNode } from 'react'
import { TableProps } from './types'
import styles from './Table.module.css'

export default function Table(props: TableProps): ReactNode {
  return (
    <table className={`${styles.table} ${props.className}`}>
      <thead>
        <tr>
          {props.header.map((data, i) => (
            <th key={i}>{data}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.map((row, i) => (
          <tr key={i}>
            {row.map((data: ReactNode, j) => (
              <td key={j}>{data}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
