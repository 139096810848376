import React, { useState } from 'react'
import { Title } from '../../shared/components/title/Title'
import styled from 'styled-components'
import { TransactionCard } from './TransactionCard'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { TemplatePageProps } from '../../shared/types'
import { RolePermission } from '../../shared/constants'
import { hasPermission } from '../../shared/utils'
import { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import { fetchTransactions } from './api'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { Transaction } from './types'
import { showNotification } from '../../shared/components/Alert'
import { Select } from '../../shared/components/Select'
import style from './Transactions.module.css'

ChartJS.register(ArcElement, Tooltip)

const cardsObject = [
  {
    icon: 'amount.svg',
    title: 'Total Value Processed (TVP)',
    value: '',
  },
  { icon: 'shopping_cart.svg', title: 'Transactions', value: '' },
  { icon: 'receipt_2.svg', title: 'Fees collected', value: '' },
  // { icon: 'increase_detailed.svg', title: 'Value Settled', value: '' },
]

const activitiesObject = [
  { type: 'Total value processed', value: '' },
  { type: 'Transactions', value: '' },
  { type: 'Value purchases', value: '' },
  { type: 'Count purchases', value: '' },
  // { type: 'Value refunds', value: '' },
  // { type: 'Count refunds', value: '' },
]

const backgroundColor = [
  '#0CA663',
  '#196BF0',
  '#D82D2D',
  '#37CEC2',
  '#BE82D2',
  '#F38C47',
  '#E8CE00',
]

const Transactions = ({ user }: TemplatePageProps): React.ReactNode => {
  const canRead = hasPermission(user, 'transactions', RolePermission.READ)
  const [isLoading, setIsLoading] = useState(true)
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const [orgId, setOrgId] = useState('')
  const [cards, setCards] = useState(cardsObject)
  const [payments, setPayments] = useState([])
  const [activities, setActivities] = useState(activitiesObject)

  async function getTransactions() {
    try {
      const response: Transaction = await fetchTransactions(user, orgId)
      cardsObject[0].value = `$ ${(response.aggGeneral.tpv.value / 100).toFixed(2)}`
      cardsObject[1].value = `${response.aggGeneral.countTransactions}`
      cardsObject[2].value = `$ ${(response.aggGeneral.sumFeeCollected.value / 100).toFixed(2)}`
      activitiesObject[0].value = `$ ${(response.aggLastSevenDays.tpv.value / 100).toFixed(2)}`
      activitiesObject[1].value = `${response.aggLastSevenDays.countTransactions}`
      activitiesObject[2].value = `$ ${(response.aggLastSevenDays.sumValuePurchases.value / 100).toFixed(2)}`
      activitiesObject[3].value = `${response.aggLastSevenDays.countPurchases}`
      const paymentsObject = []
      Object.keys(response.aggTopPaymentPerBrand).forEach(key => {
        paymentsObject.push({
          type: key,
          value: (response.aggTopPaymentPerBrand[key] / 100).toFixed(2),
        })
      })
      setCards([...cardsObject])
      setPayments(paymentsObject)
      setActivities([...activitiesObject])
    } catch (e: any) {
      showNotification('danger', 'Failed to fetch transactions.')
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (orgList.length) setOrgId(orgList[0].orgId)
    else setOrgId(user.orgId)
  }, [orgList])

  React.useEffect(() => {
    if (!orgId) return
    setIsLoading(true)
    const intervalRef = setInterval(() => {
      getTransactions()
    }, 10000)
    return () => clearInterval(intervalRef)
  }, [orgId])

  return canRead ? (
    <Wrapper>
      <LoadingOverlay isLoading={isLoading} />
      <Title>Transactions</Title>
      {orgList.length > 1 && orgId && (
        <div className={style.select}>
          <Select
            isWithFader={false}
            visibleCount={10}
            collection={orgList.map(org => ({
              name: org.name,
              value: org.orgId,
            }))}
            value={{
              name: orgList.find(org => org.orgId === orgId)?.name,
              value: orgId,
            }}
            onSelect={org => setOrgId(org)}
          />
        </div>
      )}
      <RowWrapper>
        <LeftWrapper>
          <TodayActivity>Today's Activity</TodayActivity>
          <CardWrapper>
            {cards.map((card, index) => (
              <TransactionCard
                key={index}
                icon={card.icon}
                title={card.title}
                value={card.value}
              />
            ))}
          </CardWrapper>
          <PaymentWidget>
            <WidgetTitle>Top payment types</WidgetTitle>
            <PaymentWrapper>
              <DataTable>
                {payments.map((payment, index) => (
                  <TableRow key={index}>
                    <Index>{index + 1}</Index>
                    <PaymentType>{payment.type}</PaymentType>
                    <Value>{`$ ${payment.value}`}</Value>
                  </TableRow>
                ))}
              </DataTable>
              <Chart>
                <Doughnut
                  options={{ plugins: { legend: { display: false } } }}
                  data={{
                    labels: payments.map(payment => payment.type),
                    datasets: [
                      {
                        backgroundColor,
                        data: payments.map(payment => payment.value),
                      },
                    ],
                  }}
                />
              </Chart>
            </PaymentWrapper>
          </PaymentWidget>
        </LeftWrapper>
        <ActivityWidget>
          <WidgetTitle>Activity from the last 7 days</WidgetTitle>
          <DataTable>
            {activities.map((activity, index) => (
              <ActivityRow key={index}>
                <ActivityType>{activity.type}</ActivityType>
                <Value>{activity.value}</Value>
              </ActivityRow>
            ))}
          </DataTable>
        </ActivityWidget>
      </RowWrapper>
    </Wrapper>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(Transactions)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 28px;
  max-width: 964px;
  justify-content: center;
  align-self: center;
`

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`

const PaymentWidget = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 28px;
  width: auto;
  max-width: 540px;
  border-radius: 8px;
  border: 1px solid var(--content-inverse-secondary);
`

const WidgetTitle = styled.div`
  color: var(--content-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 32px;
`

const TodayActivity = styled(WidgetTitle)`
  margin-bottom: 24px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 32px 0;
`

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ActivityWidget = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 28px;
  max-width: 380px;
  border-radius: 8px;
  border: 1px solid var(--content-inverse-secondary);
`

const DataTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  min-width: 272px;
`

const Index = styled.div`
  width: 19px;
  color: var(--content-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const PaymentType = styled.div`
  flex: 1 0 0;
  color: var(--content-subtle);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Value = styled.div`
  color: var(--content-primary);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const ActivityType = styled.div`
  color: var(--content-subtle);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const ActivityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Chart = styled.div`
  position: relative;
  margin: -48px 48px 0 48px;
  min-width: 33%;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 66%;
  padding: 32px 28px;
  border-radius: 8px;
  border: 1px solid var(--content-inverse-secondary);
`
