import styled from 'styled-components'

export const StyledBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 620px;

  > div {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  > h3 {
    margin-top: 8px;
  }
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 50%;
`

export const FormLogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 8px;
  width: 100%;
  max-height: 104px;

  > a {
    display: flex;
    justify-content: center;
  }
`
