import {
  createAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit'
import { AuthState, MikaUser } from '../../../shared/types'

import { setUserList } from '../../User/ducks/userSlice'
import {
  setOrganizationList,
  setSelectedOrganization,
} from '../../Organization/ducks/organizationSlice'

import {
  signIn,
  fetchUserAttributes,
  fetchAuthSession,
  signOut,
} from 'aws-amplify/auth'

const initialState: AuthState = {
  isLoggedIn: false,
  user: undefined,
  errorMsg: undefined,
}

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<MikaUser>) => {
      state.isLoggedIn = true
      state.user = action.payload
    },
    logoutUser: state => {
      state.user = undefined
      state.isLoggedIn = false
    },
    loginError: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload
    },
  },
})

const groupMap = {
  mikaAdmin: '',
  portfolioAdmin: 'custom:portfolioId',
  servicePortfolioAdmin: 'custom:servicePortfolioId',
  orgAdmin: 'custom:orgId',
  campaignAdmin: 'custom:campaignId',
}

export const loginAction =
  (
    username: string,
    password: string,
    confirmLogin?: boolean,
  ): ThunkAction<
    void,
    AuthState,
    null,
    PayloadAction<MikaUser | string | undefined>
  > =>
  async dispatch => {
    try {
      const signInStatus =
        !confirmLogin && (await signIn({ username, password }))

      if (
        !confirmLogin &&
        signInStatus?.nextStep?.signInStep ===
          'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        const user = {
          email: username,
          email_verified: '',
          first_login: true,
          roles: [],
          accessToken: '',
          userId: '',
          username,
        }

        dispatch(loginSuccess(user))
        return
      }

      if (!signInStatus?.isSignedIn) {
        if (signInStatus?.nextStep?.signInStep === 'RESET_PASSWORD')
          throw new Error('PasswordResetRequiredException')
        if (signInStatus?.nextStep?.signInStep)
          throw new Error(signInStatus?.nextStep.signInStep)
      }

      if (signInStatus?.isSignedIn || confirmLogin) {
        const userAttributes = await fetchUserAttributes()
        const authenticatedSession = await fetchAuthSession()
        const accessJwt = authenticatedSession?.tokens?.accessToken?.toString()
        if (userAttributes) {
          const orgId: string =
            userAttributes['custom:orgId']?.toString() ||
            authenticatedSession?.tokens?.accessToken?.payload[
              'cognito:orgId'
            ]?.toString()

          const portfolioId: string =
            userAttributes['custom:portfolioId']?.toString()
          const servicePortfolioId: string =
            userAttributes['custom:servicePortfolioId']?.toString()

          const scope = userAttributes['custom:scope']

          const roles: string[] = authenticatedSession?.tokens?.accessToken
            ?.payload['cognito:groups'] as string[]

          const isGroupValid = roles?.every(
            role => groupMap[role] !== undefined,
          )

          if (!isGroupValid) throw new Error('UnknownGroupException')

          const isPayloadValid =
            roles.every(role => userAttributes[groupMap[role]]) ||
            roles.includes('mikaAdmin')

          if (!isPayloadValid)
            throw new Error('CustomPropertyNotMatchException')

          const user = {
            orgId: orgId,
            portfolioId: portfolioId,
            servicePortfolioId: servicePortfolioId,
            userId: userAttributes.sub,
            roles: roles,
            accessToken: accessJwt,
            tokenExpiration:
              authenticatedSession?.credentials.expiration.getTime(),
            email: userAttributes.email,
            email_verified: userAttributes.email_verified,
            name: userAttributes.name,
            username: userAttributes.name,
            scope,
          }

          dispatch(loginSuccess(user))
        }
      }
    } catch (error: any) {
      let errorMessage: string
      const treatedException = error.name || error.message

      switch (treatedException) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          errorMessage = 'Login failed, check your e-mail or password.'
          if (error.message?.includes('revoked'))
            errorMessage = 'Session expired, please login again.'
          break
        case 'PasswordResetRequiredException':
          errorMessage = 'Please, reset your password to unlock this user.'
          break
        case 'CustomPropertyNotMatchException':
          errorMessage = 'Custom properties are not matching with the group.'
          break
        case 'UnknownGroupException':
          errorMessage = 'You are not allowed to use the portal.'
          break
        case '':
          errorMessage = ''
          break
        default:
          errorMessage = 'Could not authenticate, validate your informations.'
      }

      dispatch(loginError(errorMessage))
      setTimeout(() => {
        dispatch(loginError(''))
      }, 3000)
    }
  }

export const logout =
  (): ThunkAction<void, AuthState, null, PayloadAction<undefined>> =>
  async dispatch => {
    try {
      await signOut()
      dispatch(logoutUser())
      window.location.href = '/'
      dispatch(setUserList([]))
      dispatch(setOrganizationList([]))
      dispatch(setSelectedOrganization(null))
    } catch (error) {
      console.error('Failed to logout: ', error)
    }
  }

const loginSuccess = createAction<MikaUser>('auth/loginSuccess')
export const logoutUser = createAction('auth/logoutUser')
export const loginError = createAction<string>('auth/loginError')
export default loginSlice.reducer
