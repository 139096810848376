import { RootState } from '../../store/store'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CampaignEntity, TemplatePageProps } from '../../shared/types'
import React, { useState } from 'react'
import Message from '../../shared/components/message/Message'
import { getOrganizationCampaignList } from '../Campaign/api'
import { showNotification } from '../../shared/components/Alert'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import { Title } from '../../shared/components/title/Title'
import styled from 'styled-components'
import style from './Reports.module.css'
import { Select } from '../../shared/components/Select'
import { fetchReports, generateReports, getReportUrl } from './api'
import Button from '../../shared/components/button/Button'
import { ButtonVariant } from '../../shared/components/button/constants'
import Modal from '../../shared/components/modal/Modal'
import { IANAZones } from './constants'
import type { ReportItem } from './types'
import Table from '../../shared/components/table/Table'
import { Icon } from '../../shared/components/icon/Icon'
import { setSelectedOrganization } from '../Organization/ducks/organizationSlice'

const chipMap = {
  AVAILABLE: {
    backgroundColor: '#D9F6E8',
    color: '#20754B',
    icon: 'check.svg',
  },
  PENDING: {
    backgroundColor: '#F3F4F6',
    color: '#606C7F',
    icon: 'hourglass.svg',
  },
  EXPIRED: {
    backgroundColor: '#FFFFD9',
    color: '#74641D',
    icon: 'warning.svg',
  },
  FAILED: {
    backgroundColor: '#FFEAEA',
    color: '#C9312E',
    icon: 'close_circle.svg',
  },
}

const Reports = ({ user }: TemplatePageProps): React.ReactNode => {
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const selectedOrg = useSelector(
    (state: RootState) => state.organization.selectedOrganization,
  )
  const [campaignList, setCampaignList] = React.useState<CampaignEntity[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const canRead = hasPermission(user, 'reports', RolePermission.READ)
  const [selectedCampaign, setSelectedCampaign] = React.useState<string | null>(
    null,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [timeZone, setTimeZone] = useState(null)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [reports, setReports] = useState<ReportItem[]>([])
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!selectedOrg) {
      dispatch(setSelectedOrganization(orgList[0]))
      return
    }
    if (!hasPermission(user, 'reportsCampaign', RolePermission.READ)) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    getOrganizationCampaignList(user, selectedOrg?.orgId || user.orgId)
      .then((e: CampaignEntity[]) => {
        if (e) {
          e.splice(0, 0, {
            campaignId: '*',
            name: 'All Campaigns',
            orgId: selectedOrg?.orgId || user.orgId,
          })
          setCampaignList(e)
          setSelectedCampaign(e[0].campaignId)
        }
      })
      .catch(() => {
        showNotification('danger', 'Failed to load campaigns.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [selectedOrg])

  const fetchSelectedReports = () => {
    fetchReports(user, selectedCampaign, selectedOrg?.orgId || user.orgId).then(
      e => {
        if (e) setReports(e)
      },
    )
  }

  React.useEffect(() => {
    if (
      !selectedCampaign &&
      hasPermission(user, 'reportsCampaign', RolePermission.READ)
    )
      return
    fetchSelectedReports()
    const intervalRef = setInterval(async () => {
      fetchSelectedReports()
    }, 10000)
    return () => clearInterval(intervalRef)
  }, [selectedCampaign, selectedOrg])

  const onGenerateReport = () => {
    generateReports(
      user,
      selectedOrg?.orgId || user.orgId,
      selectedCampaign,
      new Date(new Date(fromDate).setHours(0, 0, 0, 0)).toISOString(),
      new Date(new Date(toDate).setHours(23, 59, 59, 999)).toISOString(),
      timeZone,
    )
    setIsModalOpen(false)
  }

  const onSelectOrganization = (e: string) => {
    const org = orgList.find(value => value.orgId === e)
    dispatch(setSelectedOrganization(org))
  }

  const onDownloadReport = (report: ReportItem) => {
    getReportUrl(user, report)
  }

  return canRead ? (
    <Wrapper>
      <LoadingOverlay isLoading={isLoading} />
      {isModalOpen && (
        <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
          <ModalWrapper>
            <ModalHeader>
              <HeaderText>New report</HeaderText>
            </ModalHeader>
            <ModalBody>
              <BodyText>
                You are generating a new report for the campaign{' '}
                <BodyCampaign>
                  {
                    campaignList.find(
                      campaign => campaign.campaignId === selectedCampaign,
                    )?.name
                  }
                </BodyCampaign>
              </BodyText>
              <DateForm>
                <Frame1>
                  <DatePicker>
                    <label htmlFor="start">From</label>
                    <input
                      onChange={e => setFromDate(e.target.value)}
                      type="date"
                      id="start"
                      name="report-start"
                      value={fromDate}
                    />
                  </DatePicker>
                  <DatePicker>
                    <label htmlFor="end">To</label>
                    <input
                      onChange={e => setToDate(e.target.value)}
                      type="date"
                      id="end"
                      name="report-end"
                      max={new Date().toISOString().split('T')[0]}
                      value={toDate}
                    />
                  </DatePicker>
                </Frame1>
                <div className={style.timezone}>
                  <span className={style.label}>Timezone</span>
                  <Select
                    isWithFader={false}
                    visibleCount={10}
                    collection={IANAZones}
                    value={{
                      name: IANAZones.find(tz => tz.value === timeZone)?.name,
                      value: timeZone,
                    }}
                    onSelect={tz => setTimeZone(tz)}
                  />
                </div>
              </DateForm>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onGenerateReport}>Generate report</Button>
              <Button
                variant={ButtonVariant.GHOST}
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Modal>
      )}
      {isLoading ? null : (
        <>
          <Title>Reports</Title>
          <div className={style.header}>
            <div className={style.select}>
              <div className={style.dropdown}>
                <span className={style.label}>Select organization</span>
                <Select
                  isWithFader={false}
                  visibleCount={10}
                  collection={orgList.map(org => ({
                    name: org.name,
                    value: org.orgId,
                  }))}
                  value={{
                    name: orgList.find(org => org.orgId === selectedOrg?.orgId)
                      ?.name,
                    value: selectedOrg?.orgId,
                  }}
                  onSelect={org => onSelectOrganization(org)}
                  disabled={orgList.length < 2}
                />
              </div>
              {hasPermission(user, 'reportsCampaign', RolePermission.READ) && (
                <div className={style.dropdown}>
                  <span className={style.label}>Select campaign</span>
                  <Select
                    isWithFader={false}
                    visibleCount={10}
                    collection={campaignList.map(campaign => ({
                      name: campaign.name,
                      value: campaign.campaignId,
                    }))}
                    value={{
                      name: campaignList.find(
                        campaign => campaign.campaignId === selectedCampaign,
                      )?.name,
                      value: selectedCampaign,
                    }}
                    onSelect={campaign => setSelectedCampaign(campaign)}
                  />
                </div>
              )}
            </div>
            <div className={style.button}>
              <Button
                variant={ButtonVariant.ACCENT}
                onClick={() => setIsModalOpen(true)}
              >
                New report
              </Button>
            </div>
          </div>
          {reports.length ? (
            <Table
              className={style.table}
              header={['Id', 'Period', 'Created', 'Status', 'Actions']}
              data={reports
                .filter(
                  report =>
                    selectedCampaign === null ||
                    selectedCampaign === '*' ||
                    report.campaignId === selectedCampaign,
                )
                .map((value: ReportItem) => {
                  return [
                    value.reportId,
                    `${new Date(value.periodBegin).toLocaleDateString()} to ${new Date(value.periodEnd).toLocaleDateString()}`,
                    `${value.createdAt}`,
                    <Chip
                      key={value.reportId}
                      style={{
                        backgroundColor: chipMap[value.status].backgroundColor,
                        color: chipMap[value.status].color,
                      }}
                    >
                      <Icon name={chipMap[value.status].icon} />
                      {value.status.toLowerCase()}
                    </Chip>,
                    value.status === 'AVAILABLE' ? (
                      <div key={value.reportId} className={style.actions}>
                        <div onClick={() => onDownloadReport(value)}>
                          <Icon name="download.svg" />
                        </div>
                      </div>
                    ) : null,
                  ]
                })}
            />
          ) : (
            <Message
              title={`No reports for this ${selectedCampaign ? 'campaign' : 'organization'}`}
              primaryButtonText=""
              primaryAction={null}
            />
          )}
        </>
      )}
    </Wrapper>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(Reports)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 28px;
  max-width: 964px;
  min-width: 964px;
  justify-content: center;
  align-self: center;
`

const ModalWrapper = styled.div`
  display: flex;
  width: 632px;
  min-width: 632px;
  max-width: 632px;
  flex-direction: column;
  align-items: flex-start;
`

const ModalHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #d5d9df;
  background: var(--grey-0);
`

const HeaderText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

const ModalBody = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

const BodyText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const BodyCampaign = styled.span`
  font-weight: 700;
`

const ModalFooter = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const DateForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const Frame1 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

const DatePicker = styled.div`
  display: flex;
  width: 209px;
  min-width: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`

const Chip = styled.div`
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`
