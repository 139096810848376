import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    // position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;

    table {
      position: absolute;
      width: 100%;

      thead {
        position: sticky;
        top: 0;
        z-index: 5;
        > tr > th {
          background: #000;
          color: var(--grey-0);
        }
        tr.new-colors  > th {
          background: var(--neutrals-light-50) !important;
          color: #000 !important;
        }
      }
          

      tr {
        :first-child {
          border-bottom-width: 0;
          padding-left: 32px;
        }
      }

      td {
        border-bottom-width: 0;
      }
    }
  }

  > button {
    margin: 16px 32px;
  }
`

export const ActionsTd = styled.td`
  display: flex;
  gap: 8px;
`

export const ActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
