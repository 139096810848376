import styled from 'styled-components'

export const Navbar = styled.div`
  height: 70px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: bold;
  line-height: 36px;
  background-color: var(--brand-primary);
  color: var(--button-label-accent-default);

  a {
    color: var(--button-label-accent-default);
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
`

export const SektorNavbar = styled.div`
  height: 70px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: bold;
  line-height: 36px;
  background-color: transparent;
  color: var(--button-label-accent-default);

  a {
    color: var(--button-label-accent-default);
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--button-label-accent-default);

  z-index: 1;
  position: relative;
  height: 70px;
  width: 100%;
  padding: 16px;
`

export const ColoredFooter = styled(Footer)`
  background-color: var(--brand-primary);
`

export const StyledHr = styled.hr`
  border: 1px solid black;
  width: 90%;
  margin: auto;
`

export const FormPageContainer = styled.div`
  position: relative;
  background-color: var(--grey-0)
  overflow: visible;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;

  h2 {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding: 0 32px;
    margin-top: 40px;
  }

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    min-width: 30vw;
    padding: 16px 32px;

    label {
      color: var(--content-primary);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    > div {
      gap: 14px;

      > div > div {
        gap: 8px;
      }
    }
  }

  button {
    margin-top: 12px;
    height: 40px;

    .spinner-border {
      height: 20px;
      width: 20px;
    }
  }
`

export const FormPageInfoWrapper = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  > h4 {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--font-primary);

    &.group-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--font-primary);

    &.group-title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  > div {
    width: calc(50% - 8px);
  }

  &.active-campaign, &.inactive-campaign {
    &.active-campaign {
      background-color: var(--green-50);
    }
    &.inactive-campaign {
      border: 2px solid var(--border-regular);
      padding: 14px 14px;
    }
    padding: 16px 16px;
    border-radius: 8px;

    > div {
      height: 16px;
      line-height: 16px;
    }
  }

  .row-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;

    &.border-round {
      border-radius: 8px;
      border: 1px solid var(--border-regular);
      padding: 12px 16px 0px 8px;
      width: fit-content;
    }

    > div {
      flex: 1 1;
    }
    
    .checkbox-group {
        display: flex;
        flex: 0 1;
        flex-grow: 0;
        flex-direction: row-reverse;
        height: 20px;

        > div {
          height: 20px;
        }

        :first-child {
          margin-bottom: 0px;
        }

      input[type='checkbox'] {
        height: 16px;
        padding: 2px;
      }

      span {
        text-wrap: nowrap;
      }

      > div {
        border: 0px;
      }
    }
  }

  .half-width {
    width: 50%;
    flex: 1 1;
  }

  .label-left {
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    align-items: baseline;
    
    div {
      width: 50%;
    }    
  }

  .column-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
      flex: 1 1;
    }
  }
`