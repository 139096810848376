import React from 'react'
import { Footer, StyledHr } from '../LayoutTemplate'
import { styled } from 'styled-components'
import { isSektorEnv } from '../../../App'

const currentYear = new Date().getFullYear()

export const CommonFooter = (): React.ReactNode => (
  <StyledFooter>
    <div>
      <StyledHr />
    </div>
    <div>
      <span>© {isSektorEnv ? 'Sektor' : 'Mika'} {`${currentYear}`}</span>
    </div>
  </StyledFooter>
)

const TemplateFooter: React.FC = React.memo(() => {
  return <CommonFooter />
})

TemplateFooter.displayName = 'TemplateFooter'

export default TemplateFooter

const StyledFooter = styled(Footer)`
  :nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight: bold;
  }
`
