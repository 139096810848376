import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import persistReducer from 'redux-persist/es/persistReducer'

import storage from 'redux-persist/lib/storage'

import userSlice from '../pages/User/ducks/userSlice'
import organizationSlice from '../pages/Organization/ducks/organizationSlice'
import loginSlice from '../pages/Login/ducks/loginSlice'
import alertSlice from '../shared/components/alert/ducks/alertSlice'

const rootReducer = combineReducers({
  alert: alertSlice,
  user: userSlice,
  organization: organizationSlice,
  login: loginSlice,
})

const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

export const config = {
  key: 'root',
  storage: storage,
  whitelist: ['login', 'user', 'organization'],
  blacklist: [],
  transforms: [],
}

const persistRootReducer = persistReducer(config, rootReducer)

const store = configureStore({
  reducer: persistRootReducer,
  devTools: { trace: true, traceLimit: 25 },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Pass the serializableCheck object directly
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
