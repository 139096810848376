import React from 'react'
import LoginForm from './components/LoginForm'
import { LoginBackground, LoginBody, SektorBackground } from './Styles'
import { MikaMascot } from '../../shared/components/MikaMascot'
import Footer from '../../shared/components/Template/Footer'
import { LoginHeader } from './LoginHeader'
import { TemplatePageProps } from '../../shared/types'
import { RootState } from '../../store/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isSektorEnv } from '../../App'

export const LoginPage = ({
  isLoggedIn,
  user,
}: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()

  const currentPath = location.pathname

  React.useEffect(() => {
    if (isLoggedIn && user.first_login) navigate('/welcome/')
    if (isLoggedIn && currentPath === '/' && !user.first_login) navigate('/organization')
  }, [isLoggedIn, currentPath, navigate])

  const BgComponent = isSektorEnv ? SektorBackground : LoginBackground

  return isSektorEnv ? (
    <BgComponent>
      <LoginHeader />
      <LoginBody>
        <LoginForm />
      </LoginBody>
    </BgComponent>
  )
  :(
    <BgComponent>
      <LoginHeader />
      <LoginBody>
        <LoginForm />
        <MikaMascot hideOnMobile />
      </LoginBody>
      <Footer />
    </BgComponent>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  isLoggedIn: state.login.isLoggedIn,
})

export default connect(mapStateToProps)(LoginPage)
