import type { MikaUser, ResponseError } from '../../shared/types'
import {
  baseUrl,
  buildRequestOptions,
  getAPIUrl,
  getActiveToken,
  validateActionResponse,
  validateUserToken,
} from '../../shared/utils'
import { FormState } from '../../shared/reducers/formReducer'
import { setUserList } from './ducks/userSlice'
import store from '../../store/store'
import { Portfolio, ServicePortfolio } from './types'

export const fetchUserList = async (mikaUser: MikaUser): Promise<void> => {
  const url = `${getAPIUrl()}/users`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')
  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get user list')

  const userList = await response.json()
  

  store.dispatch(setUserList(userList))
}

export const getPortfolioList = async (
  mikaUser: MikaUser,
): Promise<Portfolio[]> => {
  const url = `${getAPIUrl()}/portfolios`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')
  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get portfolio list')

  return await response.json()
}

export const getServicePortfolioList = async (
  mikaUser: MikaUser,
  portfolioId: string,
): Promise<ServicePortfolio[]> => {
  const currentPortfolioId = portfolioId

  const url = `${getAPIUrl()}/portfolios/${currentPortfolioId}/service-portfolios`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')
  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get ServicePortfolio list')

  return await response.json()
}

export const createUser = async (
  mikaUser: MikaUser,
  user: FormState,
): Promise<ResponseError | void> => {
  if (mikaUser && mikaUser.accessToken) {
    const url = `${getAPIUrl()}/users`

    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    const requestOptions = buildRequestOptions(token, 'POST', user)

    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'register user',
    )

    if (error) return error
  }
}

export const updateUser = async (
  mikaUser: MikaUser,
  user: FormState,
): Promise<ResponseError | void> => {
  if (mikaUser && mikaUser.accessToken && user.userId) {
    const url = `${getAPIUrl()}/users/${user.userId}`

    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    delete user.userId
    
    const requestOptions = buildRequestOptions(token, 'PATCH', user)
    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'update user',
    )

    if (error) return error
  }
}

export const deleteUser = async (
  mikaUser: MikaUser,
  userId: string,
): Promise<ResponseError | void> => {
  const token = getActiveToken(mikaUser)
  if (!token) return null

  const url = `${baseUrl}/users/${userId}/`
  const requestOptions = buildRequestOptions(token, 'DELETE')
  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'Delete User',
  )

  if (error) return error
}
