import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/Login/LoginPage'
import { styled } from 'styled-components'
import OrganizationPage from './pages/Organization/OrganizationPage'
import Campaign from './pages/Campaign/Campaign'
import NotFoundPage from './pages/NotFound/NotFoundPage'
import LogoutPage from './pages/Logout/LogoutPage'
import UserList from './pages/User/UserList'
import OrganizationList from './pages/Organization/OrganizationList'
import PageTemplate from './shared/components/Template/PageTemplate'
import UserPage from './pages/User/UserPage'
import MerchantPage from './pages/Merchant/MerchantPage'
import Transactions from './pages/Transactions/Transactions'
import FirstLoginPage from './pages/Login/FirstLoginPage'
import Installations from './pages/Installations/Installations'
import InstallationsList from './pages/Installations/InstallationsList'
import PasswordReset from './pages/PasswordReset/PasswordReset'
import CampaignPage from './pages/Campaign/CampaignPage'
import Reports from './pages/Reports/Reports'
import Kiosk from './pages/Kiosk/Kiosk'
import KioskList from './pages/Kiosk/KioskList'

const Navigation = (): React.ReactNode => {
  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/welcome/" element={<FirstLoginPage />} />
          <Route path="/password-reset/" element={<PasswordReset />} />
          <Route
            path="/organization/:id?"
            element={
              <PageTemplate>
                <OrganizationPage />
              </PageTemplate>
            }
          />
          <Route
            path="/organization/"
            element={
              <PageTemplate>
                <OrganizationList />
              </PageTemplate>
            }
          />
          <Route
            path="/campaign/:orgId?/:id?"
            element={
              <PageTemplate>
                <CampaignPage />
              </PageTemplate>
            }
          />
          <Route
            path="/campaign/"
            element={
              <PageTemplate>
                <Campaign />
              </PageTemplate>
            }
          />
          <Route
            path="/user/:id?"
            element={
              <PageTemplate>
                <UserPage />
              </PageTemplate>
            }
          />
          <Route
            path="/user/"
            element={
              <PageTemplate>
                <UserList />
              </PageTemplate>
            }
          />
          <Route
            path="/merchant/:id?/:orgId?"
            element={
              <PageTemplate>
                <MerchantPage />
              </PageTemplate>
            }
          />
          <Route
            path="/transactions"
            element={
              <PageTemplate>
                <Transactions />
              </PageTemplate>
            }
          />
          <Route
            path="/installations"
            element={
              <PageTemplate>
                <InstallationsList />
              </PageTemplate>
            }
          />
          <Route
            path="/installations/:id?"
            element={
              <PageTemplate>
                <Installations />
              </PageTemplate>
            }
          />
          <Route
            path="/kiosk"
            element={
              <PageTemplate>
                <KioskList />
              </PageTemplate>
            }
          />
          <Route
            path="/kiosk/:id?"
            element={
              <PageTemplate>
                <Kiosk />
              </PageTemplate>
            }
          />
          <Route
            path="/reports"
            element={
              <PageTemplate>
                <Reports />
              </PageTemplate>
            }
          />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AppContainer>
  )
}

const AppContainer = styled.div`
  height: 100vh;
  display: block;
`

export default Navigation
