import React, { ReactNode } from 'react'
import Button from '../button/Button'
import { ConfirmationProps } from './types'
import styles from './Confirmation.module.css'
import { ButtonVariant } from '../button/constants'

export function Confirmation({
  title,
  message,
  onConfirm,
  onCancel,
}: ConfirmationProps): ReactNode {
  return (
    <div className={styles.confirmation}>
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button variant={ButtonVariant.ACCENT} onClick={onConfirm}>
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  )
}
