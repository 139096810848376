import {
  baseUrl,
  buildRequestOptions,
  getActiveToken,
  getAPIUrl,
  validateActionResponse,
  validateUserToken,
} from '../../shared/utils'
import { MikaUser, ResponseError } from '../../shared/types'
import { FormState } from '../../shared/reducers/formReducer'
import _ from 'lodash'
import { Acquirer, Merchant } from './types'

export const getMerchantListByOrgId = async (
  mikaUser: MikaUser,
  orgId: string,
): Promise<Merchant[]> | null => {
  const url = `${getAPIUrl()}/orgs/${orgId ?? mikaUser.orgId}/merchants`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')

  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get merchant list')

  return await response.json()
}

export const getMerchantById = async (
  mikaUser: MikaUser,
  orgId: string,
  merchantId: string,
): Promise<Merchant> | null => {
  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)
    validateUserToken(token)

    const url = `${getAPIUrl()}/orgs/${orgId}/merchants/${merchantId}`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)
    validateActionResponse(response, requestOptions, 'get merchant')

    return await response.json()
  }

  return null
}

export const createMerchant = async (
  mikaUser: MikaUser,
  merchant: FormState,
): Promise<ResponseError | void> => {
  const url = `${baseUrl}/orgs/${merchant.orgId}/merchants/`

  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    if (merchant.acquirerInformation.annualCCV) {
      merchant.acquirerInformation.annualCCV = Number(
        merchant.acquirerInformation.annualCCV,
      )
    }
    if (merchant.acquirerInformation.avgTicketSize) {
      merchant.acquirerInformation.avgTicketSize = Number(
        merchant.acquirerInformation.avgTicketSize,
      )
    }
    merchant.acquirerInformation.refund.dailyLimit = Number(
      merchant.acquirerInformation.refund?.dailyLimit ?? 0,
    )
    merchant.name = merchant.cardAcceptorInformation.name

    const cleanFormRequest = _.omitBy(merchant, _.isEmpty)
    const requestOptions = buildRequestOptions(token, 'POST', cleanFormRequest)

    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'create merchant',
    )

    if (error) return error
  }
}

export const updateMerchant = async (
  mikaUser: MikaUser,
  merchant: FormState,
): Promise<ResponseError | void> => {
  const url = `${baseUrl}/orgs/${merchant.orgId}/merchants/${merchant.merchantId}`

  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    if (merchant.acquirerInformation.annualCCV) {
      merchant.acquirerInformation.annualCCV = Number(
        merchant.acquirerInformation.annualCCV,
      )
    }
    if (merchant.acquirerInformation.avgTicketSize) {
      merchant.acquirerInformation.avgTicketSize = Number(
        merchant.acquirerInformation.avgTicketSize,
      )
    }
    if (merchant.acquirerInformation.refund?.dailyLimit) {
      merchant.acquirerInformation.refund.dailyLimit = Number(
        merchant.acquirerInformation.refund.dailyLimit,
      )
    }
    merchant.name = merchant.cardAcceptorInformation.name

    const cleanFormRequest = _.omitBy(merchant, _.isEmpty)
    const requestOptions = buildRequestOptions(token, 'PATCH', cleanFormRequest)

    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'edit merchant',
    )

    if (error) return error
  }
}

export const deleteMerchant = async (
  mikaUser: MikaUser,
  orgId: string,
  merchantId: string,
): Promise<ResponseError | void> => {
  const url = `${baseUrl}/orgs/${orgId}/merchants/${merchantId}`

  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)

    validateUserToken(token)

    const requestOptions = buildRequestOptions(token, 'DELETE')

    const response = await fetch(url, requestOptions)
    const error = await validateActionResponse(
      response,
      requestOptions,
      'delete merchant',
    )

    if (error) return error
  }
}

export const getAcquirerList = async (
  mikaUser: MikaUser,
): Promise<Acquirer[]> => {
  const url = `${getAPIUrl()}/acquirers`
  const token = getActiveToken(mikaUser)

  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'GET')
  const response = await fetch(url, requestOptions)

  validateActionResponse(response, requestOptions, 'get acquirer list')

  return await response.json()
}
