import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  alertList: [],
}

const alertSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      action.payload.id ??= (Math.random() + 1).toString(36).substring(2)
      state.alertList = [...state.alertList, action.payload]
    },
    deleteAlert: (state, action) => {
      state.alertList = state.alertList.filter(
        alert => alert.id !== action.payload,
      )
    },
  },
})

export const { createAlert, deleteAlert } = alertSlice.actions
export default alertSlice.reducer
