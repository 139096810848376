import React from 'react'
import styled from 'styled-components'

export const Title = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode => {
  return <TitleText>{children}</TitleText>
}

const TitleText = styled.div`
  color: var(--content-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`
