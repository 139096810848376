import { createSlice } from '@reduxjs/toolkit'
import type { OrganizationState } from '../types'

const initialState: OrganizationState = {
  organizationList: [],
  selectedOrganization: null,
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizationList = action.payload
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload
    },
  },
})

export const { setOrganizationList, setSelectedOrganization } =
  organizationSlice.actions
export default organizationSlice.reducer
