import { Amplify } from 'aws-amplify'

const {
  VITE_AMAZON_DOMAIN,
  VITE_AMAZON_IDENTITY_POOL_ID,
  // VITE_AMAZON_REGION,
  VITE_AMAZON_USERPOOL_ID,
  VITE_AMAZON_USERPOOL_WEB_CLIENT,
} = import.meta.env

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: VITE_AMAZON_USERPOOL_ID,
      userPoolClientId: VITE_AMAZON_USERPOOL_WEB_CLIENT,
      identityPoolId: VITE_AMAZON_IDENTITY_POOL_ID,
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        oauth: {
          domain: VITE_AMAZON_DOMAIN,
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
})

// You can get the current config object
export const currentConfig = Amplify.getConfig()

// export const AwsConfigAuth = {
//     region: VITE_AMAZON_REGION,
//     userPoolId: VITE_AMAZON_USERPOOL_ID,
//     userPoolWebClientId: VITE_AMAZON_USERPOOL_WEB_CLIENT,
//     cookieStorage: {
//         domain: VITE_AMAZON_DOMAIN,
//         path: '/',
//         expires: 365,
//         sameSite: 'strict',
//         secure: true
//     },
//     authenticationFlowType: 'USER_SRP_AUTH'
// }
