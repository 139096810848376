import { Icon } from '../icon/Icon'
import React from 'react'
import { styled } from 'styled-components'
import Button from '../button/Button'
import { ButtonVariant } from '../button/constants'

export default function ChangeImageButton({
  buttonText,
  dropCallback,
  errorText,
  sizeLimit = 2 * 1024 * 1024,
  type = null,
}: {
  buttonText: string
  errorText: string
  dropCallback: (content: string | ArrayBuffer) => void
  sizeLimit?: number
  type?: RegExp
}): React.ReactElement {
  const [error, setError] = React.useState('')
  const fileUploadRef = React.useRef<HTMLInputElement>(null)

  const handleFileSelect = () => {
    if (fileUploadRef.current) fileUploadRef.current.click()
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) readFile(files[0])
  }

  const readFile = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const content = reader.result as string
      dropCallback(content)
      setError('')
    }
    reader.onerror = () => {
      setError('Failed to read file')
    }
    if (type && !type.test(file.type)) {
      setError(`The type ${file.type} is not allowed`)

      return
    }
    if (file.size > sizeLimit) {
      setError(`File size should not exceed ${sizeLimit / 1024 / 1024} MB`)

      return
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      <div>
        <Button
        variant={ButtonVariant.TONAL}
        onClick={
          handleFileSelect
        }
        >
          <Icon name="picture-upload.svg" />
          {buttonText}
        </Button>
        <input
        ref={fileUploadRef}
        onChange={handleFileUpload}
        type="file"
        style={{ display: 'none' }}
        />
        {!!errorText || (!!error && <ErrorText>{errorText || error}</ErrorText>)}
      </div>
    </>
  )
}

const ErrorText = styled.div`
  color: var(--red-500);
  margin-top: 4px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`
