import { styled } from 'styled-components'

export const StyledButton = styled.button`
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 600;
  min-height: 36px;
`
