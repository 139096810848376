import styled from 'styled-components'

export const ListWrapper = styled.div`
  background-color: var(--grey-0);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  max-width: 1280px;
  min-width: 30vw;
  align-self: center;
  
  h2 {
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  } 

  h3 {
    color: var(--content-primary);
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
  }
  
  h4 {
    color: var(--content-secondary);
    font-family: var(--font-primary);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 4px;
  }
`

export const Action = styled.div`
  display: flex;
  gap: 10px;
  padding: 16px 0px;

  .secondary-action {
    background-color: var(--grey-0);
    color: var(--content-primary);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 600;
  }
  .secondary-action:hover {
    cursor: pointer;
    background-color: #EEE;
  }
`

export const FormWrapper = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 16px;

  > div {
    width: calc(50% - 8px);
  }

  .row-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;

    > div {
      flex: 1 1;
    }
  }

  .column-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
      flex: 1 1;
    }
  }

  select {  
    width: calc(100% - 6px) !important;
    height: 48px !important;
  }

  .reset-password {
    background: var(--neutrals-light-50) !important;
    color: #000 !important;
    font-size: 14px;
    font-weight: 600;
  }
`
