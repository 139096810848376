import React from 'react'

import {
  AmountConfig,
  CampaignAmount,
  CampaignEntity,
  EmvConfigEntity,
  FormError,
  MikaUser,
  ReceiptConfig,
  ReceiptOptions,
} from '../../shared/types'
import formReducer, { FormState } from '../../shared/reducers/formReducer'
import {
  CurrencyDropdown,
  CustomDropdown,
  DropdownOption,
  ReceiptTemplateDropDown,
} from '../../shared/components/Dropdown'
import { LogoComponent } from '../../shared/components/Logos'
import { getCampaignById, getEmvConfigList, registerCampaign, updateCampaign } from './api'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { showNotification } from '../../shared/components/Alert'
import _ from 'lodash'

import { FormPageContainer, FormPageInfoWrapper } from '../../shared/components/LayoutTemplate'

import Button from '../../shared/components/button/Button'
import { basicCustomAmount, initialCampaignState } from './constants'
import {
  formatResponseError,
  getBooleanFromString,
  getUTCDate,
  redirectToPage,
} from '../../shared/utils'
import { RootState } from '../../store/store'
import styles from './Campaign.module.css'
import { connect, useSelector } from 'react-redux'
import { TextArea, TextInput } from '../../shared/components/input/TextInput'
import Upload from '../../shared/components/upload/Upload'
import { Merchant } from '../Merchant/types'
import { getMerchantListByOrgId } from '../Merchant/api'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionsDiv } from '../../shared/components/Template/Table'
import ActionToggle from '../../shared/components/input/ActionToggle'
import { ColorInput } from '../../shared/components/input/ColorInput'
import { InputBoolean } from '../../shared/components/InputForm'
import { CustomAmountForm } from './CustomAmountForm'
import { DateInput } from '../../shared/components/input/DateInput'
import ChangeImageButton from '../../shared/components/upload/ChangeImage'

const CampaignPage = ({user}: {
  user: MikaUser
}): React.ReactNode => {
  const navigate = useNavigate()
  const params = useParams()

  const { orgId, id } = params

  const newCampaign = id === 'new'
  
  const [selectedCampaign, setSelectedCampaign] =
    React.useState<CampaignEntity | null>(null)

  const [campaignForm, dispatch] = React.useReducer(formReducer, initialCampaignState)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<FormError>({})

  const [customAmounts, setCustomAmounts] = React.useState<CampaignAmount[]>([basicCustomAmount])

  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const [emvConfigList, setEmvConfigList] = React.useState<DropdownOption[]>([])
  const [merchantList, setMerchantList] = React.useState<DropdownOption[]>([])
  const selectedOrg = useSelector(
    (state: RootState) => state.organization.selectedOrganization,
  )
  const [logoFileContent, setLogoFileContent] = React.useState<
    string | ArrayBuffer
  >(null)

  const dropHandler = (content: string | ArrayBuffer) => {
    setErrors({ ...errors, 'logo': null})
    setLogoFileContent(content)
    setHasChanges(true)
  }

  // Preference to the value set in dropdown
  const organizationId = selectedOrg && selectedOrg.orgId || orgId

  React.useEffect(() => {

    if(id && !orgId)
      navigate(`/campaign/${organizationId}/${id}`)
    

    if(id !== 'new' && (!orgId && !organizationId))
      navigate('/campaign')
    
  }, [id, orgId])

  const handleInputChange = (
    field: string,
    value: string,
    objectMap?: string,
    wasUserChanges = true,
  ) => {
    if (wasUserChanges) setHasChanges(true)

    if (objectMap) {
      dispatch({
        type: 'HANDLE_FIELD_UPDATE',
        field,
        payload: value,
        objectMap,
      })

      return
    }

    dispatch({ type: 'HANDLE_FIELD_UPDATE', field, payload: value })
  }

  const handleReceiptOptionsChange = (option: ReceiptOptions, receiptConfig: ReceiptConfig[]) => {
    setHasChanges(true)

    const optionIndex = receiptConfig['options'].indexOf(option)
    const newReceiptConfig = _.cloneDeep(receiptConfig)

    if(optionIndex >= 0)
      newReceiptConfig['options'].splice(optionIndex, 1)
    else
      newReceiptConfig['options'].push(option)
      dispatch({
        type: 'HANDLE_FIELD_UPDATE',
        field: 'receiptConfig',
        payload: newReceiptConfig,
      })
  }

  const createUpdateCampaign = (
    campaignForm: FormState,
    newCampaign: boolean,
  ) => {
    setIsSaving(true)
    setErrors({})

    const customAmountsWithNumber = customAmounts.map(customConfig => {
      return {
        ...customConfig,
        amount: customConfig.amount ? Number(customConfig.amount) : '',
      }
    })

    const defaultAmountConfigs: AmountConfig[] = [
      {
        region: 'default',
        amounts: customAmountsWithNumber,
      },
    ]

    const minimumAmount = Number.isNaN(campaignForm.minimumAmount)
      ? 0
      : Number(campaignForm.minimumAmount)
    const maximumAmount = Number.isNaN(campaignForm.maximumAmount)
      ? 0
      : Number(campaignForm.maximumAmount)

    const isActive =
      campaignForm.isActive === 'true' ||
      (campaignForm.isActive && campaignForm.isActive !== 'false')

    const percentage =
      (campaignForm.feeSettings.percentage &&
        Number(campaignForm.feeSettings.percentage)) ||
      0

    const isMandatory =
      campaignForm.feeSettings.isMandatory === 'true' ||
      (campaignForm.feeSettings.isMandatory &&
        campaignForm.feeSettings.isMandatory !== 'false')

    const fixedAmount =
      campaignForm.feeSettings.fixedAmount &&
      !Number.isNaN(campaignForm.feeSettings.fixedAmount)
        ? Number(campaignForm.feeSettings.fixedAmount)
        : 0

    const isAmountEntryEnabled = customAmounts.length > 0

    if (newCampaign) {
      delete campaignForm.terminalId
      delete campaignForm.configs
      delete campaignForm.campaignId

      const campaignBody: CampaignEntity = {
        ...campaignForm,
        feeSettings: {
          percentage: percentage,
          fixedAmount: fixedAmount,
          isMandatory: isMandatory,
        },
        isActive,
        logo: campaignForm.logo,
        defaultAmountConfigs,
        minimumAmount,
        maximumAmount,
        isAmountEntryEnabled: isAmountEntryEnabled,
        expiryDate: campaignForm.expiryDate,
      }

      if(campaignBody.defaultAmountConfigs[0].amounts.length === 1 && campaignBody.defaultAmountConfigs[0].amounts[0].amount === '')
         campaignBody.defaultAmountConfigs[0].amounts = []
      

      registerCampaign(
        user,
        campaignBody,
        selectedOrg?.orgId,
        logoFileContent as string,
      )
        .then(response => {
          if (response && response.error) {

            if (response.statusCode === 400) {
              if(response.message && Array.isArray(response.message)) {
                const formattedError: FormError = formatResponseError(response)
                setErrors(formattedError)
  
                showNotification(
                  'danger',
                  'Failed to register campaign, check the field errors.',
                )
              }

              if(response.message && typeof response.message === 'string') {
                showNotification(
                  'danger',
                  `Failed to register campaign, ${response.message}`,
                )

                if(response.message === 'Campaign already exists with this code') {
                  const MessageError = {}
                  
                  MessageError['code'] = 'Campaign already exists with this code'

                  setErrors(MessageError)
                }
              }

              return
            }

            showNotification(
              'danger',
              `Failed to register campaign, due to a ${response.error}.`,
            )

            return
          }

          showNotification('success', 'Campaign has been registered.')
          redirectToPage('/campaign/')
        })
        .catch(e => {
          console.error(' Caught error: ', e)
          showNotification(
            'danger',
            ('Failed to register campaign, ' + e && e.message) || e.code,
          )
        })
        .finally(() => {
          setIsSaving(false)
        })
    } else {
      delete campaignForm.terminalId

      const campaignBody: CampaignEntity = {
        ...campaignForm,
        feeSettings: {
          percentage: percentage,
          fixedAmount: fixedAmount,
          isMandatory: isMandatory,
        },
        logo: campaignForm.logo,
        isActive,
        isAmountEntryEnabled: isAmountEntryEnabled,
        minimumAmount,
        maximumAmount,
        defaultAmountConfigs,
      }

      if(campaignBody.defaultAmountConfigs[0].amounts.length === 1 && campaignBody.defaultAmountConfigs[0].amounts[0].amount === '')
        campaignBody.defaultAmountConfigs[0].amounts = []

      updateCampaign(
        user,
        campaignBody,
        selectedOrg?.orgId,
        logoFileContent as string,
      )
        .then(response => {
          if (response && response.error) {
            if (response.message?.length && response.statusCode === 400) {
              const formattedError: FormError = formatResponseError(response)
              setErrors(formattedError)
              showNotification(
                'danger',
                'Failed to update campaign, check the field errors.',
              )

              return
            }

            showNotification(
              'danger',
              `Failed to update campaign, due to the ${response.error}.`,
            )

            return
          }
          showNotification('success', `Campaign ${campaignBody.name} updated `)
          redirectToPage('/campaign/')
        })
        .catch(e => {
          console.warn('Source: ' + e.code + ' Error: ' + e.message)
          showNotification(
            'danger',
            ('Failed to register campaign, ' + e && e.message) || e.code,
          )
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  const fetchCampaignById =
    ({user, orgId, id}) => {
        getCampaignById(user, orgId, id)
          .then((responseCampaign: CampaignEntity) => {
            if (responseCampaign) {
              const payload: FormState = { ...campaignForm, ...responseCampaign }
              const keys = Object.keys(responseCampaign)

              _.forEach(keys, key => {
                if (payload[key] == null) payload[key] = ''

                if (
                  key === 'expiryDate' ||
                  key === 'createdAt' ||
                  key === 'updatedAt'
                ) {
                  const receivedDate = getUTCDate(payload[key])

                  const year = receivedDate.getFullYear()
                  const month = (receivedDate.getMonth() + 1)
                    .toString()
                    .padStart(2, '0') // Months are zero-based, so add 1
                  const day = receivedDate.getDate().toString().padStart(2, '0')

                  payload[key] = `${year}-${month}-${day}`
                }
              })

              if(payload.defaultAmountConfigs.length > 0)
                setCustomAmounts(payload.defaultAmountConfigs[0].amounts)
              
              initCampaignState(false, payload)


            } else {
              showNotification('warning', 'Failed to load campaign')
            }
          })
          .catch(e => {
            showNotification(
              'danger',
              'Failed to load campaign, try again later.',
            )
          })
          .finally(() => {
            setIsLoading(false)
          })
    }

  const initCampaignState = (newCampaign: boolean, campaign?: CampaignEntity) => {
      if (newCampaign)
        dispatch({ type: 'LOAD_FORM_VALUES', payload: initialCampaignState })
      
      if (campaign && id && campaign.campaignId === id) {

        if (campaign.defaultAmountConfigs && campaign.defaultAmountConfigs[0].amounts) setCustomAmounts(campaign.defaultAmountConfigs[0].amounts)
        setSelectedCampaign(campaign)
        dispatch({ type: 'LOAD_FORM_VALUES', payload: campaign })
      }
        setHasChanges(false)

        return
      }
    

  const loadMerchantList = (
    setMerchantList: (dropdownOption: any) => void,
    user: MikaUser,
    orgId?: string,
  ) => {
    getMerchantListByOrgId(user, orgId)
      .then((options: Merchant[]) => {
        const merchantOptions = []

        if (options && options.length) {
          options.map(option => {
            const merchantOption = {
              name: option.name || option.cardAcceptorInformation.name,
              value: option.merchantId,
            }

            merchantOptions.push(merchantOption)
          })
        }

        setMerchantList(merchantOptions)
      })
      .catch(e => {
        showNotification('danger', 'Failed to load merchants list.')
        console.warn('Error -> ', e)
      })
  }

  const loadEmvConfigList = (
    setEmvConfigList: (dropdownOption) => void,
    user,
  ) => {
    getEmvConfigList(user)
      .then((options: EmvConfigEntity[]) => {
        const emvConfigOptions = []

        if (options && options.length) {
          options.map(option => {
            const emvConfigOption = {
              name: option.name,
              value: option.emvConfigId,
            }
            emvConfigOptions.push(emvConfigOption)
          })
        }

        setEmvConfigList(emvConfigOptions)
      })
      .catch(e => {
        showNotification('danger', 'Failed to load emvConfigs list.')

        console.warn('Error -> ', e)
      })
  }

  React.useEffect(() => {
    if (user)
      loadEmvConfigList(setEmvConfigList, user)
  }, [user])

  React.useEffect(() => {
    if (user && organizationId) {
      loadMerchantList(setMerchantList, user, organizationId)
      if (id && id.toLocaleLowerCase() === 'new') {
        setIsLoading(false)

        return
      }

      fetchCampaignById({user, orgId: organizationId, id})
    }
  }, [user, organizationId, id])

  React.useEffect(() => {
    if (merchantList && merchantList.length > 0) {
      if (
        user &&
        (newCampaign || (selectedCampaign && !selectedCampaign.merchantId))
      )
        handleInputChange('merchantId', merchantList[0].value, '', false)

      if (user && selectedCampaign && selectedCampaign.merchantId) {
        const merchant = merchantList.find(
          e => e.value === selectedCampaign.merchantId,
        )
        if (merchant) handleInputChange('merchantId', merchant.value, '', false)
      }
    }
  }, [user, merchantList, newCampaign, selectedCampaign])

  React.useEffect(() => {
    if (emvConfigList && emvConfigList.length > 0) {
      if (
        user &&
        (newCampaign || (selectedCampaign && !selectedCampaign.emvConfigId))
      )
        handleInputChange('emvConfigId', emvConfigList[0].value, '', false)

      if (user && selectedCampaign && selectedCampaign.emvConfigId) {
        const emvConfig = emvConfigList.find(
          e => e.value === selectedCampaign.emvConfigId,
        )
        if (emvConfig)
          handleInputChange('emvConfigId', emvConfig.value, '', false)
      }
    }
  }, [user, emvConfigList, newCampaign, selectedCampaign])

  React.useEffect(() => {
    handleInputChange('orgId', selectedOrg?.orgId)
  }, [selectedOrg])

  const onSelectOrganization = (e: string) => {
    const org = orgList.find(org => org.orgId === e)
    handleInputChange('orgId', org.orgId)
  }

  const formats = {
    email: campaignForm['receiptConfig']?.options?.includes('EMAIL'),
    mail: campaignForm['receiptConfig']?.options?.includes('MAIL'),
    qrcode: campaignForm['receiptConfig']?.options?.includes('QR_CODE'),
    noreceipt: campaignForm['receiptConfig']?.options?.includes('NO_RECEIPT'),
  }

  return (
    <FormPageContainer>
      <div>
        <LoadingOverlay isLoading={isLoading} />

        {!isLoading && (
          <><h2>{id === 'new' ? 'New campaign' : 'Edit campaign'}</h2>
            <form>
              <FormPageInfoWrapper className={getBooleanFromString(campaignForm.isActive) ? 'active-campaign' : 'inactive-campaign'}>
                <div className='row-div'>
                  <ActionToggle value={campaignForm.isActive} setValue={(newValue: boolean) => handleInputChange('isActive', newValue.toString())} label={'Campaign active status:'} name='campaignStatus' />
                </div>
              </FormPageInfoWrapper>
              <FormPageInfoWrapper>
                <h4 className='group-title'>About</h4>
                <TextInput
                    label="Campaign ID:"
                    name="campaignId"
                    type="text"
                    onChange={e => handleInputChange('campaignId', e.target.value)}
                    value={campaignForm.campaignId}
                    hasError={!!errors.campaignId}
                    info={errors.campaignId}
                    infoType={errors.campaignId ? 'error' : null}
                    tabIndex={0}
                    disabled
                />

                <div className="row-div">
                  <TextInput
                    label="Campaign name:"
                    name="name"
                    type="text"
                    onChange={e => handleInputChange('name', e.target.value)}
                    value={campaignForm.name}
                    hasError={!!errors.name}
                    info={errors.name}
                    infoType={errors.name ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={1}
                  />

                  <TextInput
                      label="Campaign title:"
                      name="title"
                      type="text"
                      onChange={e => handleInputChange('title', e.target.value)}
                      value={campaignForm.title}
                      hasError={!!errors.title}
                      info={errors.title}
                      infoType={errors.title ? 'error' : null}
                      disabled={isSaving}
                      tabIndex={2}
                  />
                </div>
                
                <div className={styles.dropdown}>
                  <CustomDropdown
                        label="Organization"
                        options={orgList.map(org => ({
                          name: org.name,
                          value: org.orgId,
                        }))}
                        useOptionName
                        value={selectedOrg?.orgId}
                        disabled
                        onChange={e => onSelectOrganization(e.target.value)}
                        tabIndex={3}
                  />
                </div>

                <TextInput
                    label="Campaign access key:"
                    name="campaignCode"
                    type="text"
                    onChange={e => {
                      const inputValue = e.target.value
                      const regexPattern = /^[a-zA-Z\d\- ]*$/

                      if (regexPattern.test(inputValue)) {
                        // Input value matches the regex pattern, so it's valid
                        handleInputChange('code', inputValue)
                      }
                    }}
                    value={campaignForm.code}
                    hasError={!!errors.code}
                    info={errors.code}
                    infoType={errors.code ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={4}
                />

                <div className="row-div">
                  <CustomDropdown
                    label="EMV config:"
                    name="emvConfigId"
                    options={emvConfigList}
                    useOptionName
                    onChange={e => handleInputChange('emvConfigId', e.target.value)}
                    value={campaignForm.emvConfigId}
                    disabled={isSaving || !!selectedCampaign}
                    tabIndex={5}
                  />
                  <CustomDropdown
                    label="Merchant:"
                    name="merchantId"
                    options={merchantList}
                    useOptionName
                    onChange={e => handleInputChange('merchantId', e.target.value)}
                    value={campaignForm.merchantId}
                    disabled={isSaving || !!selectedCampaign}
                    tabIndex={6}
                  />
                </div>

                <DateInput
                    label="Expiry date:"
                    name="campaignExpiryDate"
                    placeholder="dd-mm-yyyy"
                    type="date"
                    onChange={e => handleInputChange('expiryDate', e.target.value)}
                    hasError={!!errors.expiryDate}
                    info={errors.expiryDate}
                    infoType={errors.expiryDate ? 'error' : null}
                    value={campaignForm.expiryDate}
                    min={new Date().toISOString().split('T')[0]}
                    disabled={isSaving}
                    tabIndex={7}
                />
              </FormPageInfoWrapper>


              <FormPageInfoWrapper>
                <h4>Campaign Branding</h4>
                <div className="row-div">
                  <ColorInput
                    label="Primary Color:"
                    name="primaryColor"
                    onChange={e => handleInputChange('primaryColor', e.target.value)}
                    hasError={!!errors.primaryColor}
                    info={errors.primaryColor}
                    value={campaignForm.primaryColor}
                    infoType={errors.primaryColor ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={8}
                  />

                  <ColorInput
                    label="Secondary Color:"
                    name="secondaryColor"
                    onChange={e => handleInputChange('secondaryColor', e.target.value)}
                    hasError={!!errors.secondaryColor}
                    info={errors.secondaryColor}
                    value={campaignForm.secondaryColor}
                    infoType={errors.secondaryColor ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={9}
                  />
                </div>
                    
                <>
                  <label htmlFor="logoAnchor">
                    Campaign logo URL:
                  </label>
                </>
                  
                <div className='row-div'>
                  {(!!logoFileContent || !!campaignForm.logo) ? (
                    <>
                      <LogoComponent
                      src={logoFileContent || campaignForm.logo}
                      alt={'Campaign Logo'}
                      size={'big'}
                      />
                      <ChangeImageButton
                        errorText={errors.logo as unknown as string}
                        type={/[/.](jpg|jpeg)$/i}
                        buttonText="Change image"
                        dropCallback={dropHandler}
                      />
                    </>
                  ) : (
                    <Upload
                      errorText={errors.logo as unknown as string}
                      type={/[/.](jpg|jpeg)$/i}
                      descriptionText="JPG format • 2 MB Max"
                      uploadText="Drop image here"
                      dropCallback={dropHandler}
                    />
                  )}
                </div>

              </FormPageInfoWrapper>

              <FormPageInfoWrapper>
                <h4 className='group-title'>Donation settings</h4>
                <CurrencyDropdown
                    label="Currency:"
                    name="campaignCurrency"
                    onChange={e => handleInputChange('currency', e.target.value)}
                    value={campaignForm.currency}
                    hasError={!!errors.currency}
                    info={errors.currency}
                    disabled={isSaving}
                    tabIndex={11}
                />
              </FormPageInfoWrapper>
              <FormPageInfoWrapper>
                <h4>Fees & amounts</h4>

                <InputBoolean
                  label="Is Fee Mandatory:"
                  name="campaignFeeMandatory"
                  onChange={e => handleInputChange(
                    'isMandatory',
                    e.target.checked.toString(),
                    'feeSettings',
                  )}
                  value={campaignForm.feeSettings?.isMandatory}
                  disabled={isSaving}
                  tabIndex={12}
                />
                <div className='row-div half-width'>
                  <TextInput
                    label="Fee fixed amount:"
                    name="campaignFixedAmount"
                    type="number"
                    onChange={e => handleInputChange(
                      'fixedAmount',
                      e.target.value,
                      'feeSettings',
                    )}
                    className='label-left'
                    value={campaignForm.feeSettings.fixedAmount}
                    hasError={!!errors?.feeSettings?.fixedAmount}
                    infoType={errors.feeSettings?.fixedAmount ? 'error' : null}
                    info={errors?.feeSettings?.fixedAmount}
                    disabled={isSaving || campaignForm.feeType === 'percentage'}
                    tabIndex={13}
                  />
                </div>
                <div className='row-div half-width'>
                  <TextInput
                    label="Fee percentage:"
                    name="campaignFeePercentage"
                    placeholder="0"
                    type="number"
                    min="0"
                    max="100"
                    onChange={e => handleInputChange(
                      'percentage',
                      e.target.value,
                      'feeSettings',
                    )}
                    suffix={'%'}
                    className='label-left'
                    value={campaignForm.feeSettings.percentage}
                    hasError={!!errors?.feeSettings?.feePercentage}
                    info={errors?.feeSettings?.feePercentage}
                    infoType={errors.feeSettings?.feePercentage ? 'error' : null}
                    disabled={isSaving || campaignForm.feeType === 'fixed'}
                    tabIndex={14}
                  />
                </div>
              </FormPageInfoWrapper>
              <FormPageInfoWrapper>
                <h4>Custom amounts</h4>

                <CustomAmountForm
                    customAmounts={customAmounts}
                    setCustomAmounts={setCustomAmounts}
                    errors={errors}
                />
              </FormPageInfoWrapper>
              <FormPageInfoWrapper>
                <h4>Any amount range</h4>

                <TextInput
                    label="Min. amount"
                    name="minimumAmount"
                    placeholder="0"
                    type="number"
                    suffix={campaignForm?.currency || initialCampaignState.currency}
                    onChange={e => handleInputChange('minimumAmount', e.target.value)}
                    value={campaignForm.minimumAmount}
                    info={errors.minimumAmount}
                    hasError={!!errors.minimumAmount}
                    infoType={errors.minimumAmount ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={20}
                />

                <TextInput
                    label="Max. amount"
                    name="maximumAmount"
                    placeholder="0"
                    type="number"
                    suffix={campaignForm?.currency || initialCampaignState.currency}
                    info={errors.maximumAmount}
                    hasError={!!errors.maximumAmount}
                    onChange={e => handleInputChange('maximumAmount', e.target.value)}
                    infoType={errors.maximumAmount ? 'error' : null}
                    value={campaignForm.maximumAmount}
                    disabled={isSaving}
                    tabIndex={21}
                />
              </FormPageInfoWrapper>
              <FormPageInfoWrapper>
                <h4 className='group-title'>Receipt options</h4>
                <div className='row-div'>
                  <TextInput
                    label="Receipts are mandatory"
                    name="receipts"
                    type="checkbox"
                    className='checkbox-group'
                    onChange={() => handleReceiptOptionsChange('NO_RECEIPT', campaignForm.receiptConfig)}
                    value={formats.noreceipt ? 0:1}
                    tabIndex={22}
                  />
                </div>

                <label>Formats available</label>
                <div className='row-div'>
                  <TextInput
                    label="Email"
                    name="emailOption"
                    type="checkbox"
                    className='checkbox-group'
                    onChange={() => handleReceiptOptionsChange('EMAIL', campaignForm.receiptConfig)}
                    value={formats.email}
                    tabIndex={23}
                  />
                </div>
                <div className='row-div'>
                  <TextInput
                    label="QR Code"
                    name="qrCode"
                    type="checkbox"
                    className='checkbox-group'
                    onChange={() => handleReceiptOptionsChange('QR_CODE', campaignForm.receiptConfig)}
                    value={formats.qrcode}
                    tabIndex={24}
                  />
                </div>
                <div className='row-div'>
                  <TextInput
                    label="Mail"
                    name="mailOption"
                    className='checkbox-group'
                    type="checkbox"
                    onChange={() => handleReceiptOptionsChange('MAIL', campaignForm.receiptConfig)}
                    value={campaignForm['receiptConfig']?.options?.includes('MAIL')}
                    tabIndex={25}
                  />
                </div>

                <TextInput
                    label="Email copy"
                    name="emailCopy"
                    type="email"
                    onChange={e => handleInputChange('emailCc', e.target.value, 'receiptConfig')}
                    value={campaignForm.receiptConfig.emailCc}
                    hasError={!!errors.emailCc}
                    info={errors.emailCc}
                    infoType={errors.emailCc ? 'error' : null}
                    disabled={isSaving}
                    tabIndex={26}
                />

                <ReceiptTemplateDropDown
                    label="Receipt Template:"
                    name="receiptTemplate"
                    onChange={e => handleInputChange(
                      'template',
                      e.target.value,
                      'receiptConfig')}
                    value={campaignForm.receiptConfig.template}
                    tabIndex={27}
                />

                <div className='row-div'>
                  <TextInput
                    label="Email subject"
                    name="EmailSubject"
                    placeholder=""
                    type="text"
                    info={errors.emailSubject}
                    hasError={!!errors.emailSubject}
                    onChange={e => handleInputChange('emailSubject', e.target.value, 'receiptConfig')}
                    infoType={errors.emailSubject ? 'error' : null}
                    value={campaignForm['receiptConfig']?.emailSubject}
                    disabled={isSaving}
                    tabIndex={28}
                  />
                </div>
                
                <div className='row-div'>
                  <TextArea
                    label="Email text"
                    name="EmailText"
                    placeholder=""
                    type="text"
                    info={errors.emailText}
                    hasError={!!errors.emailText}
                    onChange={e => handleInputChange('emailText', e.target.value, 'receiptConfig')}
                    infoType={errors.emailText ? 'error' : null}
                    value={campaignForm['receiptConfig']?.emailText}
                    disabled={isSaving}
                    cols={6}
                    rows={4}
                    tabIndex={29}
                  />
                </div>
              </FormPageInfoWrapper>
              <ActionsDiv>
                <Button
                onClick={() => createUpdateCampaign(campaignForm, newCampaign)}
                // disabled={!hasChanges}
                >
                  Save
                </Button>
                <Button onClick={() => navigate('/campaign/')}>Cancel</Button>
              </ActionsDiv>
            </form></>
      )}
      </div>
    </FormPageContainer>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(CampaignPage)
