import React, { ReactNode, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import type { MikaUser } from '../../shared/types'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { hasPermission } from '../../shared/utils'
import { deleteUser, fetchUserList } from './api'
import { showNotification } from '../../shared/components/Alert'
import type { User } from './types'
import { Wrapper } from '../Installations/Installations'
import Modal from '../../shared/components/modal/Modal'
import { Confirmation } from '../../shared/components/confirmation/Confirmation'
import { useNavigate } from 'react-router-dom'
import Button from '../../shared/components/button/Button'
import Message from '../../shared/components/message/Message'
import { RolePermission } from '../../shared/constants'
import { Title } from '../../shared/components/title/Title'
import { TextInput } from '../../shared/components/input/TextInput'
import Table from '../../shared/components/table/Table'
import styles from '../Installations/Installation.module.css'
import { Icon } from '../../shared/components/icon/Icon'

const compareByName = (a: User, b: User) => {
  const fullNameA = `${a.firstName} ${a.firstName}`
  const fullNameB = `${b.firstName} ${b.firstName}`
  return fullNameA.localeCompare(fullNameB)
}

const UserList = ({ activeUser }: { activeUser: MikaUser }): ReactNode => {
  const [isLoading, setIsLoading] = useState(true)
  const userList = useSelector((state: RootState) => state.user.userList)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState<string>('')
  const [searchText, setSearchText] = useState('')

  const canUpdate = hasPermission(activeUser, 'user', RolePermission.UPDATE)
  const canCreate = hasPermission(activeUser, 'user', RolePermission.CREATE)
  const canDelete = hasPermission(activeUser, 'user', RolePermission.DELETE)
  const canRead = hasPermission(activeUser, 'user', RolePermission.READ)

  const filteredUsers =
    userList &&
    userList.length >= 0 &&
    userList
      ?.filter(value => value.firstName.startsWith(searchText))
      .sort(compareByName)

  const navigate = useNavigate()

  const onEditUser = (user: User) => {
    navigate(`/user/${user.userId}`)
  }

  const onAddUser = () => {
    navigate('/user/new')
  }
  const onRequestDeleteUser = (e: string) => {
    setUserToDelete(e)
    setIsModalOpen(true)
  }

  const onDeleteUser = (e: string) => {
    setIsModalOpen(false)
    deleteUser(activeUser, e)
      .then(() => {
        setIsLoading(true)
        showNotification('success', 'User was deleted')
      })
      .catch(() => {
        showNotification('success', 'Could not delete user')
      })
  }

  useEffect(() => {
    if (!isLoading) return
    fetchUserList(activeUser).then(() => {
      setIsLoading(false)
    })
  }, [activeUser, setIsLoading, isLoading])

  return (
    <Wrapper>
      {!canRead ? (
        <h1>You do not have permission to use this function.</h1>
      ) : (
        <>
          <LoadingOverlay isLoading={isLoading} />
          {isLoading ? null : (
            <>
              {isModalOpen && (
                <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
                  <Confirmation
                    title="Are you sure?"
                    message="This action cannot be undone."
                    onConfirm={() => {
                      onDeleteUser(userToDelete)
                    }}
                    onCancel={() => setIsModalOpen(false)}
                  />
                </Modal>
              )}
              <Title>
                <div className="title">Users</div>
              </Title>
              <div className={styles.search}>
                <TextInput
                  value={searchText}
                  prefix={<Icon name="search.svg" />}
                  onChange={e => setSearchText(e.target.value)}
                />
                {canCreate && (
                  <Button onClick={() => navigate('/user/new')}>
                    New User
                  </Button>
                )}
              </div>
              <div className={styles.filter}>
                Showing {filteredUsers.length} Users (of {userList.length})
              </div>
              <Table
                className={styles.table}
                header={['#', 'Full Name', 'Group', 'Actions']}
                data={filteredUsers.map((user: User, i: number) => {
                  return [
                    i + 1,
                    user.firstName + ' ' + user.lastName,
                    user.group,
                    <div key={user.userId} className={styles.actions}>
                      {canUpdate && (
                        <div onClick={() => onEditUser(user)}>
                          <Icon name="edit.svg" />
                        </div>
                      )}
                      {canDelete && (
                        <div onClick={() => onRequestDeleteUser(user.userId)}>
                          <Icon name="trash.svg" />
                        </div>
                      )}
                    </div>,
                  ]
                })}
              />
            </>
          )}
          {!userToDelete && !isLoading && userList.length === 0 && (
            <Message
              title="No Users found"
              primaryButtonText={canCreate ? 'New User' : ''}
              primaryAction={canCreate ? onAddUser : null}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeUser: state.login.user,
})

export default connect(mapStateToProps)(UserList)
