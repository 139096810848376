import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import styles from './AlertList.module.css'
import { deleteAlert } from './ducks/alertSlice'

export const AlertList = () => {
  const dispatch = useDispatch()
  const alerts = useSelector((state: RootState) => state.alert.alertList)
  const styleMap = {
    success: styles.success,
    error: styles.error,
    warning: styles.warning,
  }
  alerts.forEach(item => {
    setTimeout(() => {
      dispatch(deleteAlert(item.id))
    }, item.timeout || 5000)
  })
  return (
    <div className={styles.wrapper}>
      {alerts.map(item => (
        <div
          key={item.id}
          className={`${styles.alert} ${styleMap[item.level] ?? '' }`}
        >
          {item.message}
        </div>
      ))}
    </div>
  )
}
