import React, { ReactNode } from 'react'
import style from './Button.module.scss'
import { ButtonSize, ButtonVariant } from './constants'
import type { ButtonProps } from './types'

export default function Button({
  onClick,
  tabIndex = null,
  size = ButtonSize.MEDIUM,
  variant = ButtonVariant.FILLED,
  disabled = false,
  className = null,
  children = null,
}: ButtonProps): ReactNode {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.detail > 1) return
    onClick(event)
  }

  return (
    <button
      tabIndex={tabIndex}
      type="button"
      disabled={disabled}
      data-size={size}
      data-variant={variant}
      className={`${style.button} ${className}`}
      onClick={clickHandler}
    >
      {children}
    </button>
  )
}
