export const enum ButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export const enum ButtonVariant {
  ACCENT = 'accent',
  DESTRUCTIVE = 'destructive',
  FILLED = 'filled',
  GHOST = 'ghost',
  TONAL = 'tonal',
  WARNING = 'warning',
}
