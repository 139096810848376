import { Icon } from '../icon/Icon'
import React from 'react'
import { css, styled } from 'styled-components'

export default function Upload({
  uploadText,
  descriptionText,
  errorText,
  dropCallback,
  sizeLimit = 2 * 1024 * 1024,
  type = null,
}: {
  uploadText: string
  descriptionText: string
  errorText: string
  dropCallback: (content: string | ArrayBuffer) => void
  sizeLimit?: number
  type?: RegExp
}): React.ReactElement {
  const [isDragging, setIsDragging] = React.useState(false)
  const [error, setError] = React.useState('')
  const fileUploadRef = React.useRef<HTMLInputElement>(null)

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setError('')
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (files.length > 0) readFile(files[0])
  }

  const handleFileSelect = () => {
    if (fileUploadRef.current) fileUploadRef.current.click()
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) readFile(files[0])
  }

  const readFile = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const content = reader.result as string
      dropCallback(content)
    }
    reader.onerror = () => {
      setError('Failed to read file')
    }
    if (type && !type.test(file.type)) {
      setError(`The type ${file.type} is not allowed`)

      return
    }
    if (file.size > sizeLimit) {
      setError(`File size should not exceed ${sizeLimit / 1024 / 1024} MB`)

      return
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      <DragZone
        $isDragging={isDragging}
        $errorText={errorText || error}
        onDragEnterCapture={handleDragStart}
        onDragLeaveCapture={handleDragOver}
        onDropCapture={handleDrop}
        onDragOver={e => e.preventDefault()}
      >
        <IconBackground>
          <Icon name="picture-upload.svg" />
        </IconBackground>
        <DragZoneText>
          {isDragging && <DragZoneText>Drop file here</DragZoneText>}
          {!isDragging && (
            <DragZoneText>
              {uploadText} or{' '}
              <FileSelect onClick={handleFileSelect}>select file</FileSelect>
              <DragZoneDescription>{descriptionText}</DragZoneDescription>
            </DragZoneText>
          )}
        </DragZoneText>
      </DragZone>
      <input
        ref={fileUploadRef}
        onChange={handleFileUpload}
        type="file"
        style={{ display: 'none' }}
      />
      {!!errorText || (!!error && <ErrorText>{errorText || error}</ErrorText>)}
    </>
  )
}

const DragZone = styled.div<{ $isDragging: boolean; $errorText: string }>`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 24px;
  border: 2px dashed #d5d9df;
  ${props =>
    props.$errorText &&
    css`
      border: 2px dashed #c9312e;
    `}
  ${props =>
    props.$isDragging &&
    css`
      border: 2px solid var(--content-primary);
    `}
    border-radius: 8px;
`

const DragZoneText = styled.span`
  color: var(--content-primary);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  pointer-events: none;
`

const DragZoneDescription = styled.div`
  color: var(--content-subtle);
  text-align: left;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  pointer-events: none;
`

const FileSelect = styled.span`
  color: var(--content-hyperlink);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-decoration-line: underline;
  pointer-events: all;
  cursor: pointer;
`

const IconBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutrals-light-50);
  border-radius: 50%;
  padding: 16px;
  width: 48px;
  height: 48px;
  pointer-events: none;

  svg {
    width: 26px;
    height: 26px;
    color: var(--content-subtle);
  }
`

const ErrorText = styled.div`
  color: var(--red-500);
  margin-top: -4px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`
