import store, { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import { FormError, TemplatePageProps } from '../../shared/types'
import React, { useReducer, useState } from 'react'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { showNotification } from '../../shared/components/Alert'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Title } from '../../shared/components/title/Title'
import formReducer from '../../shared/reducers/formReducer'
import { defaultInstallation } from './constants'
import { TextInput } from '../../shared/components/input/TextInput'
import { Action } from '../User/styles'
import Button from '../../shared/components/button/Button'
import { ButtonVariant } from '../../shared/components/button/constants'
import { getInstallation, saveInstallation } from './api'
import { formatMerchantResponseError } from '../Merchant/utils'
import { Installation } from '../Installations/types'
import { Select } from '../../shared/components/Select'
import { getOrganizationCampaignList } from '../Campaign/api'
import { Icon } from '../../shared/components/icon/Icon'

const Kiosk = ({ user }: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const canCreate = hasPermission(user, 'kiosk', RolePermission.CREATE)
  const [form, dispatch] = useReducer(formReducer, {
    ...defaultInstallation,
  })
  const [errors, setErrors] = React.useState<FormError>({})
  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const location = useLocation()
  const currentOrgId = location?.state?.orgId
  const [orgId, setOrgId] = useState(currentOrgId)
  const selectedOrganization =
    store.getState().organization.selectedOrganization
  const [dropdowns, setDropdowns] = React.useState([])
  const [campaignList, setCampaignList] = React.useState([])

  React.useEffect(() => {
    const organizationId =
      form.orgId ??
      selectedOrganization?.orgId ??
      orgList[0]?.orgId ??
      user.orgId
    if (organizationId !== form.orgId)
      handleInputChange('orgId', organizationId)

    setIsLoading(true)
    setDropdowns([])
    getOrganizationCampaignList(user, orgId).then(x => {
      setCampaignList(x)
      setDropdowns([x[0]?.campaignId])
    })
    setIsLoading(false)

    if (user && id !== 'new') {
      setIsLoading(true)
      getInstallation(user, selectedOrganization.orgId, id)
        .then(({ loadedInstallation, loadedCampaigns }) => {
          if (loadedCampaigns)
            setDropdowns(loadedCampaigns.map(c => c.campaignId))

          if (loadedInstallation) {
            const payload: Installation = {
              ...defaultInstallation,
              ...loadedInstallation,
            }
            dispatch({ type: 'LOAD_FORM_VALUES', payload: payload })
          } else {
            showNotification('warning', 'Failed to load kiosk.')
          }
        })
        .catch(() => {
          showNotification('danger', 'Failed to load kiosk, try again later.')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [form.orgId, orgId])

  const handleInputChange = (
    field: string,
    value: string,
    objectMap?: string,
  ) => {
    dispatch({
      type: 'HANDLE_FIELD_UPDATE',
      field,
      payload: value,
      objectMap,
    })
  }

  const selectCampaign = (campaign, index) => {
    const newDropdowns = [...dropdowns]
    newDropdowns[index] = campaign
    setDropdowns(newDropdowns)
  }

  const onSave = () => {
    saveInstallation(user, form, id, dropdowns).then(response => {
      if (response.error) {
        if (response.message?.length && response.statusCode === 400) {
          const formattedError: FormError =
            formatMerchantResponseError(response)
          setErrors(formattedError)
          showNotification(
            'danger',
            'Failed to save installation, check the field errors.',
          )
        }
      } else {
        showNotification('success', `Kiosk ${form.name} saved successfully.`)
        navigate('/kiosk')
      }
    })
  }

  return canCreate ? (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <Wrapper>
          <SubTitle>Kiosks</SubTitle>
          <Title>{id === 'new' ? 'New Kiosk' : 'Edit Kiosk'}</Title>
          <Block>
            <TextInput
              label="Kiosk Name"
              name="name"
              placeholder=""
              type="text"
              value={form.name}
              onChange={e => handleInputChange('name', e.target.value)}
              hasError={!!errors.name}
              info={errors.name}
              infoType={errors.name ? 'error' : null}
            />
          </Block>
          <Block>
            <SubTitle>Organization</SubTitle>
          </Block>
          <Block>
            <Select
              isWithFader={false}
              visibleCount={10}
              collection={orgList
                .map(org => ({
                  name: org.name,
                  value: org.orgId,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))}
              value={{
                name: orgList.find(org => org.orgId === orgId)?.name,
                value: orgId,
              }}
              onSelect={org => setOrgId(org)}
              disabled={orgList.length < 2}
            />
          </Block>
          <Block>
            <SubTitle>Campaigns</SubTitle>
          </Block>
          {dropdowns.map((dropdown, i) => (
            <Block key={i}>
              <Select
                isWithFader={false}
                visibleCount={10}
                collection={campaignList
                  .map(campaign => ({
                    name: campaign.name,
                    value: campaign.campaignId,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))}
                value={{
                  name: campaignList.find(
                    campaign => campaign.campaignId === dropdown,
                  )?.name,
                  value: dropdown,
                }}
                onSelect={c => selectCampaign(c, i)}
              />
              <div
                onClick={() => {
                  dropdowns.splice(i, 1)
                  setDropdowns([...dropdowns])
                }}
              >
                <Icon name="trash.svg" />
              </div>
            </Block>
          ))}
          <Block>
            <Button
              onClick={() =>
                setDropdowns([...dropdowns, campaignList[0].campaignId])
              }
            >
              Add another campaign
            </Button>
          </Block>
          <Action>
            <Button disabled={!form.name || !dropdowns.length} onClick={onSave}>
              Save
            </Button>
            <Button
              variant={ButtonVariant.GHOST}
              onClick={() => {
                navigate('/kiosk/')
              }}
            >
              Go to all kiosks
            </Button>
          </Action>
        </Wrapper>
      )}
    </>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(Kiosk)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 28px;
  max-width: 933px;
  min-width: 616px;
  justify-content: center;
  align-self: center;
`

export const SubTitle = styled.div`
  color: var(--content-subtle);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
`
