import React from 'react'
import { Header } from '../Header'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { styled } from 'styled-components'
import { LogoutButton } from '../../../pages/Login/components/LogoutButton'
import { hasPermission } from '../../utils'
import { RolePermission } from '../../constants'
import { useNavigate } from 'react-router-dom'

const CommonHeader = (): React.ReactNode => {
  const user = useSelector((state: RootState) => state.login.user)
  const navigate = useNavigate()

  const onNavigate = (path: string) => {
    navigate(path)
  }

  return (
    <Header>
      <>
        <NavButtons>
          {hasPermission(user, 'organization', RolePermission.READ) && (
            <a onClick={() => onNavigate('/organization')}>Organizations</a>
          )}
          {hasPermission(user, 'installation', RolePermission.READ) && (
            <a onClick={() => onNavigate('/installations')}>Installations</a>
          )}
          {hasPermission(user, 'campaign', RolePermission.READ) && (
            <a onClick={() => onNavigate('/campaign')}>Campaigns</a>
          )}
          {hasPermission(user, 'user', RolePermission.READ) && (
            <a onClick={() => onNavigate('/user')}>Users</a>
          )}
          {hasPermission(user, 'kiosk', RolePermission.READ) &&
            !user.portfolioId && (
              <a onClick={() => onNavigate('/kiosk')}>Kiosks</a>
            )}
          {hasPermission(user, 'transactions', RolePermission.READ) && (
            <a onClick={() => onNavigate('/transactions')}>Transactions</a>
          )}
          {hasPermission(user, 'reports', RolePermission.READ) && (
            <a onClick={() => onNavigate('/reports')}>Reports</a>
          )}
        </NavButtons>
        <AuthenticatedDiv>
          <span>{user.roles[0]}</span>
          <LogoutButton />
        </AuthenticatedDiv>
      </>
    </Header>
  )
}

const TemplateHeader: React.FC = React.memo(() => {
  return <CommonHeader />
})

TemplateHeader.displayName = 'TemplateHeader'

export default TemplateHeader

const AuthenticatedDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-grow: 1;

  a {
    padding: 0 16px;
  }
`
