import { showNotification } from '../../../shared/components/Alert'
import { FormState } from '../../../shared/reducers/formReducer'
import {
  MikaUser,
  Organization,
  ResponseError,
  ResponseInfo,
} from '../../../shared/types'
import {
  baseUrl,
  buildRequestOptions,
  getAPIUrl,
  getActiveToken,
  validateActionResponse,
  validateUserToken,
  uploadFile,
} from '../../../shared/utils'
import store from '../../../store/store'
import { setOrganizationList } from '../ducks/organizationSlice'
import _ from 'lodash'

export const fetchOrganizationList = async (
  mikaUser: MikaUser,
): Promise<void> => {
  const token = getActiveToken(mikaUser)
  if (!token) return null

  try {
    const isOrgAdmin = mikaUser.roles.includes('orgAdmin')
    const url = isOrgAdmin
      ? `${baseUrl}/orgs/${mikaUser.orgId}`
      : `${baseUrl}/orgs`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to fetch organization list.')
      store.dispatch(setOrganizationList(null))
      return
    }

    const orgList: Organization[] = await response.json()
    if (!isOrgAdmin) {
      orgList.forEach(org =>
        Object.keys(org).forEach(key => {
          org[key] ??= ''
        }),
      )
      store.dispatch(setOrganizationList(orgList))
    } else {
      store.dispatch(setOrganizationList([orgList]))
    }
  } catch (error) {
    console.warn({ fetchOrganizationListError: error })
  }
}

export const getOrganizationList = async (
  mikaUser: MikaUser,
): Promise<any> => {
  const token = getActiveToken(mikaUser)
  if (!token) return null

  try {
    const url = mikaUser.roles.includes('orgAdmin')
      ? `${baseUrl}/orgs/${mikaUser.orgId}`
      : `${baseUrl}/orgs`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to fetch organization list.')
      store.dispatch(setOrganizationList(null))

      return
    }

    const orgList = await response.json()
    return orgList

  } catch (error) {
    console.warn({ fetchOrganizationListError: error })
  }
}

export const createOrganization = async (
  mikaUser: MikaUser,
  organization: FormState,
): Promise<ResponseError | string> => {
  const token = getActiveToken(mikaUser)
  if (!token) return null

  delete organization.orgId
  if (mikaUser.portfolioId) organization.portfolioId = mikaUser.portfolioId
  if (mikaUser.servicePortfolioId)
    organization.servicePortfolioId = mikaUser.servicePortfolioId

  const cleanFormRequest = _.omitBy(organization, _.isEmpty)

  if (mikaUser?.scope !== 'retail') 
  cleanFormRequest.hasKiosk = true

  const url = `${baseUrl}/orgs/`
  const requestOptions = buildRequestOptions(token, 'POST', cleanFormRequest)
  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'register organization',
  )

  await fetchOrganizationList(mikaUser)

  if (error) return error
  else return (await response.json()).orgId
}

export const deleteOrganization = async (
  mikaUser: MikaUser,
  orgId: string,
): Promise<ResponseInfo> => {
  const token = getActiveToken(mikaUser)
  if (!token) return null

  const url = `${baseUrl}/orgs/${orgId}`
  const requestOptions = buildRequestOptions(token, 'DELETE')
  const response = await fetch(url, requestOptions)

  if (!response.ok)
    return { message: 'Failed to delete organization', messageLevel: 'danger' }

  return { message: 'Organization has been deleted', messageLevel: 'success' }
}

export const getOrganizationById = async (
  mikaUser: MikaUser,
  organization: string,
): Promise<any> | null => {
  const url = `${getAPIUrl()}/orgs/${organization ?? mikaUser.orgId}`

  if (mikaUser && mikaUser.accessToken) {
    const token = getActiveToken(mikaUser)
    validateUserToken(token)

    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)
    validateActionResponse(response, requestOptions, 'get organization')

    return await response.json()
  }

  return null
}

export const updateOrganizationRequest = async (
  mikaUser: MikaUser,
  updatedOrg: FormState,
): Promise<ResponseError> | null => {
  const url = `${getAPIUrl()}/orgs/${updatedOrg.orgId}`

  const token = getActiveToken(mikaUser)
  validateUserToken(token)

  delete updatedOrg.orgId
  const requestOptions = buildRequestOptions(token, 'PATCH', updatedOrg)
  const response = await fetch(url, requestOptions)
  const error = await validateActionResponse(
    response,
    requestOptions,
    'update organization',
  )

  if (error) return error
}

export const uploadFiles = async (
  mikaUser: MikaUser,
  orgId: string,
  logo: string,
  signature: string,
): Promise<string[]> => {
  let logoResponse: string
  let signatureResponse: string
  if (logo) {
    logoResponse = await uploadFile(
      mikaUser,
      `${getAPIUrl()}/orgs/${orgId}/UploadUrl/logo`,
      logo,
    )
  }
  if (signature) {
    signatureResponse = await uploadFile(
      mikaUser,
      `${getAPIUrl()}/orgs/${orgId}/UploadUrl/signature`,
      signature,
    )
  }

  return [logoResponse, signatureResponse]
}
