import React from 'react';
import Select, { components,
} from 'react-select';
import { StylizedSelect } from '../../pages/Campaign/styles';
import _ from 'lodash';

const { VITE_ASSET_PATH } = import.meta.env

const ReactSelectDropdown = ({
  setValue,
  value,
  options,
  name,
  className,
  ...props
}) => {

  return (
    <Select
        value={value}
        onChange={setValue}
        defaultValue={''}
        options={options}
        name={name}
        className={className}
        {...props}
    />)
}

const IconOption = props => {
  return (
    <components.Option {...props}>
      <img src={`${window.location.origin + VITE_ASSET_PATH}amountIcons/${props.label}`} />
    </components.Option>
  )
}

export const IconsDropdown = ({
  setValue,
  value,
  options,
  name,
  className = '',
}) => {
  const selectedOption = options.find(e => e.value === value)
  const selectedOptionLabel = <img src={`${window.location.origin + VITE_ASSET_PATH}amountIcons/${selectedOption.label}`} />

  const dropdownRef = React.useRef(null)
  const [suggestedHeight, setSuggestedHeight] = React.useState(0)

  React.useEffect(() => {
    if (dropdownRef && dropdownRef.current)
      calculateOffset(dropdownRef)
    
  }, [dropdownRef.current])

  const calculateOffset = dropdownRef => {
      const rect = dropdownRef.current.getBoundingClientRect();
      const suggestedHeight = (rect.y + document.querySelector('#body-container > div').scrollTop) - 36;
      setSuggestedHeight(suggestedHeight);
    return true
  }

  return (<div
            ref={dropdownRef}
          >
    <StylizedSelect
          components={{Option: IconOption}}
          value={{label: selectedOptionLabel}}
          onChange={(e: HTMLOptionElement) => {
            setValue(e.value)
          }}
          onMenuOpen={() => calculateOffset(dropdownRef)}
          options={options}
          name={name}
          className={className}
          $offsetTop={`${suggestedHeight}`}
    /></div>)
}

export default ReactSelectDropdown
