import React, { useEffect } from 'react'
import { TextInput } from '../../shared/components/input/TextInput'
import { Icon } from '../../shared/components/icon/Icon'
import Button from '../../shared/components/button/Button'
import { CustomAmountWrapper } from './styles'
import styles from './Campaign.module.css'
import { basicCustomAmount, MaterialIconOptions } from './constants'
import _ from 'lodash'
import { IconsDropdown } from '../../shared/components/ReactSelectDropdown'
import { CampaignAmount } from '../../shared/types'

export const CustomAmountForm = ({
    customAmounts,
    setCustomAmounts,
    errors,
  }) => {
  
    const addCustomAmount = (newAmount: CampaignAmount) => {
      const updatedCustomAmounts = [...customAmounts, newAmount]
      setCustomAmounts(updatedCustomAmounts)
    }
  
    const removeCustomAmountByIndex = (indexToRemove, customAmounts) => {
      if(indexToRemove === 0 && customAmounts .length === 1) {
        customAmounts = [{iconId: MaterialIconOptions[0].value, amount: ''}]
        setCustomAmounts(customAmounts)
      } else {
      const updatedCustomAmounts = [...customAmounts]
      updatedCustomAmounts.splice(indexToRemove, 1)
      setCustomAmounts(updatedCustomAmounts)
      }
    }

    const handleCustomAmountByIndex = (
      field: string,
      value: string,
      index: number,
    ) => {
      let customAmountFromIndex = customAmounts[index]

      customAmountFromIndex = { ...customAmountFromIndex, [field]: value}

      const newCustomAmounts = _.cloneDeep(customAmounts)

      newCustomAmounts[index] = customAmountFromIndex

      setCustomAmounts(newCustomAmounts)
    }

    useEffect(() => {
      // Prevent empty form
      if(customAmounts && customAmounts.length === 0)
        setCustomAmounts([basicCustomAmount])
      
    }, [customAmounts])
  
    return (
      <CustomAmountWrapper>
        <table>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Boost Message</th>
              <th>Icon</th>
            </tr>
          </thead>
          <tbody>
  
            {Array.isArray(customAmounts) && customAmounts.map((z, index) => {
              return (
                <tr  key={index}>
                  <td>
                    <TextInput
                      label=""
                      name="amount"
                      type="text"
                      onChange={e => handleCustomAmountByIndex('amount', e.target.value, index)}
                      value={customAmounts[index].amount}
                      hasError={!!errors.amount}
                      info={errors.amount}
                      tabIndex={16}
                    />
                  </td>
                  <td>
                    <TextInput
                      label=""
                      name="description"
                      type="text"
                      onChange={e => handleCustomAmountByIndex('description', e.target.value, index)}
                      value={customAmounts[index].description}
                      hasError={!!errors.description}
                      info={errors.description}
                      tabIndex={17}
                    />
                  </td>
                  <td>
                    <div className='flex-row'>
                      <IconsDropdown
                      name="iconId"
                      options={MaterialIconOptions}
                      value={customAmounts[index].iconId}
                      setValue={e => handleCustomAmountByIndex('iconId', e, index)}
                      />
                      {index >= 0 &&  customAmounts[index].amount !== '' &&
                      <a onClick={() => removeCustomAmountByIndex(index, customAmounts)}>
                        <Icon
                        className={`${styles.error} ${styles.icon}`}
                        name={'close_circle.svg'}
                        />
                      </a>}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='row-div'>
          <Button
              onClick={() => {
                addCustomAmount(basicCustomAmount)
              }}
              disabled={customAmounts.length === 3}
          >
            + Add
          </Button>
        </div>
        <div className='row-div'>
          {(errors.defaultAmountConfigs || errors.amounts) && (
            <div className={`${styles.error} ${styles.row}`}>
              <Icon
                className={`${styles.error} ${styles.icon}`}
                name={'error.svg'}
              />
              <div>
                {errors.defaultAmountConfigs && (
                <p>{errors.defaultAmountConfigs} </p>
                )}
                {errors.amounts && <p>{errors.amounts} </p>}
              </div>
            </div>
          )}
        </div>
      </CustomAmountWrapper>
    )
  }
