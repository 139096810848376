import React from 'react'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { TemplatePageProps } from '../../shared/types'
import { RootState } from '../../store/store'
import { connect, useDispatch } from 'react-redux'
import { logout } from '../Login/ducks/loginSlice'
import { styled } from 'styled-components'

export const LogoutPage = ({ user }: TemplatePageProps): React.ReactNode => {
  const [isLoading, setIsLoading] = React.useState(true)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(logout())
    setIsLoading(false)
  }, [user])

  return (
    <GenericContainer>
      <LoadingOverlay isLoading={isLoading} />
    </GenericContainer>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user || undefined,
})

export default connect(mapStateToProps)(LogoutPage)

const GenericContainer = styled.div`
  background-color: var(--brand-primary);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
