import React from 'react'
import { styled } from 'styled-components'

interface InputFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}


export const InputBoolean = (props: InputFormProps): React.ReactNode => {
  const isTrue: boolean =
    !!props?.value && props.value.toString().toLowerCase() === 'true'

  return (
    <InputWrapper className='input-boolean'>
      <label htmlFor={props.name}>{props.label || props.name}</label>
      <div>
        <input
          type="checkbox"
          name={props.name}
          onChange={props.onChange}
          checked={isTrue}
        />
      </div>
    </InputWrapper>
  )
}

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  &.input-boolean {
    flex-direction: row;
    width: 50%;
    padding-right: 8px;
  }

  label {
    color: var(--content-primary);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 4px;
  }
  input {
    width: 272px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  select {
    padding: 0 8px;
    height: 36px;
    width: calc(100% - 4px);
    border-radius: 8px;
    color: var(--grey-1000);
    font-size: 13px;
    line-height: 20px;
    border: 1px solid var(--grey-200);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
    
  select.empty {
    color: var(--grey-200);
  }

  input[type='checkbox'] {
    margin-left: 4px;
    margin-top: 4px;
    height: 18px;
    width: 18px;
  }

  input[type='number'] {
    width: 272px;
  }
`
