import { MikaUser } from '../../shared/types'
import {
  baseUrl,
  buildRequestOptions,
  getActiveToken,
} from '../../shared/utils'
import { showNotification } from '../../shared/components/Alert'
import { ReportItem } from './types'
import _ from 'lodash'

export const fetchReports = async (
  user: MikaUser,
  campaignId: string,
  orgId: string,
): Promise<any> => {
  const token = getActiveToken(user)
  if (!token) return null

  try {
    const url = `${baseUrl}/reports/?orgId=${orgId}&campaignId=${campaignId}`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to fetch report list.')

      return
    }

    return await response.json()
  } catch (error) {
    console.warn(error)
  }
}

export const generateReports = async (
  user: MikaUser,
  orgId: string,
  campaignId: string,
  periodBegin: string,
  periodEnd: string,
  timezone: string,
): Promise<void> => {
  const token = getActiveToken(user)
  if (!token) return

  try {
    const url = `${baseUrl}/reports`
    const requestOptions = buildRequestOptions(
      token,
      'POST',
      _.omitBy(
        {
          campaignId,
          orgId,
          periodBegin,
          periodEnd,
          timezone,
        },
        _.isEmpty,
      ),
    )
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to fetch report list.')

      return
    }

    showNotification('success', 'Report has been generated.')
  } catch (error) {
    console.warn(error)
  }
}

export const getReportUrl = async (
  user: MikaUser,
  report: ReportItem,
): Promise<void> => {
  const token = getActiveToken(user)
  if (!token) return

  try {
    const url = `${baseUrl}/reports/${report.reportId}/downloadUrl`
    const requestOptions = buildRequestOptions(token, 'GET')
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      showNotification('danger', 'Failed to download report.')

      return
    }

    const dlUrl = await response.text()
    const link = document.createElement('a')
    link.href = dlUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.warn(error)
  }
}
