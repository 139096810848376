import _, { debounce } from 'lodash'
import { FormError, MikaUser, ResponseError } from './types'
import { showNotification } from './components/Alert'
import {
  hiddenFields,
  permissions,
  required,
  restrictions,
  RolePermission,
} from './constants'

type Methods = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'

export const isCharityOrRetail = (isSektorEnv: boolean): string => {return isSektorEnv ? 'retail' : 'charity'}

export const buildRequestOptions = (
  token: string,
  method: Methods,
  body?: unknown,
  additionalHeaders?: HeadersInit,
): RequestInit => {
  const defaultHeaders: HeadersInit = new Headers({
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type':
      method === 'POST' || method === 'PATCH'
        ? 'application/json; charset=UTF-8'
        : 'text/plain;charset=UTF-8',
    ...additionalHeaders,
  })

  const requestOptions: RequestInit = {
    method,
    headers: defaultHeaders,
    mode: 'cors',
    cache: 'no-cache',
  }

  if (body) requestOptions.body = JSON.stringify(body)

  return requestOptions
}

export const createDebouncedFunction = <T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): T | ((...args: Parameters<T>) => void) => debounce(func, delay)

export const getActiveToken = (user: MikaUser): string | null => {
  if (user && user.accessToken) {
    const expirationTime = user.tokenExpiration
    const expirationDate = new Date(expirationTime)

    if (expirationDate > new Date()) return user.accessToken
    else console.warn('Invalid Token')
  } else {
    console.warn('User is not logged in.')
  }

  return null
}

export const getAPIUrl = (): string => {
  const { VITE_API_BASE_URL } = import.meta.env

  return VITE_API_BASE_URL
}

export const redirectToLogout = (): void => {
  window.location.href = '/logout'
}

export const redirectToPage = (page: string): void => {
  setTimeout(() => {
    window.location.href = page // '/campaign'
  }, 1000)
}

export const doLogoutWithMessage = (message: string): void => {
  showNotification('danger', message)
  setTimeout(() => {
    redirectToLogout()
  }, 2000)
}

export const validateActionResponse = async (
  response: Response,
  request: RequestInit,
  method: string,
): Promise<void | ResponseError> => {
  if (response.status === 401) {
    doLogoutWithMessage('Invalid token, please login and try again.')

    return
  }
  if (response.status === 400) {
    if (response && response.body) {
      const validatedResponse = await response.json()

      console.warn({ validatedResponse })
      if (
        validatedResponse.error === 'Bad Request' &&
        validatedResponse.message
      )
        return validatedResponse

      throw new Error(
        `Failed to ${method} due to error ${validatedResponse.code}, contact an admin.`,
      )
    }

    throw new Error(`Failed to ${method} due to a conflict`)
  }
  if (response.status === 409) {
    if (response && response.body) {
      const validatedResponse = await response.json()

      console.warn({ validatedResponse })
      if (validatedResponse.error === 'Conflict' && validatedResponse.message)
        return validatedResponse

      throw new Error(
        `Failed to ${method} due to error ${validatedResponse.code}, contact an admin.`,
      )
    }

    throw new Error(`Failed to ${method} due to a bad request`)
  }
  if (!response.ok) throw new Error(`Failed to ${method} due to an error.`)
}

export const validateUserToken = (token: string): void => {
  if (!token) doLogoutWithMessage('Invalid token.')
}

export const baseUrl = getAPIUrl()
export const isAdmin = (role: string): boolean =>
  ['mikaAdmin', 'orgAdmin'].includes(role)

export const isValidEmail = (email: string): boolean => {
  const emailRegExp =
    /[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'+/=?^_`{|}~-]+)@(?:[a-z0-9](?:[a-z0-9-][a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

  return emailRegExp.test(email)
}

export const getUTCDateString = (date: string): string => {
  const localDate = new Date(date) // Local date
  const utcDate = new Date(
    localDate.getTime() + localDate.getTimezoneOffset() * 60000,
  )

  return utcDate.toISOString()
}

export const getUTCDate = (date: string): Date => {
  const localDate = new Date(date) // Local date
  return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000)
}

export const getObjDiff = (
  objA: Record<string, any>,
  objB: Record<string, any>,
): Record<string, unknown> => {
  const changedProperties = _.transform(objB, (result: any, value, key) => {
    const hasDifference = !_.isEqual(value, objA[key])

    if (hasDifference) {
      const isNestedObject = _.isObject(value) && _.isObject(objA[key])

      if (isNestedObject)
        result[key] = getObjDiff(objA[key] as any, value as any)
      else result[key] = value
    }
  })

  return changedProperties
}

export const formatResponseError = (error: ResponseError): FormError => {
  const badInputs = error.message
  const formError = {}
  
  if(badInputs && Array.isArray(badInputs))
  {badInputs.map(fieldError => {
    if (fieldError.constraints && fieldError.property?.length) {
      const keyName = fieldError.property[fieldError.property.length - 1]

      // formError[keyName] = fieldError.constraints
      
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let error = ''

      Object.keys(fieldError.constraints).map((constraint, index) => {
        const message = fieldError.constraints[constraint]
        const styledMessage =
          message.charAt(0).toUpperCase() + message.substring(1, message.length)
        error = index === 0 ? `${styledMessage}` : `${error}\n${styledMessage}`
      })

      formError[keyName] = error
    }
  })}

  return formError
}

export function dataToBlob(dataURI: string, mimeType: string): Blob {
  const byteString = atob(dataURI.split(',')[1])

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeType })
}

export async function validateFileUpload(
  response: Response,
): Promise<{ errorMessage?: string }> {
  const regex = /<\?xml version="1.0" encoding="UTF-8"\?>\n/g
  const responseText = await response.text()
  const validatedResponse = responseText.replace(regex, '')
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(validatedResponse, 'text/xml')
  const message = xmlDoc.querySelector('Message')?.textContent

  return response.status === 400 ? { errorMessage: message } : {}
}

export const uploadFile = async (
  mikaUser: MikaUser,
  url: string,
  file: string,
): Promise<string> => {
  const token = getActiveToken(mikaUser)
  validateUserToken(token)

  const requestOptions = buildRequestOptions(token, 'POST', null)
  const response = await fetch(url, requestOptions)

  const result = await response.json()
  const formData = new FormData()
  const mimeType = file.match(/[^:\s*]\w+\/[\w-+.]+(?=[;| ])/)[0]
  for (const key in result.fields) formData.append(key, result.fields[key])

  formData.append('Content-Type', mimeType)
  formData.append('file', dataToBlob(file, mimeType))
  const options = { method: 'POST', body: formData }
  const resp = await fetch(result.url, options)
  const uploadError = await validateFileUpload(resp)

  return uploadError.errorMessage
}

export const hasPermission = (
  user: MikaUser,
  resource: string,
  permission: RolePermission,
): boolean => {
  const mainRole = user?.roles?.[0] || ''
  if (!mainRole || !resource || !permission) return false
  const restriction = restrictions[mainRole]
  if (
    restriction &&
    (restriction.custom === '' || user[restriction.custom]) &&
    restriction.restricted.includes(resource)
  )
    return false
  const roleRequirements = required[mainRole]
  if (roleRequirements && roleRequirements[resource]) {
    const isEnabled = roleRequirements[resource].every(
      (requirement: string) => user[requirement],
    )
    if (!isEnabled) return false
  }
  const rolePermission = permissions[mainRole][resource]
  if (rolePermission === undefined) return true

  return rolePermission.includes(permission)
}

export const isFieldVisible = (user: MikaUser, field: string): boolean => {
  const role = user?.roles?.[0] || ''
  const rolePermissions = hiddenFields[role]

  return !rolePermissions.includes(field)
}

export const getBooleanFromString = (value: string | boolean) => {
  const realValue = (typeof value === 'string' && value === 'true') || value === true ? true : false

  return realValue
}
