import React, { ReactNode } from 'react'
import { MessageProps } from './types'
import Button from '../button/Button'
import styled from 'styled-components'
import { ButtonVariant } from '../button/constants'
import { Icon } from '../icon/Icon'

export default function Message(props: MessageProps): ReactNode {
  return (
    <MessageWrapper>
      <Icon name="folder.svg" />
      <Title>{props.title}</Title>
      {!!props.summary && <Summary>{props.summary}</Summary>}
      {(!!props.primaryButtonText || !!props.secondaryButtonText) && (
        <ButtonWrapper>
          {!!props.primaryButtonText && (
            <Button
              variant={ButtonVariant.ACCENT}
              onClick={props.primaryAction}
            >
              {props.primaryButtonText}
            </Button>
          )}
          {!!props.secondaryButtonText && (
            <Button
              variant={ButtonVariant.GHOST}
              onClick={props.secondaryAction}
            >
              {props.secondaryButtonText}
            </Button>
          )}
        </ButtonWrapper>
      )}
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  text-align: center;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
`

const Summary = styled.div`
  font-size: 13px;
  color: var(--content-subtle);
`

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 8px;
`
