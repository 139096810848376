import { UserState } from '../types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: UserState = {
  userList: [],
  selectedUser: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
  },
})

export const { setUserList, setSelectedUser } = userSlice.actions
export default userSlice.reducer
