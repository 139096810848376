import React from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo.svg'
import SektorLogo from '../assets/sektorLogo.svg'
import { isSektorEnv } from '../../App'

type LogoSize = 'giant' | 'big' | 'medium' | 'small'

interface LogoProps {
  size?: LogoSize
}

const getSizeInPixels = (size: LogoSize): number => {
  switch (size) {
    case 'giant':
      return 120
    case 'big':
      return 64
    case 'medium':
      return 32
    case 'small':
      return 16
    default:
      return 32
  }
}

export const CommonLogo = ({ size = 'medium' }: LogoProps): React.ReactNode => {
  
  return (
    <a>
      <StyledLogo src={isSektorEnv ? SektorLogo : Logo} alt="Logo" size={size} className={isSektorEnv ? 'mt-6' : ''} />
    </a>
  )
}

export const LogoComponent = ({
  size = 'medium',
  src = Logo,
  ...props
}: LogoProps & React.ImgHTMLAttributes<HTMLImageElement>): React.ReactNode => {
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    setError(false)
  }, [src])

  return (
    <a>
      <StyledLogo
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
          e.currentTarget.onerror = null
          setError(true)
        }}
        src={src}
        alt="Logo image"
        size={size}
        $error={error}
        {...props}
      />
    </a>
  )
}

const StyledLogo = styled.img<{ size?: LogoSize; $error?: boolean }>`
  height: ${props => getSizeInPixels(props.size)}px;
  cursor: pointer;
  padding: 0 16px;
  margin-right: 32px;
  display: ${props => (props.$error ? 'none' : 'block')};

  &.mt-6 {
    margin-top: 6px;
  }
`
