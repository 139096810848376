import React from 'react'
import ReactDOM from 'react-dom'
import { styled } from 'styled-components'

export type AlertType = 'success' | 'info' | 'warning' | 'danger'

interface AlertProps {
  type: AlertType
  message: string
  duration?: number
}

const Alert: React.FC<AlertProps> = ({ type, message, duration = 3000 }) => {
  const [visible, setVisible] = React.useState(true)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false)
    }, duration)

    return () => clearTimeout(timeout)
  }, [duration])

  const handleClose = () => {
    setVisible(false)
  }

  return visible ? (
    <StyledAlert className={`alert alert-${type}`} role="alert">
      {message}
      <button
        type="button"
        className="btn-close"
        onClick={handleClose}
        aria-label="Close"
      />
    </StyledAlert>
  ) : null
}

export default Alert

const StyledAlert = styled.div`
  position: fixed;
  z-index: 105;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  width: 60vw;
  display: flex;
  justify-content: space-between;

  button,
  button:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`

export const showNotification = (type: AlertType, message: string): void => {
  const container = document.createElement('div')
  document.body.appendChild(container)
  const alertElement = React.createElement(Alert, { type, message })
  ReactDOM.render(alertElement, container)
}
