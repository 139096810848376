import { MikaUser } from '../../shared/types'
import {
  baseUrl,
  buildRequestOptions,
  getActiveToken,
  validateUserToken,
} from '../../shared/utils'

export async function fetchTransactions(user: MikaUser, orgId?: string) {
  const token = getActiveToken(user)
  validateUserToken(token)
  const now = new Date().toISOString()
  const starOftDay = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const url = `${baseUrl}/transactions/dashboard/summary?orgId=${orgId ?? user.orgId}&timezone=${timezone}&periodBegin=${starOftDay}&periodEnd=${now}`
  const requestOptions = buildRequestOptions(token, 'GET')
  const response = await fetch(url, requestOptions)
  return await response.json()
}
