import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction, loginError } from '../ducks/loginSlice'
import { RootState } from '../../../store/store'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../../shared/components/input/Input'
import { LoginArea, LoginBox, LoginButton } from './styles'
import { isValidEmail } from '../../../shared/utils'
import { AuthUser, getCurrentUser, signOut } from 'aws-amplify/auth'
import { styled } from 'styled-components'
import { isSektorEnv } from '../../../App'
import { LogoComponent } from '../../../shared/components/Logos'
import SektorLogoPurple from '../../../shared/assets/sektorLogoPurple.svg'

const LoginForm: React.FC = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn)
  const errorMessage = useSelector((state: RootState) => state.login.errorMsg)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    if (isLoggedIn) navigate('/organization')
  }, [isLoggedIn, navigate])

  useEffect(() => {
    if (errorMessage) setIsLoading(false)
  }, [errorMessage])

  useEffect(() => {
    if (isLoading) clearErrorMessage()
  }, [isLoading])

  const handleLogin = async () => {
    setIsLoading(true)
    try {
      if (!isLoggedIn) {
        let loggedInUser: AuthUser | undefined

        await getCurrentUser()
          .then(e => {
            loggedInUser = e
          })
          .catch(() => {
            loggedInUser = undefined
          })

        if (loggedInUser) await signOut()
      }
    } finally {
      dispatch(loginAction(email, password))
    }
  }

  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
  }

  const clearErrorMessage = () => {
    dispatch(loginError(''))
  }

  const onPasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => setPassword(e.target.value)
  const isLoginButtonDisabled = password.length === 0 || !isValidEmail(email)

  return (
    <LoginBox>
      <LoginTitle />
      <LoginArea>
        <Input
          type="email"
          placeholder="Email*"
          value={email}
          onChange={onEmailInputChange}
          autoComplete="off"
        />
        <Input
          type="password"
          placeholder="Password*"
          value={password}
          onChange={onPasswordInputChange}
        />
        <LoginButton
          onClick={handleLogin}
          disabled={isLoginButtonDisabled || isLoading}
        >
          Login
          {isLoading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : isLoginButtonDisabled ? (
            <i className="bi bi-lock-fill" />
          ) : (
            <i className="bi bi-arrow-up-right" />
          )}
        </LoginButton>
        <ForgotPassword onClick={() => navigate('/password-reset')}>
          I forgot my password
        </ForgotPassword>
        {!!errorMessage && (
          <div className="alert alert-danger" role="alert">
            <div>
              <i className="bi bi-exclamation-triangle-fill" />
              <span>{errorMessage}</span>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={clearErrorMessage}
            ></button>
          </div>
        )}
      </LoginArea>
    </LoginBox>
  )
}

export default LoginForm

export const LoginTitle = () => (
  <>
    {isSektorEnv ? <LogoComponent src={SektorLogoPurple} /> : <span>Mika</span>}
    <span className={isSektorEnv ? 'partner-portal' : ''}>Partner Portal</span>
  </>
)

const ForgotPassword = styled.div`
  color: var(--content-primary);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  margin-top: 16px;
`
