import React from 'react'
import styled from 'styled-components'
import Char from '../assets/Mascot_3.svg'

export const MikaMascot = ({
  hideOnMobile,
}: {
  hideOnMobile?: boolean
}): React.ReactNode => (
  <CharSvg
    src={Char}
    alt="Mika Character"
    hideonmobile={hideOnMobile ? 'true' : 'false'}
  />
)

const CharSvg = styled.img<{ hideonmobile: string }>`
  position: relative;
  z-index: 1;
  margin: auto;
  text-align: center;
  height: 20vh;

  @media (max-height: 799px) {
    display: ${({ hideonmobile }) =>
      hideonmobile === 'true' ? 'none' : 'initial'};
  }
`
