import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../store/store'
import { connect, useSelector } from 'react-redux'
import { FormError, TemplatePageProps } from '../../shared/types'
import React, { useEffect, useReducer, useState } from 'react'
import { Action, FormWrapper } from './styles'
import { emptyUser } from './constants'
import formReducer from '../../shared/reducers/formReducer'
import {
  createUser,
  getPortfolioList,
  getServicePortfolioList,
  updateUser,
} from './api'
import { showNotification } from '../../shared/components/Alert'
import { UserWithRole } from './types'
import Button from '../../shared/components/button/Button'
import { TextInput } from '../../shared/components/input/TextInput'
import {
  formatResponseError,
  hasPermission,
  isCharityOrRetail,
  redirectToPage,
} from '../../shared/utils'
import {
  CountryDropdown,
  CustomDropdown,
} from '../../shared/components/Dropdown'
import { getOrganizationList } from '../Organization/service/api'
import LoadingOverlay, {
  LoadingLayer,
} from '../../shared/components/LoadingOverlay'
import { RolePermission } from '../../shared/constants'
import { isArray, isEmpty, omitBy } from 'lodash'
import { Wrapper } from '../Installations/Installations'
import { isSektorEnv } from '../../App'

const filterRoles = (userRole: string) => {
  const roles = [
    { name: 'mikaAdmin', value: 'mikaAdmin' },
    { name: 'portfolioAdmin', value: 'portfolioAdmin' },
    { name: 'servicePortfolioAdmin', value: 'servicePortfolioAdmin' },
    { name: 'orgAdmin', value: 'orgAdmin' },
  ]

  const minIndex = roles.findIndex(e => e.name === userRole)
  const filteredRoles = roles.splice(minIndex, roles.length)
  return filteredRoles
}

const UserPage = ({ user }: TemplatePageProps): React.ReactNode => {
  const { id } = useParams<{ id: string }>()
  const userLists = useSelector((state: RootState) => state.user.userList)
  const [selectedUser, setSelectedUser] = useState<Partial<UserWithRole>>(null)
  const [errors, setErrors] = React.useState<FormError>({})

  const [portfolioOptions, setPortfolioOptions] = useState([])
  const [servicePortfolioOptions, setServicePortfolioOptions] = useState([])
  const [orgOptions, setOrgOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)

  const [loadingOptions, setLoadingOptions] = useState(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [userForm, dispatch] = useReducer(formReducer, emptyUser)
  const navigate = useNavigate()

  const role = user?.roles?.[0] || ''
  const activePortfolioId = userForm?.portfolioId || user.portfolioId
  const displayRoles = filterRoles(role)

  const canUpdate = hasPermission(user, 'user', RolePermission.UPDATE)
  const canCreate = hasPermission(user, 'user', RolePermission.CREATE)

  const handleInputChange = (
    field: string,
    value: string,
    objectMap?: string,
  ) => {
    if (objectMap) {
      dispatch({
        type: 'HANDLE_MERCHANT_CONFIGURATION',
        field,
        payload: value,
        objectMap,
      })

      return
    }

    dispatch({ type: 'HANDLE_FIELD_UPDATE', field, payload: value })
  }

  const onSaveUser = () => {
    setIsSaving(true)

    if (!userForm.portfolio && user.portfolioId)
      userForm.portfolioId = user.portfolioId

    if (!userForm.servicePortfolio && user.servicePortfolioId)
      userForm.servicePortfolioId = user.servicePortfolioId

    if (user.roles[0] === 'mikaAdmin' && selectedOption?.value) {
      userForm.portfolioId = selectedOption.portfolioId
      userForm.servicePortfolioId = selectedOption.servicePortfolioId
    }

    userForm.orgId = selectedOption?.value

    const cleanUserForm = omitBy(userForm, isEmpty)
    cleanUserForm.scope = isCharityOrRetail(isSektorEnv)

    const createOrUpdateUser = !!userForm?.userId ? updateUser : createUser
    const createOrUpdateTxt = !!userForm?.userId ? 'update' : 'register'

    createOrUpdateUser(user, cleanUserForm)
      .then(response => {
        if (response && response.error) {
          if (response.message?.length && response.statusCode === 400) {
            const formattedError: FormError = formatResponseError(response)
            setErrors(formattedError)

            showNotification(
              'danger',
              `Failed to ${createOrUpdateTxt} user, check the field errors.`,
            )

            return
          }

          showNotification(
            'danger',
            `Failed to ${createOrUpdateTxt} user, due to error "${response && response.message ? response.message : response.error}".`,
          )

          return
        }

        showNotification('success', `user has been ${createOrUpdateTxt}ed.`)
        redirectToPage('/user/')
      })
      .catch(e => {
        console.error(' Caught error: ', e)
        showNotification(
          'danger',
          ('Failed to ${createOrUpdateTxt} user, ' + e && e.message) || e.code,
        )
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const loadServicePortfolioOptions = (portfolioId: string) => {
    getServicePortfolioList(user, portfolioId).then(list => {
      setServicePortfolioOptions(
        list.map(servicePortfolio => ({
          name: servicePortfolio.name,
          value: servicePortfolio.servicePortfolioId,
          // TODO portfolioId: servicePortfolio.portfolioId
        })),
      )
    })
  }

  const loadPortfolioOptions = () => {
    if (
      portfolioOptions &&
      portfolioOptions.length === 0 &&
      !user.portfolioId
    ) {
      setLoadingOptions(true)
      getPortfolioList(user).then(list => {
        setPortfolioOptions(
          list.map(portfolio => ({
            name: portfolio.name,
            value: portfolio.portfolioId,
          })),
        )
        setLoadingOptions(false)
      })
    }
  }

  const loadOrgsOptions = () => {
    setLoadingOptions(true)
    getOrganizationList(user).then(list => {
      if (isArray(list)) {
        setOrgOptions(
          list.map(org => ({
            name: org.name,
            value: org.orgId,
            portfolioId: org.portfolioId,
            servicePortfolioId: org.servicePortfolioId,
          })),
        )
        setLoadingOptions(false)
      } else {
        const org = [
          {
            name: list.name,
            value: list.orgId,
            portfolioId: list.portfolioId,
            servicePortfolioId: list.servicePortfolioId,
          },
        ]
        setOrgOptions(org)
        setLoadingOptions(false)
      }
    })
  }

  useEffect(() => {
    if (id !== 'new') {
      const userFromList = userLists.find(
        (user: UserWithRole) => user.userId === id,
      )
      if (!userFromList) {
        showNotification('danger', 'User not found')
        navigate('/user/')
      } else {
        dispatch({ type: 'LOAD_FORM_VALUES', payload: userFromList })
        setSelectedUser(userFromList)
      }
    } else {
      const payload = { ...emptyUser }
      dispatch({ type: 'LOAD_FORM_VALUES', payload })
      setSelectedUser(payload)
    }
  }, [id, navigate, dispatch, setSelectedUser, userLists])

  useEffect(() => {
    if (
      userForm.group === 'portfolioAdmin' ||
      (userForm.group === 'servicePortfolioAdmin' && role === 'mikaAdmin')
    )
      loadPortfolioOptions()

    if (userForm.group === 'servicePortfolioAdmin' && !!activePortfolioId)
      loadServicePortfolioOptions(activePortfolioId)

    if (userForm.group === 'orgAdmin') loadOrgsOptions()
  }, [userForm.group, activePortfolioId])

  return (
    <Wrapper>
      {(id === 'new' && canCreate) || (id !== 'new' && canUpdate) ? (
        <div>
          <LoadingOverlay isLoading={isSaving} />
          <h3>{userForm?.userId ? 'Edit User' : 'New User'}</h3>
          <FormWrapper>
            <h4>About</h4>
            <TextInput
              label="User ID:"
              name="user-id"
              placeholder=""
              type="text"
              onChange={e => handleInputChange('userId', e.target.value)}
              value={userForm?.userId}
              hasError={!!errors.userId}
              info={errors.userId}
              infoType={errors.userId ? 'error' : null}
              disabled
            />
            <div className="row-div">
              <TextInput
                label="First Name:"
                name="contact-firstName"
                type="text"
                onChange={e => handleInputChange('firstName', e.target.value)}
                value={userForm?.firstName}
                hasError={!!errors.firstName}
                info={errors.firstName}
                infoType={errors.firstName ? 'error' : null}
                tabIndex={2}
              />
              <TextInput
                label="Family Name:"
                name="contact-lastName"
                type="text"
                onChange={e => handleInputChange('lastName', e.target.value)}
                value={userForm?.lastName}
                hasError={!!errors.lastName}
                info={errors.lastName}
                infoType={errors.lastName ? 'error' : null}
                tabIndex={3}
              />
            </div>
            <div className="row-div">
              <CustomDropdown
                label="Role: "
                options={displayRoles}
                useOptionName
                value={userForm?.group}
                onChange={e => handleInputChange('group', e.target.value)}
                tabIndex={4}
                required
              />

              <LoadingLayer isLoading={loadingOptions}>
                {(userForm.group === 'portfolioAdmin' ||
                  userForm.group === 'servicePortfolioAdmin') &&
                  !user.portfolioId && (
                    <CustomDropdown
                      label="Portfolio: "
                      options={portfolioOptions}
                      useOptionName
                      value={userForm?.portfolioId}
                      onChange={e =>
                        handleInputChange('portfolioId', e.target.value)
                      }
                      tabIndex={5}
                      disabled={!portfolioOptions.length}
                      hidden={loadingOptions && portfolioOptions.length === 0}
                      required
                    />
                  )}
                {userForm.group === 'orgAdmin' && (
                  <CustomDropdown
                    label="Organization: "
                    options={orgOptions}
                    useOptionName
                    value={userForm?.orgId}
                    onChange={e =>
                      setSelectedOption(
                        orgOptions.find(org => org.value === e.target.value),
                      )
                    }
                    tabIndex={6}
                    disabled={!orgOptions.length}
                    required
                  />
                )}
              </LoadingLayer>
            </div>

            {userForm.group === 'servicePortfolioAdmin' && (
              <div>
                <CustomDropdown
                  label="Service Portfolio: "
                  options={servicePortfolioOptions}
                  useOptionName
                  value={userForm?.servicePortfolioId}
                  onChange={e =>
                    handleInputChange('servicePortfolioId', e.target.value)
                  }
                  tabIndex={7}
                  disabled={
                    !activePortfolioId || !servicePortfolioOptions.length
                  }
                  required
                />
              </div>
            )}
          </FormWrapper>

          <FormWrapper>
            <h4>Contact</h4>
            <div className="row-div">
              <CountryDropdown
                label="Country:"
                name="org-country"
                onChange={e => handleInputChange('country', e.target.value)}
                value={userForm.country}
                tabIndex={8}
                hasError={!!errors.country}
                info={errors.country}
              />

              <TextInput
                label="Phone number:"
                name="contact-phone"
                type="text"
                onChange={e => handleInputChange('phoneNumber', e.target.value)}
                value={userForm?.phoneNumber}
                hasError={!!errors.phoneNumber}
                info={errors.phoneNumber}
                infoType={errors.phoneNumber ? 'error' : null}
                tabIndex={10}
              />
            </div>

            <div>
              <TextInput
                label="Email:"
                name="contact-email"
                type="text"
                onChange={e => handleInputChange('email', e.target.value)}
                value={userForm?.email}
                hasError={!!errors.email}
                info={errors.email}
                infoType={errors.email ? 'error' : null}
                tabIndex={9}
              />
            </div>
          </FormWrapper>

          <Action>
            <Button onClick={onSaveUser}>
              {selectedUser?.userId ? 'Update user' : 'Create user'}
            </Button>
            <Button
              className="secondary-action"
              onClick={() => {
                navigate('/user/')
              }}
            >
              Go to all users
            </Button>
          </Action>
        </div>
      ) : (
        <div>
          <h1>You do not have permission to use this function.</h1>
        </div>
      )}
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(UserPage)
