import React, { useEffect } from 'react'
import styled from 'styled-components'
import { RootState } from '../../../store/store'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { StyledBodyContainer } from './Containers'
import { TemplatePageProps } from '../../types'
import { getActiveToken } from '../../utils'
import { loginError } from '../../../pages/Login/ducks/loginSlice'

interface BodyContainerProps {
  children?: React.ReactNode
  id: string
}

const PageTemplate = ({
  children,
  user,
  isLoggedIn,
  id,
}: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {
    if (!isLoggedIn || !user)
      navigate('/')
    
    if (user) {
      const token = getActiveToken(user)

      if (!token){
        dispatch(loginError('Refresh your login session.'))
        navigate('/logout')
      }
    }
  }, [user, isLoggedIn, navigate])

  return (
    <TemplateContainer id={'template-container'}>
      <Header />
      <BodyContainer id={id ?? 'body-container'}>{children}</BodyContainer>
      <Footer />
    </TemplateContainer>
  )
}

const BodyContainer: React.FC<BodyContainerProps> = React.memo(
  ({ children, id }) => {
    return <StyledBodyContainer id={id}>{children}</StyledBodyContainer>
  },
)

BodyContainer.displayName = 'BodyContainer'

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  isLoggedIn: state.login.isLoggedIn,
})

export default connect(mapStateToProps)(PageTemplate)

const TemplateContainer = styled.div`
  background-color: var(--brand-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
`

// Temporary fix above, before the layout updates on visual feedback from back-end MR is merged
