import React from 'react'
import type { TextAreaProps, TextInputProps } from './types'
import { Input } from './Input'
import style from './Input.module.css'
import { Icon } from '../icon/Icon'

const iconMap = {
  error: { name: 'error.svg', style: style.error },
  info: { name: 'info.svg', style: style.info },
  success: { name: 'check.svg', style: style.success },
  warning: { name: 'warning.svg', style: style.warning },
}

  export function TextInput(props: TextInputProps): React.ReactNode {
  const {
    onChange,
    onFocus,
    onBlur,
    disabled,
    prefix,
    suffix,
    type,
    tabIndex,
    value,
    className,
    placeholder,
    hasError,
    label,
    tooltip,
    note,
    isRequired,
    infoType,
    info,
  } = props

  return (
    <div className={className}>
      <div className={style.header}>
        {label && <span className={style.label}>{label}</span>}
        {tooltip}
        {note && <span className={style.note}> ({note})</span>}
        {isRequired && <span className={style.required}> *</span>}
      </div>
      <Input
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
        type={type}
        tabIndex={tabIndex}
        value={value}
        placeholder={placeholder}
        hasError={hasError || infoType === 'error'}
        checked={type === 'checkbox' && value}
      />
      {infoType && (
        <WarningMessage infoType={infoType} info={info}/>
      )}
    </div>
  )
}

export function TextArea(props: TextAreaProps): React.ReactNode {
  const {
    onChange,
    onFocus,
    onBlur,
    disabled,
    tabIndex,
    value,
    className,
    placeholder,
    hasError,
    label,
    tooltip,
    note,
    isRequired,
    infoType,
    info,
    rows = 2,
    cols = 2,
  } = props

  return (
    <div className={className}>
      <div className={style.header}>
        {label && <span className={style.label}>{label}</span>}
        {tooltip}
        {note && <span className={style.note}> ({note})</span>}
        {isRequired && <span className={style.required}> *</span>}
      </div>
      <textarea
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        tabIndex={tabIndex}
        value={value}
        placeholder={placeholder}
        className={`${disabled ? style.disabled : ''} ${hasError ? style['error-border'] : ''} ${
          style.wrapper
        } ${style['text-area']} `}
        style={{
          overflow: 'scroll'
      }}
        rows={rows}
        cols={cols}
      />
      {infoType && (
        <WarningMessage infoType={infoType} info={info}/>
      )}
    </div>
  )
}

type warningProps = {
  info?: string | unknown
  infoType?: 'info' | 'success' | 'warning' | 'error'
}

export const WarningMessage = (props: warningProps) => {
  const { infoType, info } = props

  return (<div className={style.footer}>
    <Icon
      className={`${style.icon} ${iconMap[infoType].style}`}
      name={iconMap[infoType].name}
    />
    <span className={infoType === 'error' ? style.error : ''}>
      {info as string}
    </span>
  </div>)
}